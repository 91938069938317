/* 16. Styles for differents Post types.
-------------------------------------------------------------- */
.menu_main_nav>li#blob {
    position: absolute;
    top: 0;
    z-index: 1;
    opacity: 0;
    -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
    -moz-transition: all 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
    -ms-transition: all 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
    -o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
    transition: all 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
}

.menu_main_nav>li.blob_over:not(.current-menu-item):not(.current-menu-parent):not(.current-menu-ancestor):hover, .menu_main_nav>li.blob_over:not(.current-menu-item):not(.current-menu-parent):not(.current-menu-ancestor).sfHover {
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.menu_hover_slide_line .menu_main_nav>li#blob {
    height: 1px;
    margin: 3.3em 0 0 1.5em
}

.menu_hover_zoom_line .menu_main_nav>li>a {
    position: relative
}

.menu_hover_zoom_line .menu_main_nav>li>a:before {
    content: "";
    height: 1px;
    position: absolute;
    margin-left: 0.25em;
    top: 3.3em;
    left: 50%;
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -ms-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 0
}

.menu_hover_zoom_line .menu_main_nav>li>a:hover:before, .menu_hover_zoom_line .menu_main_nav>li.sfHover>a:before, .menu_hover_zoom_line .menu_main_nav>li.current-menu-item>a:before, .menu_hover_zoom_line .menu_main_nav>li.current-menu-parent>a:before, .menu_hover_zoom_line .menu_main_nav>li.current-menu-ancestor>a:before {
    width: 80%
}

.menu_hover_path_line .menu_main_nav>li>a {
    position: relative
}

.menu_hover_path_line .menu_main_nav>li:before, .menu_hover_path_line .menu_main_nav>li:after, .menu_hover_path_line .menu_main_nav>li>a:before, .menu_hover_path_line .menu_main_nav>li>a:after {
    content: ' '!important;
    position: absolute;
    -webkit-transition: -webkit-transform 0.1s;
    -ms-transition: -ms-transform 0.1s;
    transition: transform 0.1s;
    -webkit-transition-timing-function: cubic-bezier(1, 0.68, 0.16, 0.9);
    -ms-transition-timing-function: cubic-bezier(1, 0.68, 0.16, 0.9);
    transition-timing-function: cubic-bezier(1, 0.68, 0.16, 0.9)
}

.menu_hover_path_line .menu_main_nav>li:before, .menu_hover_path_line .menu_main_nav>li:after {
    top: 0.5em;
    bottom: 0.5em;
    width: 1px;
    -webkit-transform: scale3d(1, 0, 1);
    -ms-transform: scale3d(1, 0, 1);
    transform: scale3d(1, 0, 1)
}

.menu_hover_path_line .menu_main_nav>li:before {
    left: 0.2em;
    -webkit-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    transform-origin: 50% 100%
}

.menu_hover_path_line .menu_main_nav>li:after {
    right: 0.2em;
    -webkit-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    transform-origin: 50% 0%
}

.menu_hover_path_line .menu_main_nav>li>a:before, .menu_hover_path_line .menu_main_nav>li>a:after, .menu_hover_path_line .menu_main_nav>li.menu-item-has-children>a:after {
    left: 0.2em;
    right: 0.2em;
    height: 1px;
    -webkit-transform: scale3d(0, 1, 1);
    -ms-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1)
}

.menu_hover_path_line .menu_main_nav>li>a:before {
    top: 0.5em;
    bottom: auto;
    -webkit-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    transform-origin: 0 50%
}

.menu_hover_path_line .menu_main_nav>li>a:after, .menu_hover_path_line .menu_main_nav>li.menu-item-has-children>a:after {
    top: auto;
    bottom: 0.5em;
    -webkit-transform-origin: 100% 50%;
    -ms-transform-origin: 100% 50%;
    transform-origin: 100% 50%
}

.menu_hover_path_line .menu_main_nav>li>a:after, .menu_hover_path_line .menu_main_nav>li.menu-item-has-children>a:after {
    -webkit-transition-delay: 0.3s;
    -ms-transition-delay: 0.3s;
    transition-delay: 0.3s
}

.menu_hover_path_line .menu_main_nav>li>a:hover:after, .menu_hover_path_line .menu_main_nav>li.sfHover>a:after, .menu_hover_path_line .menu_main_nav>li.menu-item-has-children>a:hover:after, .menu_hover_path_line .menu_main_nav>li.sfHover.menu-item-has-children>a:after {
    -webkit-transition-delay: 0s;
    -ms-transition-delay: 0s;
    transition-delay: 0s
}

.menu_hover_path_line .menu_main_nav>li:before {
    -webkit-transition-delay: 0.2s;
    -ms-transition-delay: 0.2s;
    transition-delay: 0.2s
}

.menu_hover_path_line .menu_main_nav>li:hover:before, .menu_hover_path_line .menu_main_nav>li.sfHover:before {
    -webkit-transition-delay: 0.1s;
    -ms-transition-delay: 0.1s;
    transition-delay: 0.1s
}

.menu_hover_path_line .menu_main_nav>li>a:before {
    -webkit-transition-delay: 0.1s;
    -ms-transition-delay: 0.1s;
    transition-delay: 0.1s
}

.menu_hover_path_line .menu_main_nav>li.sfHover>a:before, .menu_hover_path_line .menu_main_nav>li>a:hover:before {
    -webkit-transition-delay: 0.2s;
    -ms-transition-delay: 0.2s;
    transition-delay: 0.2s
}

.menu_hover_path_line .menu_main_nav>li.sfHover:after, .menu_hover_path_line .menu_main_nav>li:hover:after {
    -webkit-transition-delay: 0.3s;
    -ms-transition-delay: 0.3s;
    transition-delay: 0.3s
}

.menu_hover_path_line .menu_main_nav>li.sfHover:before, .menu_hover_path_line .menu_main_nav>li:hover:before, .menu_hover_path_line .menu_main_nav>li.sfHover:after, .menu_hover_path_line .menu_main_nav>li:hover:after, .menu_hover_path_line .menu_main_nav>li.sfHover>a:before, .menu_hover_path_line .menu_main_nav>li>a:hover:before, .menu_hover_path_line .menu_main_nav>li>a:hover:after, .menu_hover_path_line .menu_main_nav>li.sfHover>a:after {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1)
}

.menu_hover_roll_down .menu_main_nav>li>a:before {
    content: ' ';
    position: absolute;
    top: 3.3em;
    left: 1.5em;
    right: 1.5em;
    height: 1px;
    opacity: 0;
    -webkit-transform: translate3d(0, -3em, 0);
    -ms-transform: translate3d(0, -3em, 0);
    transform: translate3d(0, -3em, 0);
    -webkit-transition: -webkit-transform 0s 0.3s, opacity 0.2s;
    -ms-transition: transform 0s 0.3s, opacity 0.2s;
    transition: transform 0s 0.3s, opacity 0.2s
}

.menu_hover_roll_down .menu_main_nav>li>a:hover:before, .menu_hover_roll_down .menu_main_nav>li.sfHover>a:before, .menu_hover_roll_down .menu_main_nav>li.current-menu-item>a:before, .menu_hover_roll_down .menu_main_nav>li.current-menu-ancestor>a:before, .menu_hover_roll_down .menu_main_nav>li.current-menu-parent>a:before {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: -webkit-transform 0.3s, opacity 0.1s;
    -ms-transition: -webkit-transform 0.3s, opacity 0.1s;
    transition: transform 0.3s, opacity 0.1s;
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -ms-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.menu_hover_roll_down .menu_main_nav>li>a>span {
    display: block;
    pointer-events: none
}

.menu_hover_roll_down .menu_main_nav>li.sfHover>a>span, .menu_hover_roll_down .menu_main_nav>li>a:hover>span, .menu_hover_roll_down .menu_main_nav>li.current-menu-item>a>span, .menu_hover_roll_down .menu_main_nav>li.current-menu-ancestor>a>span, .menu_hover_roll_down .menu_main_nav>li.current-menu-parent>a>span {
    -webkit-animation: anim-roll-down 0.3s forwards;
    -ms-animation: anim-roll-down 0.3s forwards;
    animation: anim-roll-down 0.3s forwards
}

@-webkit-keyframes anim-roll-down {
    50% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        -ms-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
    51% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        -ms-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes anim-roll-down {
    50% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        -ms-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
    51% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        -ms-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.menu_hover_color_line .menu_main_nav>li>a:hover:before, .menu_hover_color_line .menu_main_nav>li>a:focus:before {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    -webkit-transition-delay: 0s;
    -ms-transition-delay: 0s;
    transition-delay: 0s
}

.menu_hover_color_line .menu_main_nav>li>a:before, .menu_hover_color_line .menu_main_nav>li>a:after, .menu_hover_color_line .menu_main_nav>li.menu-item-has-children>a:after {
    content: ' '!important;
    position: absolute;
    top: 3.3em;
    left: 1.5em;
    right: 1.5em!important;
    margin: 0;
    height: 1px;
    -webkit-transform: scale3d(0, 1, 1);
    -ms-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
    -webkit-transform-origin: center left;
    -ms-transform-origin: center left;
    transform-origin: center left;
    -webkit-transition: transform 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
    -ms-transition: transform 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
    transition: transform 0.3s cubic-bezier(0.22, 0.61, 0.36, 1)
}

.menu_hover_color_line .menu_main_nav>li>a:hover, .menu_hover_color_line .menu_main_nav>li.sfHover>a:after, .menu_hover_color_line .menu_main_nav>li>a:hover:after, .menu_hover_color_line .menu_main_nav>li>a:before {
    -webkit-transition-delay: 0.3s;
    -ms-transition-delay: 0.3s;
    transition-delay: 0.3s
}

.menu_hover_color_line .menu_main_nav>li.sfHover>a:after, .menu_hover_color_line .menu_main_nav>li.menu-item-has-children>a:hover:after, .menu_hover_color_line .menu_main_nav>li>a:hover:after, .menu_hover_color_line .menu_main_nav>li.sfHover>a:before, .menu_hover_color_line .menu_main_nav>li>a:hover:before, .menu_hover_color_line .menu_main_nav>li.current-menu-item>a:after, .menu_hover_color_line .menu_main_nav>li.current-menu-item>a:before, .menu_hover_color_line .menu_main_nav>li.current-menu-ancestor>a:after, .menu_hover_color_line .menu_main_nav>li.current-menu-ancestor>a:before, .menu_hover_color_line .menu_main_nav>li.current-menu-parent>a:after, .menu_hover_color_line .menu_main_nav>li.current-menu-parent>a:before {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1)
}

.menu_hover_color_line .menu_main_nav>li.sfHover>a:before, .menu_hover_color_line .menu_main_nav>li>a:hover:before {
    -webkit-transition-delay: 0s;
    -ms-transition-delay: 0s;
    transition-delay: 0s
}

[class*="sc_button_hover_slide"] {
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s
}

.sc_button_hover_slide_left:hover, .sc_button_hover_slide_left.active, .vc_active .sc_button_hover_slide_left, li.active .sc_button_hover_slide_left {
    background-position: left bottom!important
}

.sc_button_hover_slide_right:hover, .sc_button_hover_slide_right.active, .vc_active .sc_button_hover_slide_right, li.active .sc_button_hover_slide_right {
    background-position: right bottom!important
}

.sc_button_hover_slide_top:hover, .sc_button_hover_slide_top.active, .vc_active .sc_button_hover_slide_top, li.active .sc_button_hover_slide_top {
    background-position: right top!important
}

.sc_button_hover_slide_bottom:hover, .sc_button_hover_slide_bottom.active, .vc_active .sc_button_hover_slide_bottom, li.active .sc_button_hover_slide_bottom {
    background-position: right bottom!important
}

.sc_button_hover_arrow {
    position: relative;
    overflow: hidden;
    padding-left: 2em!important;
    padding-right: 2em!important
}

.sc_button_hover_arrow:before {
    content: '\e8f4';
    font-family: 'fontello';
    position: absolute;
    z-index: 1;
    right: -2em!important;
    left: auto!important;
    top: 50%!important;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
    background-color: transparent!important;
    color: inherit!important
}

.sc_button_hover_arrow:hover:before, .sc_button_hover_arrow.active:before, .vc_active .sc_button_hover_arrow:before {
    right: 0.5em!important
}

.post_featured[class*="hover_"] {
    position: relative;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out
}

.post_featured[class*="hover_"], .post_featured[class*="hover_"] * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box
}

.post_featured[class*="hover_"] img {
    width: 100%;
    height: auto
}

.post_featured .mask {
    content: ' ';
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    filter: alpha(opacity=0);
    -webkit-transition: all ease .5s;
    -moz-transition: all ease .5s;
    -ms-transition: all ease .5s;
    -o-transition: all ease .5s;
    transition: all ease .5s
}

.post_featured .post_info {
    position: absolute;
    z-index: 103;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) scale(0.5, 0.5);
    -moz-transform: translateX(-50%) translateY(-50%) scale(0.5, 0.5);
    -ms-transform: translateX(-50%) translateY(-50%) scale(0.5, 0.5);
    transform: translateX(-50%) translateY(-50%) scale(0.5, 0.5);
    -webkit-transition: all ease .5s;
    -moz-transition: all ease .5s;
    -ms-transition: all ease .5s;
    -o-transition: all ease .5s;
    transition: all ease .5s;
    display: none;
    font-size: 1.1429em;
    line-height: 1.3em;
    width: 90%;
    text-align: center;
    margin: -1em auto 0;
    opacity: 0;
    filter: alpha(opacity=0)
}

.post_featured.hover_icon, .post_featured.hover_icons {
    position: relative;
    display: block
}

.post_featured.hover_icon .mask {
    background-color: rgba(0, 0, 0, 0.4)
}

.post_featured.hover_icon:hover .mask {
    opacity: 1;
    filter: alpha(opacity=100)
}

.post_featured.hover_icon .icons, .post_featured.hover_icons .icons {
    position: absolute;
    z-index: 102;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%
}

.post_featured.hover_icon .icons a, .post_featured.hover_icons .icons a {
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    font-size: 1.7143em;
    cursor: pointer;
    display: block;
    width: 2.1667em;
    height: 2.1667em;
    line-height: 2.1667em;
    text-align: center;
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) scale(0.3, 0.3);
    -moz-transform: translateX(-50%) translateY(-50%) scale(0.3, 0.3);
    -ms-transform: translateX(-50%) translateY(-50%) scale(0.3, 0.3);
    transform: translateX(-50%) translateY(-50%) scale(0.3, 0.3);
    -webkit-transition: all .5s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s;
    -moz-transition: all .5s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s;
    -ms-transition: all .5s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s;
    -o-transition: all .5s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s;
    transition: all .5s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s;
    opacity: 0;
    filter: alpha(opacity=0);
    margin-left: -1.3333em
}

.post_featured.hover_icons .icons a.single_icon {
    margin-left: 0
}

.post_featured.hover_icon .icons a {
    font-size: 4em;
    margin-left: 0
}

.post_featured.hover_icons .icons a+a {
    margin-left: 1.3333em;
    -webkit-transition-delay: .15s;
    -moz-transition-delay: .15s;
    -ms-transition-delay: .15s;
    -o-transition-delay: .15s;
    transition-delay: .15s
}

.post_featured.hover_icon:hover .icons a, .post_featured.hover_icons:hover .icons a {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateX(-50%) translateY(-50%) scale(1, 1);
    -moz-transform: translateX(-50%) translateY(-50%) scale(1, 1);
    -ms-transform: translateX(-50%) translateY(-50%) scale(1, 1);
    transform: translateX(-50%) translateY(-50%) scale(1, 1);
    -webkit-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
    -moz-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
    -ms-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
    -o-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
    transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s
}

.post_featured.hover_icons:hover .icons a+a {
    -webkit-transition-delay: .15s;
    -moz-transition-delay: .15s;
    -ms-transition-delay: .15s;
    -o-transition-delay: .15s;
    transition-delay: .15s
}

.post_featured.hover_zoom:before {
    content: ' ';
    position: absolute;
    z-index: 101;
    left: 50%;
    top: 50%;
    width: 100px;
    height: 100px;
    margin: -50px 0 0 -50px;
    border: 2px solid #fff;
    background-color: rgba(0, 0, 0, 0.15);
    -webkit-transform: scale(0, 0) rotate(0deg);
    -moz-transform: scale(0, 0) rotate(0deg);
    -ms-transform: scale(0, 0) rotate(0deg);
    transform: scale(0, 0) rotate(0deg);
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s
}

.post_featured.hover_zoom:hover:before {
    -webkit-transform: scale(1, 1) rotate(45deg);
    -moz-transform: scale(1, 1) rotate(45deg);
    -ms-transform: scale(1, 1) rotate(45deg);
    transform: scale(1, 1) rotate(45deg)
}

.post_featured.hover_zoom .mask {
    background-color: rgba(255, 255, 255, 0.15)
}

.post_featured.hover_zoom:hover .mask {
    opacity: 1;
    filter: alpha(opacity=100)
}

.post_featured.hover_zoom .icons {
    position: absolute;
    z-index: 102;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    display: inline-block
}

.post_featured.hover_zoom .icons a {
    display: inline-block;
    font-size: 18px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #fff;
    position: relative;
    left: -50px;
    opacity: 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    transform: scale(0, 0);
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s
}

.post_featured.hover_zoom .icons a+a {
    margin-left: 8px;
    left: 50px
}

.post_featured.hover_zoom .icons a:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.2)
}

.post_featured.hover_zoom:hover .icons a {
    left: 0;
    opacity: 1;
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1)
}

.post_featured.hover_dots:hover .mask {
    opacity: 1;
    filter: alpha(opacity=100)
}

.post_featured.hover_dots .icons {
    position: absolute;
    z-index: 120;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%
}

.post_featured.hover_dots .icons span {
    position: absolute;
    z-index: 102;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    display: inline-block;
    width: 6px;
    height: 6px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all ease .5s;
    -moz-transition: all ease .5s;
    -ms-transition: all ease .5s;
    -o-transition: all ease .5s;
    transition: all ease .5s;
    opacity: 0;
    filter: alpha(opacity=0)
}

.post_featured.hover_dots:hover .icons span {
    opacity: 1;
    filter: alpha(opacity=100);
    display: inline-block
}

.post_featured.hover_dots:hover .icons span:first-child {
    margin-left: -13px
}

.post_featured.hover_dots:hover .icons span+span+span {
    margin-left: 13px
}

.post_featured.hover_dots.hover_with_info .icons span {
    margin-top: 1em
}

.post_featured.hover_dots .post_info {
    display: block;
    top: 45%
}

.post_featured.hover_dots:hover .post_info {
    -webkit-transform: translateX(-50%) translateY(-50%) scale(1, 1);
    -moz-transform: translateX(-50%) translateY(-50%) scale(1, 1);
    -ms-transform: translateX(-50%) translateY(-50%) scale(1, 1);
    transform: translateX(-50%) translateY(-50%) scale(1, 1);
    opacity: 1;
    filter: alpha(opacity=100)
}

.post_featured.hover_fade, .post_featured.hover_slide, .post_featured.hover_pull, .post_featured.hover_border {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden
}

.post_featured.hover_fade .post_info, .post_featured.hover_slide .post_info, .post_featured.hover_pull .post_info, .post_featured.hover_border .post_info {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    display: block;
    margin: 0;
    opacity: 1;
    filter: alpha(opacity=100);
    font-size: 1em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    transition: none;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none
}

.post_featured.hover_fade .post_info .post_title, .post_featured.hover_slide .post_info .post_title, .post_featured.hover_pull .post_info .post_title, .post_featured.hover_border .post_info .post_title {
    font-weight: 400;
    font-size: 1.5em;
    line-height: 1.4em;
    margin: 0 0 0.5em;
    text-transform: uppercase;
    text-align: center
}

.post_featured.hover_fade .post_info .post_descr, .post_featured.hover_slide .post_info .post_descr, .post_featured.hover_pull .post_info .post_descr, .post_featured.hover_border .post_info .post_descr {
    margin: 0;
    position: relative;
    padding: 0.5em 1em;
    text-align: center
}

.post_featured.hover_fade .post_info .post_title span, .post_featured.hover_slide .post_info .post_title span, .post_featured.hover_pull .post_info .post_title span, .post_featured.hover_border .post_info .post_title span {
    font-weight: 700
}

.post_featured.hover_fade .post_info .post_excerpt, .post_featured.hover_slide .post_info .post_excerpt, .post_featured.hover_pull .post_info .post_excerpt, .post_featured.hover_border .post_info .post_excerpt {
    display: none
}

.post_featured.hover_fade img, .post_featured.hover_fade .post_info .post_info_back, .post_featured.hover_fade .post_info .post_title, .post_featured.hover_fade .post_info .post_descr, .post_featured.hover_slide img, .post_featured.hover_slide .post_info .post_info_back, .post_featured.hover_slide .post_info .post_title, .post_featured.hover_slide .post_info .post_descr, .post_featured.hover_pull img, .post_featured.hover_pull .post_info .post_info_back, .post_featured.hover_pull .post_info .post_title, .post_featured.hover_pull .post_info .post_descr, .post_featured.hover_border img, .post_featured.hover_border .post_info .post_info_back, .post_featured.hover_border .post_info .post_title, .post_featured.hover_border .post_info .post_descr {
    -webkit-transition: all 0.35s ease;
    -moz-transition: all 0.35s ease;
    -ms-transition: all 0.35s ease;
    transition: all 0.35s ease
}

.post_featured.hover_fade .post_info .post_excerpt, .post_featured.hover_slide .post_info .post_excerpt, .post_featured.hover_pull .post_info .post_excerpt, .post_featured.hover_border .post_info .post_excerpt {
    margin-top: 1em;
    display: none
}

.post_featured.hover_fade:hover .mask {
    opacity: 1;
    filter: alpha(opacity=100)
}

.post_featured.hover_fade .post_info .post_info_back {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
    padding: 15px;
    margin-top: 100%;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%)
}

.post_featured.hover_fade.top_to_bottom .post_info .post_info_back {
    margin-top: -100%
}

.post_featured.hover_fade.bottom_to_top .post_info .post_info_back {
    margin-top: 100%
}

.post_featured.hover_fade.left_to_right .post_info .post_info_back {
    margin-left: -100%
}

.post_featured.hover_fade.right_to_left .post_info .post_info_back {
    margin-left: 100%
}

.post_featured.hover_fade:hover .post_info .post_info_back {
    margin: 0!important;
    opacity: 1;
    filter: alpha(opacity=100)
}

.post_featured.hover_slide .mask {
    opacity: 0.3;
    filter: alpha(opacity=30)
}

.post_featured.hover_slide:hover .mask {
    opacity: 1;
    filter: alpha(opacity=100)
}

.post_featured.hover_slide .post_info, .post_featured.hover_slide .post_info .post_title, .post_featured.hover_slide .post_info .post_descr {
    text-align: left
}

.post_featured.hover_slide img, .post_featured.hover_slide .post_info .post_title, .post_featured.hover_slide .post_info .post_descr {
    -webkit-transition: all 0.35s ease;
    -moz-transition: all 0.35s ease;
    transition: all 0.35s ease
}

.post_featured.hover_slide .post_info .post_title, .post_featured.hover_slide .post_info .post_descr {
    position: relative;
    margin: 0
}

.post_featured.hover_slide .post_info .post_title {
    padding: 0 0 15px;
    margin: 30px 30px 15px;
    overflow: hidden
}

.post_featured.hover_slide .post_info .post_title:after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    -webkit-transition: all 0.35s ease;
    -moz-transition: all 0.35s ease;
    transition: all 0.35s ease;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%)
}

.post_featured.hover_slide:hover .post_info .post_title:after {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px)
}

.post_featured.hover_slide img {
    max-width: none;
    width: calc(100% + 20px);
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px)
}

.post_featured.hover_slide:hover img {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px)
}

.post_featured.hover_slide .post_info, .post_featured.hover_slide .post_info .post_title, .post_featured.hover_slide .post_info .post_descr {
    text-align: left
}

.post_featured.hover_slide .post_info .post_descr {
    padding: 0 30px 30px;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%)
}

.post_featured.hover_slide:hover .post_info .post_descr {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px)
}

.post_featured.hover_pull .post_info, .post_featured.hover_pull .post_info .post_title, .post_featured.hover_pull .post_info .post_descr {
    text-align: left
}

.post_featured.hover_pull .post_info .post_title, .post_featured.hover_pull .post_info .post_descr {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    margin: 0
}

.post_featured.hover_pull:hover img {
    -webkit-transform: translateY(-60px);
    -moz-transform: translateY(-60px);
    -ms-transform: translateY(-60px);
    -o-transform: translateY(-60px);
    transform: translateY(-60px)
}

.post_featured.hover_pull:hover .post_info .post_title {
    -webkit-transform: translateY(-70px);
    -moz-transform: translateY(-70px);
    -ms-transform: translateY(-70px);
    -o-transform: translateY(-70px);
    transform: translateY(-70px)
}

.post_featured.hover_pull .post_info .post_descr {
    height: 60px;
    -webkit-transform: translateY(60px);
    -moz-transform: translateY(60px);
    -ms-transform: translateY(60px);
    -o-transform: translateY(60px);
    transform: translateY(60px)
}

.post_featured.hover_pull:hover .post_info .post_descr {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px)
}

.post_featured.hover_border:hover .mask {
    opacity: 1;
    filter: alpha(opacity=100)
}

.post_featured.hover_border .post_info:before, .post_featured.hover_border .post_info:after {
    content: ' ';
    position: absolute;
    left: 30px;
    right: 30px;
    top: 30px;
    bottom: 30px;
    pointer-events: none;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.post_featured.hover_border .post_info:before {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0)
}

.post_featured.hover_border:hover .post_info:before {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1)
}

.post_featured.hover_border .post_info:after {
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    transform: scaleY(0)
}

.post_featured.hover_border:hover .post_info:after {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1)
}

.post_featured.hover_border .post_info .post_info_back {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
    padding: 45px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%)
}

.post_featured.hover_border img, .post_featured.hover_border .post_info .post_title, .post_featured.hover_border .post_info .post_descr {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.post_featured.hover_border:hover img {
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1)
}

.post_featured.hover_border .post_info .post_title, .post_featured.hover_border .post_info .post_descr {
    opacity: 0;
    filter: alpha(opacity=0);
    position: relative;
    text-align: center;
    margin: 0
}

.post_featured.hover_border .post_info .post_title {
    font-size: 1.2em;
    padding: 0 0 15px;
    -webkit-transform: translateY(-1em);
    -moz-transform: translateY(-1em);
    -ms-transform: translateY(-1em);
    -o-transform: translateY(-1em);
    transform: translateY(-1em)
}

.post_featured.hover_border:hover .post_info .post_title {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px)
}

.post_featured.hover_border .post_info .post_descr {
    padding: 0;
    -webkit-transform: translateY(1em);
    -moz-transform: translateY(1em);
    -ms-transform: translateY(1em);
    -o-transform: translateY(1em);
    transform: translateY(1em)
}

.post_featured.hover_border:hover .post_info .post_descr {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px)
}

.post_featured.hover_border .post_info .post_info_item+.post_info_item:before {
    content: ' ';
    display: block;
    margin: 0.5em 0 0 0
}

.post_featured.hover_shop {
    position: relative;
    display: block
}

.post_featured.hover_shop .mask {
    background-color: rgba(255, 255, 255, 0.6)!important
}

.post_featured.hover_shop:hover .mask {
    opacity: 1;
    filter: alpha(opacity=100)
}

.post_featured.hover_shop .icons {
    position: absolute;
    z-index: 102;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%
}

.post_featured.hover_shop .icons a {
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    font-size: 1.2632em!important;
    padding: 0!important;
    cursor: pointer;
    display: block;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    text-align: center;
    display: inline-block;
    border: 3px solid #ddd;
    border-width: 3px!important;
    -webkit-transform: translateX(-50%) translateY(-50%) scale(0.3, 0.3) rotate(45deg);
    -moz-transform: translateX(-50%) translateY(-50%) scale(0.3, 0.3) rotate(45deg);
    -ms-transform: translateX(-50%) translateY(-50%) scale(0.3, 0.3) rotate(45deg);
    transform: translateX(-50%) translateY(-50%) scale(0.3, 0.3) rotate(45deg);
    -webkit-transition: all .5s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s;
    -moz-transition: all .5s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s;
    -ms-transition: all .5s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s;
    -o-transition: all .5s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s;
    transition: all .5s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s;
    opacity: 0;
    filter: alpha(opacity=0);
    margin-left: -1.76em
}

.post_featured.hover_shop .icons a.added_to_cart {
    display: none
}

.post_featured.hover_shop .icons a+a, .post_featured.hover_shop .icons a+a+a {
    margin-left: 1.76em;
    -webkit-transition-delay: .15s;
    -moz-transition-delay: .15s;
    -ms-transition-delay: .15s;
    -o-transition-delay: .15s;
    transition-delay: .15s
}

.post_featured.hover_shop .icons a.shop_cart:before {
    font-family: 'fontello'!important;
    font-weight: 400!important;
    content: '\e921'!important
}

.post_featured.hover_shop .icons a:before, .post_featured.hover_shop .icons a:after {
    margin: 0!important;
    padding: 0!important;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.post_featured.hover_shop .icons a:after {
    position: absolute;
    top: 25%;
    left: 25%;
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    -moz-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    -ms-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    font-size: 0.6em
}

.post_featured.hover_shop:hover .icons a {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateX(-50%) translateY(-50%) scale(1, 1) rotate(45deg);
    -moz-transform: translateX(-50%) translateY(-50%) scale(1, 1) rotate(45deg);
    -ms-transform: translateX(-50%) translateY(-50%) scale(1, 1) rotate(45deg);
    transform: translateX(-50%) translateY(-50%) scale(1, 1) rotate(45deg);
    -webkit-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
    -moz-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
    -ms-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
    -o-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
    transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s
}

.post_featured.hover_shop:hover .icons a+a, .post_featured.hover_shop:hover .icons a+a+a {
    -webkit-transition-delay: .15s;
    -moz-transition-delay: .15s;
    -ms-transition-delay: .15s;
    -o-transition-delay: .15s;
    transition-delay: .15s
}

.sc_content_width_1_1 {
    width: 1170px
}

.sc_content_width_1_2 {
    width: 585px
}

.sc_content_width_1_3 {
    width: 390px
}

.sc_content_width_2_3 {
    width: 780px
}

.sc_content_width_1_4 {
    width: 292.5px
}

.sc_content_width_3_4 {
    width: 877.5px
}

.trx_addons_container, .trx_addons_container-fluid {
    padding-left: 25px;
    padding-right: 25px
}

.trx_addons_columns_wrap {
    margin-right: -50px
}

.trx_addons_columns_wrap>[class*="trx_addons_column-"] {
    padding-right: 50px
}

.trx_addons_columns_wrap.columns_padding_left {
    margin-left: -50px
}

.trx_addons_columns_wrap.columns_padding_left>[class*="trx_addons_column-"], .trx_addons_columns_wrap>[class*="trx_addons_column-"].columns_padding_left {
    padding-left: 50px
}

.trx_addons_columns_wrap.columns_padding_right {
    margin-right: -50px
}

.trx_addons_columns_wrap.columns_padding_right>[class*="trx_addons_column-"], .trx_addons_columns_wrap>[class*="trx_addons_column-"].columns_padding_right {
    padding-right: 50px
}

.trx_addons_columns_wrap.columns_padding_center {
    margin-left: -25px;
    margin-right: -25px
}

.trx_addons_columns_wrap.columns_padding_center>[class*="trx_addons_column-"], .trx_addons_columns_wrap>[class*="trx_addons_column-"].columns_padding_center {
    padding-left: 50px
}

.trx_addons_columns_wrap.columns_padding_bottom>[class*="trx_addons_column-"], .trx_addons_columns_wrap>[class*="trx_addons_column-"].columns_padding_bottom {
    padding-bottom: 50px
}

.trx_addons_hover_mask {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0
}

.trx_addons_scroll_to_top {
    border: none
}

.menu_style_side .trx_addons_scroll_to_top {
    right: 4px;
    width: 41px;
    height: 41px;
    line-height: 41px
}

.menu_style_side .trx_addons_scroll_to_top.show {
    bottom: 4px
}

.swiper-container-horizontal>.swiper-pagination {
    bottom: 1.05em
}

.slider_swiper .swiper-pagination-bullet {
    border: 2px solid #fff;
    background-color: transparent;
    width: 12px;
    height: 12px
}

.slider_swiper.slider_controls_side .slider_prev, .slider_swiper.slider_controls_side .slider_next {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    width: 4em;
    height: 4em;
    line-height: 4em;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.slider_swiper.slider_controls_side .slider_prev:hover, .slider_swiper.slider_controls_side .slider_next:hover {
    background-color: #000
}

.slider_swiper.slider_controls_side .slider_prev {
    margin-left: -150px;
    left: 1.5em
}

.slider_swiper.slider_controls_side .slider_next {
    margin-right: -150px;
    right: 1.5em
}

.slider_swiper.slider_controls_side:hover .slider_prev, .slider_swiper.slider_controls_side:hover .slider_next {
    margin-left: 0;
    margin-right: 0
}

.slider_swiper.slider_controls_side .slider_prev:before, .slider_swiper.slider_controls_side .slider_next:before {
    font-family: 'fontello';
    font-size: 0.8571em
}

.slider_swiper.slider_controls_side .slider_prev:before {
    content: '\e907'
}

.slider_swiper.slider_controls_side .slider_next:before {
    content: '\e908'
}

.header_widgets_wrap .slider_swiper .slide_overlay.slide_overlay_large {
    background-color: transparent
}

.slider_swiper.slider_titles_center .slide_info.slide_info_large {
    padding: 3em 2em;
    width: 50%
}

.header_widgets_wrap .slider_swiper.slider_titles_center:not(.slider_multi) .slide_info.slide_info_large {
    width: 18em
}

.slider_swiper .slide_info.slide_info_large .slide_cats {
    color: transparent!important
}

.slider_swiper .slide_info.slide_info_large .slide_cats a {
    font-size: 0.8667em;
    line-height: 1.7333em;
    font-weight: 500;
    text-transform: uppercase
}

.slider_swiper .slide_info.slide_info_large .slide_title {
    font-size: 1.3158em;
    font-weight: 900;
    line-height: 1.5em;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0.5em 0
}

.slider_swiper .slide_info.slide_info_large .slide_date {
    font-size: 0.9333em;
    font-style: italic
}

.sidebar .slider_swiper.slider_multi .slide_title, .sidebar .slider_swiper .slide_info:not(.slide_info_large) .slide_title {
    font-size: 1em
}

.sidebar .slider_swiper.slider_multi .slide_date, .sidebar .slider_swiper .slide_info:not(.slide_info_large) .slide_date {
    font-size: 0.8947em
}

.sidebar .swiper-container-horizontal>.swiper-pagination {
    bottom: 0.5em
}

.sidebar .slider_swiper .swiper-pagination-bullet {
    width: 8px;
    height: 8px
}

aside.widget_bg_image {
    padding: 2em 1.5em
}

.widget_aboutme .aboutme_avatar img {
    width: 100%;
    height: auto
}

.widget_aboutme .aboutme_username {
    text-align: left;
    font-size: 1em
}

.widget_aboutme .aboutme_description {
    margin-top: 1em;
    text-align: left
}

.trx_addons_audio_player {
    padding: 1.4em 2.5em 2em
}

.widget_area .trx_addons_audio_player {
    padding: 1em 1.5em
}

.trx_addons_audio_player.without_cover {
    border: 1px dotted #ddd;
    background-color: transparent
}

.trx_addons_audio_player .audio_author {
    font-size: 0.9474em;
    font-weight: 700;
    font-style: italic;
    margin-left: 0;
    margin-right: 0
}

.trx_addons_audio_player .audio_caption {
    font-size: 1.2632em;
    font-weight: 700;
    line-height: 1.35em;
    letter-spacing: 2px;
    text-transform: none
}

.trx_addons_audio_player .audio_author+.audio_caption {
    margin: -0.6em 0 0.25em
}

.widget_audio .trx_addons_audio_player .audio_caption {
    font-size: 1em;
    letter-spacing: 1px
}

.trx_addons_audio_player .mejs-container .mejs-controls .mejs-time {
    font-size: 11px!important;
    font-weight: 700
}

.trx_addons_audio_player .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total, .trx_addons_audio_player .mejs-controls .mejs-time-rail .mejs-time-total, .trx_addons_audio_player .mejs-controls .mejs-time-rail .mejs-time-buffering, .trx_addons_audio_player .mejs-controls .mejs-time-rail .mejs-time-loaded, .trx_addons_audio_player .mejs-controls .mejs-time-rail .mejs-time-current, .trx_addons_audio_player .mejs-controls a.mejs-horizontal-volume-slider, .trx_addons_audio_player .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current, .trx_addons_audio_player .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
    height: 3px!important
}

aside .trx_addons_tabs .trx_addons_tabs_titles {
    text-align: center
}

aside .trx_addons_tabs .trx_addons_tabs_titles li {
    font-size: 12px;
    font-weight: 700;
    font-style: normal;
    line-height: 1.3em;
    text-transform: uppercase;
    letter-spacing: 1px
}

aside .trx_addons_tabs .trx_addons_tabs_titles li:after {
    display: none!important
}

aside .trx_addons_tabs .trx_addons_tabs_titles li+li {
    margin-left: 0.5em
}

aside .trx_addons_tabs .trx_addons_tabs_titles li a {
    border: 2px solid #000;
    padding: 0.3em 0.5em
}

.widget_categories_list[class*="column-"] [class*="column-"], .sidebar .widget_categories_list [class*="column-"] {
    width: 100%;
    float: none;
    overflow: hidden
}

.widget_categories_list[class*="column-"] [class*="column-"]+[class*="column-"], .sidebar .widget_categories_list [class*="column-"]+[class*="column-"] {
    margin-top: 0.5em
}

.widget_categories_list[class*="column-"] .categories_list_style_1 .categories_list_item, .sidebar .widget_categories_list .categories_list_style_1 .categories_list_item {
    text-align: left
}

.widget_categories_list[class*="column-"] .categories_list_style_1 .categories_list_image, .sidebar .widget_categories_list .categories_list_style_1 .categories_list_image {
    display: inline-block;
    width: 20%
}

.widget_categories_list[class*="column-"] .categories_list_style_1 .categories_list_image img, .sidebar .widget_categories_list .categories_list_style_1 .categories_list_image img {
    width: 100%;
    height: auto
}

.widget_categories_list[class*="column-"] .categories_list_style_1 .categories_list_title, .sidebar .widget_categories_list .categories_list_style_1 .categories_list_title {
    display: inline-block;
    width: 70%;
    margin: 0;
    font-size: 1em;
    padding-left: 1em;
    text-align: left;
    text-transform: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.widget_contacts {
    text-align: left
}

.widget_contacts[class*="column-"] .contacts_info .contacts_left, .widget_contacts[class*="column-"] .contacts_info .contacts_right, .sidebar .widget_contacts .contacts_info .contacts_left, .sidebar .widget_contacts .contacts_info .contacts_right {
    display: block;
    width: 100%;
    margin: 0;
    text-align: left
}

.widget_contacts[class*="column-"] .contacts_description, .widget_contacts[class*="column-"] .contacts_socials, .widget_contacts[class*="column-"] .contacts_info, .sidebar .widget_contacts .contacts_description, .sidebar .widget_contacts .contacts_socials, .sidebar .widget_contacts .contacts_info {
    margin-top: 1em
}

.widget_contacts .contacts_info {
    font-style: italic;
    line-height: 1.5em
}

.widget_contacts[class*="column-"] .contacts_socials .social_item+.social_item, .sidebar .widget_contacts .contacts_socials .social_item+.social_item {
    margin-left: 0.8em
}

body.expand_content .sc_recent_news_style_news-announce .post_size_full {
    height: 656px
}

body.expand_content .sc_recent_news_style_news-announce .post_size_big {
    height: 328px
}

body.expand_content .sc_recent_news_style_news-announce .post_size_medium, body.expand_content .sc_recent_news_style_news-announce .post_size_small {
    height: 164px
}

.sc_recent_news .post_counters .post_counters_edit a {
    background-color: transparent!important
}

.sc_recent_news .post_item .post_footer .post_counters .post_counters_item {
    font-size: 12px
}

.sidebar .sc_recent_news .post_item .post_title, .footer .sc_recent_news .post_item .post_title {
    font-size: 1em
}

.sidebar .sc_recent_news .post_item .post_content, .footer .sc_recent_news .post_item .post_content {
    font-size: 0.8947em;
    line-height: 1.4em
}

.sidebar .widget_socials .socials_wrap {
    text-align: left
}

.sidebar .widget_socials .social_item span {
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em
}

.sidebar .widget_socials .social_item+.social_item {
    margin-left: 0.5em
}

.slider_engine_revo .widget_socials {
    width: 10em
}

.slider_engine_revo .widget_socials .socials_wrap {
    text-align: left;
    min-width: 160px!important
}

.slider_engine_revo .widget_socials .social_item {
    display: inline-block
}

.slider_engine_revo .widget_socials .social_item+.social_item {
    margin-left: 0.5em
}

.slider_engine_revo .widget_socials .social_item span {
    background-color: transparent!important;
    width: auto;
    height: auto;
    font-size: 13px!important;
    line-height: 1.5em;
    display: inline;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.slider_engine_revo .widget_socials .social_item a {
    background-color: transparent;
    background: none
}

.slider_engine_revo .widget_socials .social_item a:hover span {
    background-color: transparent
}

.slider_engine_revo .widget_socials .social_item a:hover span:before {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none
}

.widget_twitter .widget_content ul {
    padding-left: 2em;
    list-style-type: none
}

.widget_twitter .widget_content ul>li {
    position: relative;
    text-align: left
}

.widget_twitter .widget_content ul>li+li {
    margin-top: 1.5em
}

.widget_twitter .widget_content ul>li:before {
    position: absolute;
    z-index: 1;
    display: inline-block;
    font-size: 1.2308em;
    font-style: normal;
    left: -1.5em;
    top: 0;
    background-color: transparent
}

.widget_twitter .widget_content ul>li a {
    font-weight: normal
}

.trx_addons_video_player.with_cover .video_hover {
    font-size: 2.8em;
    width: 2.2em;
    height: 2.2em;
    line-height: 2.2em;
    border: 5px solid #ddd;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0
}

.trx_addons_video_player.with_cover .video_hover:before {
    content: '\e855';
    font-family: 'fontello'
}

.widget_video .trx_addons_video_player.with_cover .video_hover {
    font-size: 1.5em;
    border-width: 3px
}

.mobile_layout .trx_addons_video_player.with_cover .video_hover {
    opacity: 1;
    margin-top: 0
}

.trx_addons_cv_header_letter {
    line-height: 0.75em
}

.trx_addons_cv_header_letter, .trx_addons_cv_header_text, .trx_addons_cv_header_socials .social_item>a {
    text-shadow: none
}

.trx_addons_cv_section_contacts input, .trx_addons_cv_section_contacts textarea {
    -webkit-border-radius: 0!important;
    -moz-border-radius: 0!important;
    border-radius: 0!important
}

.trx_addons_tooltip {
    border-bottom-style: solid
}

.trx_addons_tooltip:before {
    font-size: 0.8947em;
    font-style: italic;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-border-radius: 0!important;
    -moz-border-radius: 0!important;
    border-radius: 0!important
}

.trx_addons_tooltip:after {
    margin-bottom: -8px;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none
}

.trx_addons_dropcap {
    font-size: 5em;
    padding: 0;
    margin: 0 0.2em 0 0;
    width: auto;
    height: auto;
    line-height: 0.85em;
    background-color: transparent
}

.trx_addons_tiny_text {
    letter-spacing: 2px
}

blockquote.trx_addons_blockquote_style_1 {
    background-color: transparent!important
}

blockquote.trx_addons_blockquote_style_2 {
    padding-top: 3em;
    padding-bottom: 3em
}

blockquote.trx_addons_blockquote_style_2:before {
    top: 2em
}

ul.trx_addons_list {
    padding-left: 1.1em
}

ol, ul.trx_addons_list>li {
    font-weight: 700;
    font-style: italic
}

ul.trx_addons_list>li:before {
    left: -1em;
    font-style: normal;
    font-weight: 400
}

ul.trx_addons_list_dot_red>li:before, ul.trx_addons_list_dot_dark>li:before {
    content: ' ';
    display: block;
    width: 5px;
    height: 5px;
    margin-top: 0.4em;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%
}

ul.trx_addons_list_arrow {
    padding-left: 1.1em
}

ul.trx_addons_list_arrow>li:before {
    content: '\e911';
    font-family: 'fontello';
    font-size: 0.7778em;
    line-height: 1.4em;
    left: -1.6em;
    top: 0.2em
}

ul.trx_addons_list_asterisk {
    padding-left: 1.1em
}

ul.trx_addons_list_asterisk>li:before {
    font-size: 0.6316em;
    line-height: 1.4em;
    left: -1.6em;
    top: 0.2em
}

[class*="sc_button_hover_"]:not(.sc_button_bg_image) {
    -webkit-transition: color 0.3s ease!important, border-color 0.3s ease!important, background-position 0.3s ease!important, background-color 0 linear .3s!important;
    -moz-transition: color 0.3s ease!important, border-color 0.3s ease!important, background-position 0.3s ease!important, background-color 0 linear .3s!important;
    -ms-transition: color 0.3s ease!important, border-color 0.3s ease!important, background-position 0.3s ease!important, background-color 0 linear .3s!important;
    -o-transition: color 0.3s ease!important, border-color 0.3s ease!important, background-position 0.3s ease!important, background-color 0 linear .3s!important;
    transition: color 0.3s ease!important, border-color 0.3s ease!important, background-position 0.3s ease!important, background-color 0 linear .3s!important
}

.sc_item_title {
    text-align: center
}

.sc_item_subtitle {
    text-align: center;
    font-size: 1.2632em;
    line-height: 1.25em;
    font-weight: 600;
    font-style: italic;
    text-transform: none;
    letter-spacing: 0
}

.sc_item_title+.sc_item_descr, .sc_item_subtitle+.sc_item_descr, .sc_item_title+.sc_item_slider, .sc_item_subtitle+.sc_item_slider, .sc_item_descr+.sc_item_slider, .sc_item_title+.sc_item_columns, .sc_item_subtitle+.sc_item_columns, .sc_item_descr+.sc_item_columns, .sc_item_title+.sc_item_content, .sc_item_subtitle+.sc_item_content, .sc_item_descr+.sc_item_content {
    margin-top: 2.1em
}

.sc_item_descr {
    margin-bottom: 2.1em
}

.sc_item_title+.sc_item_button, .sc_item_title+.sc_item_button_image, .sc_item_subtitle+.sc_item_button, .sc_item_subtitle+.sc_item_button_image, .sc_item_descr+.sc_item_button, .sc_item_descr+.sc_item_button_image, .sc_item_content+.sc_item_button, .sc_item_content+.sc_item_button_image, .sc_item_slider+.sc_item_button, .sc_item_slider+.sc_item_button_image, .sc_item_columns+.sc_item_button, .sc_item_columns+.sc_item_button_image {
    margin-top: 2.1em
}

.sc_item_button a {
    font-size: 11px;
    line-height: 1.4em;
    font-weight: bold;
    border: 2px solid #000;
    letter-spacing: 1px;
    padding: 1.5em 3.1em
}

.trx_addons_hover_content .trx_addons_hover_links a+a {
    margin-left: 0.7em
}

.sc_action_item_subtitle {
    font-size: 1.1579em;
    line-height: 1.4em;
    font-weight: 700;
    font-style: italic
}

.sc_action_item_title {
    font-size: 2.4737em;
    font-weight: 400;
    line-height: 1em
}

.sc_action_item_date {
    font-size: 12px;
    font-weight: 700;
    line-height: 1.3em;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 1em 0;
    border-style: dotted
}

.sc_action_item_info {
    font-size: 10px;
    font-weight: 700;
    line-height: 1.3em;
    border-style: dotted
}

.sc_action_item_link {
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 1em 4em
}

.sc_action_item_event.with_image .sc_action_item_inner {
    padding: 2.4em 2em
}

.sc_action_item_event .sc_action_item_image+.sc_action_item_subtitle {
    margin-top: 0.8em
}

.sc_action_item_event .sc_action_item_title {
    font-size: 2.1053em;
    font-weight: 400;
    line-height: 1em;
    letter-spacing: 0;
    margin-top: 0;
    margin-bottom: 0.3em
}

.sc_action_item_event .sc_action_item_link {
    border: 2px solid #ddd
}

.menu_side_inner #toc_menu {
    display: block
}

#toc_menu .toc_menu_description {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase
}

#toc_menu .toc_menu_icon {
    height: 1em
}

#toc_menu .toc_menu_item .toc_menu_icon_default:before {
    width: 0.4211em
}

#toc_menu .toc_menu_item_active .toc_menu_icon_default:before {
    width: 0.7368em
}

.sc_blogger_default .sc_blogger_item {
    padding: 2.1429em
}

.sc_blogger_default .sc_blogger_item .sc_blogger_post_meta {
    margin-top: 1em
}

.sc_blogger_default .sc_blogger_item .sc_blogger_item_featured {
    margin-bottom: 0
}

.sc_blogger_classic .sc_blogger_content {
    padding: 2.1429em
}

.sc_blogger_classic .sc_blogger_item .sc_blogger_post_meta {
    margin-top: 0.5em
}

.sc_blogger_classic .sc_blogger_item .sc_blogger_item_excerpt {
    margin-top: 1.5em
}

.sc_blogger_classic .sc_blogger_item .sc_blogger_item_excerpt_text {
    max-height: 8em
}

.sc_blogger_classic .sc_blogger_item .more-link {
    background-color: transparent
}

.sc_item_button .sc_button_simple {
    border: none;
    padding: 0
}

.sc_item_button .sc_button_simple:not(.sc_button_bg_image) {
    background: none!important;
    background-color: transparent
}

.sc_item_button .sc_button_simple {
    font-size: 0.7368em;
    line-height: 1.4em;
    font-weight: 700
}

.sc_courses_default .trx_addons_hover_content {
    text-align: left;
    padding: 2.8em 2.8em 2.8em 3.5714em;
    left: 0;
    top: 0;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none
}

.sc_courses_default .sc_courses_item_info {
    padding: 2.8em 3.5em
}

.sc_courses_default .sc_courses_item_price {
    font-size: 2.2857em;
    margin-top: 0.3em
}

.sc_courses_default .trx_addons_hover_content .trx_addons_hover_title {
    background-color: transparent;
    padding: 0;
    margin: 0;
    text-transform: none;
    line-height: 2em;
    font-size: 1.0714em
}

.sc_courses_default .trx_addons_hover_content .trx_addons_hover_text {
    padding: 0;
    margin-top: 2.5em;
    line-height: 2em;
    font-style: normal
}

.sc_courses_default .trx_addons_hover_content .trx_addons_hover_links a {
    margin-top: 6em;
    font-size: 0.8571em;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 1.3em 1.9em
}

.sc_events_item {
    height: 5.2632em
}

.sc_events_item+.sc_events_item {
    margin-top: 1em
}

.sc_events_button {
    text-align: center
}

.sc_events_item_date {
    height: 5.2632em
}

.sc_events_item_day {
    font-weight: 200;
    margin-top: 0.2em
}

.sc_events_item_button {
    min-height: 1.3em
}

.sc_events_item_button:before, .sc_events_item_button:after {
    position: absolute;
    left: 0;
    top: 0;
    content: '\e908';
    font-family: 'fontello';
    font-size: 0.9286em;
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s
}

.sc_events_item_button:after {
    left: -30px;
    opacity: 0;
    filter: alpha(opacity=0)
}

.sc_events_item:hover .sc_events_item_button:before {
    opacity: 0;
    filter: alpha(opacity=0);
    left: 30px
}

.sc_events_item:hover .sc_events_item_button:after {
    opacity: 1;
    filter: alpha(opacity=100);
    left: 0
}

.sc_events_detailed .sc_events_item_date, .sc_events_detailed .sc_events_item_time, .sc_events_detailed .sc_events_item_button {
    font-size: 0.7368em;
    font-weight: 700;
    line-height: 1.3em;
    text-transform: uppercase
}

.sc_events_detailed .sc_events_item_title {
    font-size: 1.0526em;
    line-height: 1.2em;
    font-weight: 700;
    font-style: italic
}

.sc_events_detailed .sc_events_item, .sc_events_detailed .sc_events_item_date, .sc_events_detailed .sc_events_item_button, .sc_events_detailed .sc_events_item_title, .sc_events_detailed .sc_events_item_time {
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s
}

.sc_form form input.filled.trx_addons_field_error, .sc_form form input.filled.trx_addons_field_error:hover, .sc_form form input.filled.trx_addons_field_error:focus {
    color: #ff0000!important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.sc_form[class*="scheme_"] {
    padding: 5em
}

.sc_form_description {
    font-style: normal
}

.sc_form_detailed .sc_form_info {
    padding: 0
}

.sc_form_title+.sc_form_form, .sc_form_subtitle+.sc_form_form, .sc_form_descr+.sc_form_form {
    margin-top: 4em
}

.sc_form .sc_form_info_item .sc_form_info_icon:before {
    font-family: 'fontello'
}

.sc_form .sc_form_info_item_address .sc_form_info_icon:before {
    content: '\e8fd'
}

.sc_form .sc_form_info_item_email .sc_form_info_icon:before {
    content: '\e8fa'
}

.sc_form .sc_form_info_item_phone .sc_form_info_icon:before {
    content: '\e8f5'
}

.sc_form_field {
    margin-bottom: 2em
}

.sc_form_field_button {
    margin-top: 3em
}

.sc_form button {
    display: inline-block;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 1em 3.3em;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    border: 2px solid #000;
    -webkit-appearance: button;
    cursor: pointer;
    margin-top: 0
}

.sc_form button:after {
    content: ' '
}

.sc_form_default .sc_form_field_button:before {
    content: ' ';
    display: block;
    width: 100%;
    height: 0;
    clear: both
}

.sc_form_default .sc_form_field_button {
    text-align: left
}

.sc_form_modern .sc_form_field_button {
    text-align: center
}

.sc_form_detailed button {
    display: block
}

.sc_form_field_title {
    text-transform: none;
    font-size: 1em;
    line-height: 2em;
    margin-bottom: 0.7em
}

.sc_form input[type="text"], .sc_form textarea {
    border: 1px solid #eee;
    font-size: 1em;
    line-height: 1.5em;
    font-style: normal;
    padding: 1em 1.5em
}

[class*="sc_input_hover_"] input[type="text"], [class*="sc_input_hover_"] input[type="number"], [class*="sc_input_hover_"] input[type="email"], [class*="sc_input_hover_"] input[type="password"], [class*="sc_input_hover_"] input[type="search"], [class*="sc_input_hover_"] select, [class*="sc_input_hover_"] textarea {
    font-size: 0.8889em!important;
    font-weight: 400;
    font-style: normal;
    line-height: 1.7em;
    letter-spacing: 1px;
    padding: 0.875em 1.5em!important
}

[class*="sc_input_hover_"] .sc_form_field_hover {
    font-size: 1em
}

[class*="sc_input_hover_"] .sc_form_field_content {
    font-size: 12px;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase
}

[class*="sc_input_hover_"] label.required:after {
    display: none
}

[class*="sc_input_hover_"] label.required .sc_form_field_content:after {
    font-size: 110%
}

.sc_form_modern form:not(class*="sc_input_hover_") textarea {
    min-height: 11em
}

.sc_input_hover_accent .sc_form_field_hover:before {
    height: 3em
}

.sc_input_hover_accent textarea+.sc_form_field_hover:before {
    height: 9em
}

.sc_input_hover_path .sc_form_field_content {
    padding-top: 2em
}

.sc_input_hover_path textarea+.sc_form_field_hover>.sc_form_field_content {
    padding-top: 3em
}

.sc_input_hover_path input:focus+.sc_form_field_hover>.sc_form_field_content, .sc_input_hover_path textarea:focus+.sc_form_field_hover>.sc_form_field_content, .sc_input_hover_path input.filled+.sc_form_field_hover>.sc_form_field_content, .sc_input_hover_path textarea.filled+.sc_form_field_hover>.sc_form_field_content {
    -webkit-transform: scale3d(0.875, 0.875, 1) translate3d(0, 3.3em, 0);
    -ms-transform: scale3d(0.875, 0.875, 1) translate3d(0, 3.3em, 0);
    transform: scale3d(0.875, 0.875, 1) translate3d(0, 3.3em, 0)
}

.sc_input_hover_path textarea:focus+.sc_form_field_hover>.sc_form_field_content, .sc_input_hover_path textarea.filled+.sc_form_field_hover>.sc_form_field_content {
    -webkit-transform: scale3d(0.875, 0.875, 1) translate3d(0, 13.5em, 0);
    -ms-transform: scale3d(0.875, 0.875, 1) translate3d(0, 13.5em, 0);
    transform: scale3d(0.875, 0.875, 1) translate3d(0, 13.5em, 0)
}

.sc_input_hover_jump .sc_form_field_content:before {
    top: -190%
}

.sc_input_hover_underline .sc_form_field_hover:before {
    height: 2px
}

.sc_input_hover_underline input:focus+.sc_form_field_hover:before, .sc_input_hover_underline textarea:focus+.sc_form_field_hover:before, .sc_input_hover_underline input.filled+.sc_form_field_hover:before, .sc_input_hover_underline textarea.filled+.sc_form_field_hover:before {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1)
}

.sc_input_hover_underline input:focus+.sc_form_field_hover>.sc_form_field_content, .sc_input_hover_underline input.filled+.sc_form_field_hover>.sc_form_field_content {
    -webkit-transform: translate3d(0, 2.7em, 0) scale3d(0.875, 0.875, 1);
    -ms-transform: translate3d(0, 2.7em, 0) scale3d(0.875, 0.875, 1);
    transform: translate3d(0, 2.7em, 0) scale3d(0.875, 0.875, 1)
}

.sc_input_hover_underline textarea:focus+.sc_form_field_hover>.sc_form_field_content, .sc_input_hover_underline textarea.filled+.sc_form_field_hover>.sc_form_field_content {
    height: 5.3em;
    -webkit-transform: translate3d(0, 6.2em, 0) scale3d(0.875, 0.875, 1);
    -ms-transform: translate3d(0, 6.2em, 0) scale3d(0.875, 0.875, 1);
    transform: translate3d(0, 6.2em, 0) scale3d(0.875, 0.875, 1)
}

.sc_input_hover_iconed .sc_form_field_hover {
    padding: 0.45em!important
}

.sc_googlemap_content .sc_form_default .sc_form_details {
    width: 100%;
    float: none
}

.sc_googlemap_content .sc_form_default .sc_form_details>div {
    width: 100%;
    padding-right: 0
}

.sc_googlemap_content .sc_form_default .sc_form_field_message {
    width: 100%;
    float: none;
    padding-left: 0
}

.sc_googlemap_content .sc_form .sc_form_field input[type="text"], .sc_googlemap_content .sc_form .sc_form_field textarea {
    padding: 0.5em 1em
}

.sc_googlemap_content .sc_form .sc_form_field textarea {
    min-height: 3em
}

.sc_googlemap_content_detailed {
    font-size: 0.8421em;
    position: absolute;
    z-index: 1000;
    width: 20em;
    left: 26%;
    top: 26%;
    padding: 1.3em 2.5em;
    line-height: 1.2em
}

.sc_googlemap_content_detailed p {
    margin-bottom: 1em
}

.sc_googlemap_content_detailed:before {
    content: ' ';
    font-family: inherit;
    left: 0;
    top: 10%;
    bottom: 10%;
    width: 8px
}

.sc_icons .sc_icons_icon, .sc_icons .sc_icons_title {
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s
}

.sc_icons_title {
    font-size: 1.2632em;
    line-height: 1.3em;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 1px
}

.sc_icons .sc_icons_image+.sc_icons_title, .sc_icons .sc_icons_image+.sc_icons_description {
    margin-top: 2.5em
}

.sc_icons_description {
    font-size: 1em;
    line-height: 1.3em;
    font-weight: 600;
    font-style: italic
}

.sc_icons_modern .sc_icons_description {
    font-size: 1.1429em;
    line-height: 1.3em
}

.sc_icons .sc_icons_title+.sc_icons_description {
    margin-top: 0.7em
}

.sc_price {
    background-color: transparent;
    border: 2px solid #000
}

.sc_price_info {
    padding: 3em;
    text-align: center
}

.sc_price_period {
    font-size: 1em;
    line-height: 1.3em;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px
}

.sc_price_price {
    font-size: 4.3em;
    font-weight: 400;
    line-height: 1.3em
}

.sc_price_decimals {
    text-decoration: none
}

.sc_price_description {
    height: 3.7em;
    margin-top: 0.9em;
    line-height: 1.2em;
    font-style: italic;
    font-weight: 700
}

.sc_price_link {
    display: inline-block;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 1em 4.5em;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1px;
    background-color: transparent;
    border: 2px solid #000
}

.sc_price_link:after {
    display: none
}

.sc_promo.sc_promo_size_tiny .sc_promo_image {
    margin: 0.5em
}

.sc_promo_text blockquote {
    margin: 0!important
}

.sc_promo.sc_promo_size_normal .sc_promo_icon, .sc_promo.sc_promo_size_large .sc_promo_icon {
    font-size: 7em;
    line-height: 1em;
    margin-bottom: 0.14em
}

.sc_promo_title+.sc_promo_content, .sc_promo_subtitle+.sc_promo_content {
    margin-top: 1.8em
}

.sc_promo_title+.sc_promo_subtitle {
    margin-top: 1.25em
}

.sc_skills_pie.sc_skills_compact_off .sc_skills_total {
    font-weight: 200
}

.sc_skills_counter .sc_skills_icon {
    font-size: 4.2em;
    line-height: 1.15em
}

.sc_skills_counter .sc_skills_total {
    font-weight: 400;
    font-size: 3em;
    line-height: 1em;
    margin: 0.2em 0 0.15em
}

.sc_skills .sc_skills_item_title, .sc_skills_pie.sc_skills_compact_off .sc_skills_item_title {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 0.7368em;
    line-height: 1.3em
}

.sc_skills_pie.sc_skills_compact_off .sc_skills_item_title {
    font-size: 0.8421em;
    line-height: 1.3em;
    margin-top: 0.7em
}

.sc_skills_counter .sc_skills_item_title {
    text-align: center
}

.sc_socials .socials_wrap {
    font-size: 0.9286em
}

.sc_socials .socials_wrap a {
    display: inline-block;
    width: 3.5em;
    height: 3.5em;
    line-height: 3.5em;
    text-align: center
}

.sc_socials .socials_wrap .social_item+.social_item {
    margin-left: 3px
}

.sc_table>p {
    margin: 0!important
}

.sc_table table {
    border-collapse: separate;
    border-spacing: 0 4px
}

.sc_table table tr:first-child th, .sc_table table tr:first-child td {
    background-color: transparent!important;
    border-color: transparent!important;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0
}

.sc_table table tr:nth-child(n+1) th:first-child, .sc_table table tr:nth-child(n+1) td:first-child {
    border-width: 1px
}

.sc_table table tr:nth-child(n+1) th, .sc_table table tr:nth-child(n+1) td {
    border-style: solid;
    border-width: 1px 1px 1px 0
}

.sc_table table td {
    padding: 0.7em 1em;
    font-weight: 700;
    font-style: italic
}

.sc_table table th {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase
}

.sc_testimonials_item_content {
    font-size: 1.2105em;
    line-height: 1.6em;
    font-weight: 600;
    font-style: italic
}

.sc_testimonials [class*="column"] .sc_testimonials_item_content {
    font-size: 1em;
    line-height: 1.5em
}

.sc_testimonials_item_content:before {
    display: none
}

.sc_testimonials_item_author_avatar {
    width: 5.3571em;
    height: 5.3571em
}

.sc_testimonials_item_author {
    text-align: center;
    background-color: transparent;
    margin-top: 0;
    padding: 1.7em 0
}

.sc_testimonials_item_author_avatar, .sc_testimonials_default .sc_testimonials_item_author_data {
    display: block;
    text-align: center;
    vertical-align: baseline;
    margin: 0 auto
}

.sc_testimonials_item_author_title {
    font-size: 0.9474em;
    margin-top: 1.4em
}

.sc_testimonials_item_author_title+.sc_testimonials_item_author_subtitle {
    margin-top: 0.3em;
    font-size: 0.8421em;
    font-style: italic
}

.sc_testimonials_title+.sc_testimonials_slider, .sc_testimonials_subtitle+.sc_testimonials_slider {
    margin-top: 2.4em
}

.sc_testimonials_item_author_data {
    max-width: none
}

.sc_team_default .trx_addons_hover_content .trx_addons_hover_title {
    font-size: 1.0714em
}

.sc_team_default .sc_team_item_info {
    padding: 4.2em 5em
}

.sc_team_default .sc_team_item_title {
    margin-top: 0
}

.sc_team_default .sc_team_item_subtitle {
    font-size: 0.9286em;
    line-height: 1.5em;
    margin-top: 0.6em
}

.sc_team_default .sc_team_item_content {
    margin-top: 2.7em;
    min-height: 6em
}

.sc_team_default .sc_team_item_button {
    margin: 2.6em 0 0;
    text-transform: uppercase;
    font-size: 0.8571em;
    font-weight: bold;
    letter-spacing: 3px
}

.sc_team_default .sc_team_item_button a {
    position: relative
}

.sc_team_default .sc_team_item_button a:before, .sc_team_default .sc_team_item_button a:after {
    content: '\e908';
    font-family: 'fontello';
    margin-left: 0.5em;
    position: relative;
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s
}

.sc_team_default .sc_team_item_button a:before {
    position: absolute;
    right: 20px;
    top: 0;
    line-height: 1em;
    opacity: 0;
    filter: alpha(opacity=0)
}

.sc_team_default .sc_team_item_button a:hover:before {
    opacity: 1;
    filter: alpha(opacity=100);
    right: 1.3em
}

.sc_team_default .sc_team_item_button a:hover:after {
    opacity: 0;
    filter: alpha(opacity=0);
    margin-left: 2em
}

.vc_col-sm-12 {
    min-height: 0
}

.vc_row.vc_row-o-full-height {
    min-height: 100vh!important
}

.vc_row.vc_parallax[class*="scheme_"] .vc_parallax-inner:before {
    content: ' ';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%
}

.vc_row .wpb_column:not(.vc_col-sm-12) blockquote {
    margin: 0
}

.vc_col-has-fill>.vc_column-inner, .vc_row-has-fill+.vc_row-full-width+.vc_row>.vc_column_container>.vc_column-inner, .vc_row-has-fill+.vc_row>.vc_column_container>.vc_column-inner, .vc_row-has-fill+.vc_vc_row>.vc_row>.vc_vc_column>.vc_column_container>.vc_column-inner, .vc_row-has-fill+.vc_vc_row_inner>.vc_row>.vc_vc_column_inner>.vc_column_container>.vc_column-inner, .vc_row-has-fill>.vc_column_container>.vc_column-inner, .vc_row-has-fill>.vc_row>.vc_vc_column>.vc_column_container>.vc_column-inner, .vc_row-has-fill>.vc_vc_column_inner>.vc_column_container>.vc_column-inner {
    padding-top: 0
}

.vc_tta.vc_tta-accordion .vc_tta-panel-body, .vc_tta.vc_tta-accordion .vc_tta-panel-heading {
    border: none!important;
    background: transparent!important
}

.vc_tta.vc_tta-style-classic .vc_tta-panel.vc_active .vc_tta-panel-heading, .vc_tta.vc_tta-style-classic .vc_tta-panel:not(:last-child) .vc_tta-panel-heading, .vc_tta.vc_tta-style-modern .vc_tta-panel.vc_active .vc_tta-panel-heading, .vc_tta.vc_tta-style-modern .vc_tta-panel:not(:last-child) .vc_tta-panel-heading {
    margin-bottom: 1.6em
}

.vc_tta.vc_tta-style-classic .vc_tta-panel.vc_active+.vc_tta-panel .vc_tta-panel-heading, .vc_tta.vc_tta-style-classic .vc_tta-panel:not(:first-child) .vc_tta-panel-heading {
    margin-top: 1.6em
}

.vc_tta.vc_tta-accordion .vc_tta-panel-title {
    font-size: 1em
}

.vc_tta.vc_tta-accordion .vc_tta-panel-title .vc_tta-title-text {
    font-size: 1.1429em;
    line-height: 1.3em
}

.vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-left.vc_tta-panel-title>a {
    padding-left: 4.4em
}

.vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-right.vc_tta-panel-title>a {
    padding-right: 4.4em;
    padding-left: 0
}

.vc_tta.vc_tta-accordion .vc_tta-panel-heading .vc_tta-controls-icon {
    width: 2.8571em;
    height: 2.8571em;
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s
}

.vc_tta.vc_tta-accordion.vc_tta-shape-rounded .vc_tta-panel-heading .vc_tta-controls-icon {
    border-radius: 5px
}

.vc_tta.vc_tta-accordion.vc_tta-shape-round .vc_tta-panel-heading .vc_tta-controls-icon {
    border-radius: 50%
}

.vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-left .vc_tta-controls-icon {
    left: 0
}

.vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-right .vc_tta-controls-icon {
    right: 0
}

.vc_tta.vc_tta-accordion .vc_tta-controls-icon.vc_tta-controls-icon-minus:before, .vc_tta.vc_tta-accordion .vc_tta-controls-icon.vc_tta-controls-icon-plus:before {
    left: 50%;
    right: auto;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 10px
}

.vc_tta.vc_tta-accordion .vc_tta-controls-icon.vc_tta-controls-icon-plus:after {
    top: 50%;
    bottom: auto;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 10px
}

.vc_tta.vc_general .vc_tta-panel-body {
    padding-top: 1.3em;
    padding-bottom: 1.3em
}

.vc_tta.vc_general .vc_tta-panel-body p {
    margin-bottom: 0
}

.vc_tta.vc_general .vc_tta-panel-body p+p {
    margin-top: 1em
}

.vc_tta.vc_tta-color-grey.vc_tta-style-classic .vc_tta-tab>a {
    background-color: transparent;
    border: none;
    padding: 0.6em 1.8em 0.3em
}

.vc_tta.vc_tta-color-grey.vc_tta-style-classic .vc_tta-tab+.vc_tta-tab {
    margin-left: 0.8em
}

.vc_tta-tabs.vc_general .vc_tta-panel-body {
    padding-top: 3em;
    padding-bottom: 3em
}

.vc_separator {
    margin-top: 4.6em;
    margin-bottom: 4.1em!important
}

.vc_separator .vc_sep_holder .vc_sep_line {
    border-style: dotted
}

.vc_progress_bar.vc_progress_bar_narrow .vc_single_bar {
    box-shadow: none;
    border-radius: 0;
    height: 4px;
    margin-top: 0.9em
}

.vc_progress_bar.vc_progress_bar_narrow .vc_single_bar+.vc_single_bar {
    margin-top: 3.1em
}

.vc_progress_bar.vc_progress_bar_narrow .vc_single_bar .vc_label {
    position: absolute;
    font-size: 0.9774em;
    font-weight: 600;
    font-style: italic;
    top: -1.4em;
    padding: 0;
    line-height: 1.5em;
    text-shadow: none;
    display: block;
    width: 100%
}

.vc_progress_bar.vc_progress_bar_narrow .vc_single_bar .vc_label .vc_label_units {
    position: absolute;
    z-index: 1;
    top: 3px;
    right: 0;
    font-style: normal
}

.vc_progress_bar.vc_progress_bar_narrow .vc_single_bar .vc_bar {
    height: 4px;
    border-radius: 0
}

.vc_message_box {
    font-size: 11px;
    font-weight: 700;
    line-height: 1.5em;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 1.4em 0.875em 1.4em 4.9em;
    margin-bottom: 1.875em
}

.vc_message_box-solid-icon {
    padding-left: 7em
}

.vc_message_box-solid-icon .vc_message_box-icon {
    width: 5.25em;
    font-weight: 400
}

.vc_message_box:not(.vc_message_box-solid-icon) .vc_message_box-icon>* {
    left: auto;
    right: 0.4em;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.vc_message_box-icon {
    width: 4.5em
}

.vc_message_box .vc_message_box-icon>* {
    font-size: 2.1em
}

.vc_color-juicy_pink.vc_message_box-outline, .vc_color-juicy_pink.vc_message_box-solid-icon {
    color: #f4524d
}

.vc_color-orange.vc_message_box-outline, .vc_color-orange.vc_message_box-solid-icon {
    color: #e39a31
}

.vc_color-vista_blue.vc_message_box-outline .vc_message_box-icon, .vc_color-vista_blue.vc_message_box-solid-icon .vc_message_box-icon, .vc_color-vista_blue.vc_message_box-outline, .vc_color-vista_blue.vc_message_box-solid-icon {
    color: #9dbf00
}

.vc_color-vista_blue.vc_message_box-outline, .vc_color-vista_blue.vc_message_box-solid-icon {
    border-color: #9dbf00
}

.vc_color-vista_blue.vc_message_box-solid-icon .vc_message_box-icon {
    background-color: #9dbf00
}

.vc_message_box_closeable {
    position: relative
}

.vc_message_box_closeable:after {
    content: '\e8ac';
    font-family: 'fontello';
    font-size: 1.5em;
    line-height: 1em;
    font-weight: 400;
    padding: 1em;
    color: inherit;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.wpb_text_column[class*="scheme_"] {
    padding: 3.1em 3.6em
}

.wpb_gmaps_widget .wpb_wrapper {
    padding: 0
}

.tooltipster-content .wpb_text_column[class*="scheme_"] {
    padding: 1.5em 1em
}

.tooltipster-base.tooltipster-light {
    border: none;
    border-radius: 0;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.3)
}

.tooltipster-base.tooltipster-light .tooltipster-arrow {
    display: none
}

.tooltipster-base.tooltipster-light .tooltipster-content {
    padding: 0;
    border: none
}

.woocommerce .list_products_header, .woocommerce-page .list_products_header {
    padding: 1em 0;
    margin-bottom: 2em;
    display: none
}

.woocommerce .woocommerce-breadcrumb {
    font-size: 0.9286em;
    display: none
}

.woocommerce .post_item_single .woocommerce-breadcrumb {
    margin: -1.2em 0 3em
}

.woocommerce .page-title {
    margin: 0;
    text-align: center;
    font-size: 1.9231em
}

.woocommerce .laon_wine_house_shop_mode_buttons, .woocommerce-page .laon_wine_house_shop_mode_buttons {
    float: left;
    font-size: 0.9474em;
    margin: 1.3em 0.5em 1.3em 0;
    display: block
}

.woocommerce .button, .woocommerce-page .button, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button.woocommerce #respond input#submit, .woocommerce input[type="button"], .woocommerce-page input[type="button"], .woocommerce input[type="submit"], .woocommerce-page input[type="submit"] {
    font-size: 12px;
    line-height: 1.3em;
    text-transform: uppercase;
    border: 2px solid #ddd;
    -webkit-border-radius: 0!important;
    -moz-border-radius: 0!important;
    border-radius: 0!important
}

.woocommerce .woocommerce-result-count {
    margin: 1.2em 0.5em 1.2em 0
}

.woocommerce .laon_wine_house_shop_mode_buttons a+a, .woocommerce-page .laon_wine_house_shop_mode_buttons a+a {
    margin-left: 0.5em
}

.woocommerce .woocommerce-ordering {
    margin-top: 0.9em;
    margin-bottom: 2em;
    border-right: 1px solid #ddd
}

.woocommerce ul.products, .woocommerce-page ul.products {
    margin-right: -2.6316em;
    margin-bottom: 0
}

.woocommerce.columns-1 ul.products li.product, .woocommerce-page.columns-1 ul.products li.product, .woocommerce ul.products li.product, .woocommerce-page ul.products li.product, .woocommerce-page[class*="columns-"] ul.products li.product, .woocommerce[class*="columns-"] ul.products li.product {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    clear: none;
    padding: 0 2.6316em 2.6316em 0;
    position: relative;
    width: 100%
}

.woocommerce.columns-2 ul.products li.product, .woocommerce-page.columns-2 ul.products li.product, .woocommerce .related.products ul.products li.product.column-1_2, .woocommerce-page .related.products ul.products li.product.column-1_2, .woocommerce .upcells.products ul.products li.product.column-1_2, .woocommerce-page .upcells.products ul.products li.product.column-1_2, .woocommerce ul.products li.product.column-1_2, .woocommerce-page ul.products li.product.column-1_2 {
    width: 50%
}

.woocommerce.columns-3 ul.products li.product, .woocommerce-page.columns-3 ul.products li.product, .woocommerce .related.products ul.products li.product.column-1_3, .woocommerce-page .related.products ul.products li.product.column-1_3, .woocommerce .upcells.products ul.products li.product.column-1_3, .woocommerce-page .upcells.products ul.products li.product.column-1_3, .woocommerce ul.products li.product.column-1_3, .woocommerce-page ul.products li.product.column-1_3 {
    width: 33.3333%
}

.woocommerce.columns-4 ul.products li.product, .woocommerce-page.columns-4 ul.products li.product, .woocommerce .related.products ul.products li.product.column-1_4, .woocommerce-page .related.products ul.products li.product.column-1_4, .woocommerce .upcells.products ul.products li.product.column-1_4, .woocommerce-page .upcells.products ul.products li.product.column-1_4, .woocommerce ul.products li.product.column-1_4, .woocommerce-page ul.products li.product.column-1_4 {
    width: 25%
}

.woocommerce.columns-5 ul.products li.product, .woocommerce-page.columns-5 ul.products li.product, .woocommerce .related.products ul.products li.product.column-1_5, .woocommerce-page .related.products ul.products li.product.column-1_5, .woocommerce .upcells.products ul.products li.product.column-1_5, .woocommerce-page .upcells.products ul.products li.product.column-1_5, .woocommerce ul.products li.product.column-1_5, .woocommerce-page ul.products li.product.column-1_5 {
    width: 20%
}

.woocommerce.columns-6 ul.products li.product, .woocommerce-page.columns-6 ul.products li.product, .woocommerce .related.products ul.products li.product.column-1_6, .woocommerce-page .related.products ul.products li.product.column-1_6, .woocommerce .upcells.products ul.products li.product.column-1_6, .woocommerce-page .upcells.products ul.products li.product.column-1_6, .woocommerce ul.products li.product.column-1_6, .woocommerce-page ul.products li.product.column-1_6 {
    width: 16.6667%
}

.woocommerce ul.products li.product-category mark, .woocommerce-page ul.products li.product-category mark {
    background: none
}

.woocommerce ul.products li.product .post_item {
    margin-bottom: 0;
    border: 1px solid #ddd
}

.woocommerce ul.products li.product .post_featured {
    overflow: visible;
    margin-bottom: 0.4em
}

.product_awards {
    position: absolute;
    right: 10%;
    top: 10%;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    font-style: normal;
    width: 5.5em;
    height: 5.5em;
    overflow: hidden;
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%
}

.product_awards span {
    display: block;
    position: absolute;
    width: 80%;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%)
}

.woocommerce-main-image {
    position: relative;
    display: block
}

.woocommerce-main-image .product_awards {
    font-size: 1.4737em;
    line-height: 1.25em;
    right: 6%;
    top: 16%
}

.home_products .woocommerce ul.products {
    margin: -20px -20px 0 0
}

.home_products .woocommerce.columns-1 ul.products li.product, .home_products .woocommerce ul.products li.product {
    padding: 20px 20px 0 0!important;
    margin: 0!important
}

.home_products .woocommerce ul.products li.product .post_item {
    border: none
}

.home_products .woocommerce ul.products li.product .post_data {
    display: none
}

.home_products .woocommerce ul.products li.product .post_featured {
    margin: 0
}

.home_products .woocommerce ul.products li.product .post_featured img {
    max-width: 75%;
    margin: 0 auto;
}

.woocommerce .woocommerce-message .button, .woocommerce ul.products li.product .button, .woocommerce div.product form.cart .button {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 11px;
    font-style: normal;
    font-weight: bold;
    padding: 1em 4em;
    text-transform: uppercase;
    margin-top: 0
}

.woocommerce .woocommerce-message .button, .woocommerce ul.products li.product .button {
    border: 2px solid #000;
    background: none
}

.woocommerce div.product form.cart .button {
    padding: 1.5em 3em
}

.woocommerce ul.products li.product .button:before, .woocommerce div.product form.cart .button:before {
    content: '\e8cc';
    font-family: 'fontello';
    margin-right: 0.6em
}

.woocommerce ul.products li.product a img, .woocommerce-page ul.products li.product a img, .woocommerce ul.products li.product a:hover img, .woocommerce-page ul.products li.product a:hover img {
    margin: 0;
    box-shadow: none
}

.woocommerce ul.products li.product .onsale, .woocommerce-page ul.products li.product .onsale {
    font-size: 12px;
    line-height: 5.8em;
    width: 5.5em;
    height: 5.5em;
    top: 10%;
    right: 10%;
    margin: 0;
    padding: 0;
    text-transform: uppercase
}

.woocommerce ul.products li.product .post_header .post_tags {
    font-size: 0.9474em;
    font-weight: 600;
    font-style: italic;
    line-height: 1.4em;
    letter-spacing: 1px
}

.woocommerce ul.products li.product .post_header, .woocommerce-page ul.products li.product .post_header {
    margin-bottom: 0
}

.woocommerce ul.products li.product h3, .woocommerce-page ul.products li.product h3 {
    font-size: 0.9474em;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    overflow: hidden;
    max-height: 4em
}

.woocommerce ul.products li.product .price, .woocommerce-page ul.products li.product .price {
    font-size: 0.8421em;
    font-weight: 700;
    margin: 0.4em 0 0;
    line-height: 1em
}

span.amount {
    line-height: 1em
}

span.amount>.decimals {
    display: inline;
    font-size: 0.5em;
    line-height: 0.7em;
    margin-left: 0.2em;
    vertical-align: top
}

.woocommerce ul.products li.product .price .decimals, .woocommerce-page ul.products li.product .price .decimals {
    font-size: 0.65em;
    position: relative;
    top: 0.2em
}

.woocommerce ul.products li.product .price ins {
    text-decoration: none;
    font-weight: inherit;
    font-style: inherit
}

.woocommerce ul.products li.product .price del {
    display: inline-block;
    margin-right: 6px;
    font-size: 0.7895em;
    opacity: 1;
    text-decoration: none;
    position: relative
}

.woocommerce ul.products li.product .price del:before {
    content: ' ';
    position: absolute;
    top: 45%;
    left: 0;
    width: 100%;
    height: 1px
}

.woocommerce ul.products li.product .post_data, .woocommerce-page ul.products li.product .post_data {
    padding: 0 2em 1.7em;
    text-align: center
}

.woocommerce ul.products li.product strong {
    margin-top: 0.5em
}

.woocommerce ul.products li.product .post_data .add_to_cart_button, .woocommerce-page ul.products li.product .post_data .add_to_cart_button {
    display: none
}

.woocommerce ul.products li.product .post_item {
    position: relative
}

.woocommerce ul.products li.product .post_featured {
    position: static
}

.woocommerce .shop_mode_list ul.products li.product, .woocommerce-page .shop_mode_list ul.products li.product {
    overflow: hidden;
    margin: 0;
    float: none;
    width: 100%
}

.woocommerce .shop_mode_list ul.products li.product .post_item, .woocommerce-page .shop_mode_list ul.products li.product .post_item {
    overflow: hidden;
    text-align: left;
    border: none
}

.woocommerce .shop_mode_list ul.products li.product+li.product .post_item, .woocommerce-page .shop_mode_list ul.products li.product+li.product .post_item {
    border-top: 1px dotted #ddd;
    margin-top: 3em;
    padding-top: 5em
}

.woocommerce .shop_mode_list ul.products li.product .post_data, .woocommerce-page .shop_mode_list ul.products li.product .post_data {
    height: auto;
    overflow: hidden;
    text-align: left;
    padding: 0
}

.woocommerce .shop_mode_list ul.products li.product .post_featured, .woocommerce-page .shop_mode_list ul.products li.product .post_featured {
    float: left;
    margin: 0 2.1429em 0 0;
    width: 33%;
    max-width: 270px;
    position: relative;
    border: 1px solid #ddd
}

.woocommerce .shop_mode_list ul.products li.product .post_tags, .woocommerce-page .shop_mode_list ul.products li.product .post_tags {
    font-size: 1.1em;
    line-height: 1.4em;
    margin-bottom: 0.3em
}

.woocommerce .shop_mode_list ul.products li.product h3, .woocommerce-page .shop_mode_list ul.products li.product h3 {
    margin-bottom: 0.5em!important;
    border-bottom: none;
    text-align: left!important;
    font-size: 1.5789em
}

.woocommerce .shop_mode_list ul.products li.product .post_content, .woocommerce-page .shop_mode_list ul.products li.product .post_content {
    font-size: 0.8947em;
    line-height: 1.4em
}

.woocommerce .shop_mode_list ul.products li.product .description, .woocommerce-page .shop_mode_list ul.products li.product .description {
    margin-bottom: 1.5em;
    line-height: 1.3em;
    overflow: hidden;
    max-height: 6.6em
}

.woocommerce .shop_mode_list ul.products li.product-category .description, .woocommerce-page .shop_mode_list ul.products li.product-category .description {
    border-bottom: none
}

.woocommerce .shop_mode_list ul.products li.product .description p, .woocommerce-page .shop_mode_list ul.products li.product .description p, .woocommerce .shop_mode_list ul.products li.product .description ul, .woocommerce-page .shop_mode_list ul.products li.product .description ul {
    padding-bottom: 0;
    margin-bottom: 0;
    line-height: 1.3em
}

.woocommerce .shop_mode_list ul.products li.product .price, .woocommerce-page .shop_mode_list ul.products li.product .price {
    font-size: 1.5789em;
    line-height: 1em;
    font-weight: 600;
    font-style: italic;
    margin: 0.7em 0
}

.woocommerce ul.products li.product .price .decimals, .woocommerce-page ul.products li.product .price .decimals {
    font-size: 0.5em
}

.woocommerce a.added_to_cart, .woocommerce-page a.added_to_cart {
    padding: 1.3em 0 0 1em;
    vertical-align: top;
    line-height: 1em
}

.woocommerce #respond input#submit.loading:after, .woocommerce a.button.loading:after, .woocommerce button.button.loading:after, .woocommerce input.button.loading:after {
    display: none
}

.woocommerce .products .star-rating {
    float: none;
    margin: 0.6em auto -0.6em
}

.woocommerce ul.products li.product .post_header {
    position: relative;
    overflow: visible
}

.woocommerce ul.products li.product .star-rating {
    position: absolute;
    top: -1.4em;
    left: 50%;
    margin: 0;
    font-size: 0.7em;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%)
}

.woocommerce .shop_mode_list .products .star-rating, .woocommerce .shop_mode_list ul.products li.product .star-rating {
    position: relative;
    top: auto;
    left: auto;
    font-size: 0.8421em;
    float: none;
    margin: -0.5em 0 1em;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none
}

.woocommerce .products .star-rating:before, .woocommerce .products .star-rating span {
    top: 1px
}

.woocommerce nav.woocommerce-pagination ul {
    border: none;
    list-style-type: none;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 2.7em;
    text-align: center
}

.woocommerce nav.woocommerce-pagination ul li {
    border: none;
    display: inline-block;
    margin: 0 0.2em 0.2em 0
}

.woocommerce nav.woocommerce-pagination ul li a, .woocommerce nav.woocommerce-pagination ul li span.current {
    border: none;
    display: block;
    width: 4.3333em;
    height: 4.3333em;
    line-height: 4.3333em;
    text-align: center;
    padding: 0
}

.woocommerce nav.woocommerce-pagination ul li a.prev, .woocommerce nav.woocommerce-pagination ul li a.next {
    content: '\e907';
    font-family: 'fontello';
    font-size: 1em;
    width: 4.3333em;
    height: 4.3333em;
    line-height: 4.3333em;
    text-align: center
}

.woocommerce nav.woocommerce-pagination ul li a.prev {
    content: '\e908'
}

.woocommerce .woocommerce-error .button, .woocommerce .woocommerce-info .button, .woocommerce .woocommerce-message .button {
    margin: 0 0 0.5em 1em
}

.woocommerce div.product .product_title {
    display: none;
    margin: -0.35em 0 0
}

.woocommerce div.product p.price {
    position: relative;
    font-size: 1.8em;
    font-weight: 700;
    margin: 0 0 1.5em
}

.woocommerce div.product p.price ins {
    text-decoration: none;
    font-style: normal;
    font-weight: 400
}

.woocommerce div.product p.price del {
    font-size: 0.75em;
    font-style: normal;
    font-weight: 400
}

.woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button {
    line-height: 1.25em
}

.woocommerce div.product .product_meta {
    line-height: 1.4em
}

.woocommerce div.product .product_meta span>a {
    font-weight: bold
}

.woocommerce div.product span.onsale {
    z-index: 1;
    left: 2em;
    top: 2em;
    font-size: 12px;
    line-height: 5.8em;
    width: 5.5em;
    height: 5.5em;
    margin: 0;
    padding: 0;
    text-transform: uppercase
}

.woocommerce div.product div.images, .woocommerce #content div.product div.images, .woocommerce-page div.product div.images, .woocommerce-page #content div.product div.images {
    margin-bottom: 2em;
    width: 45%
}

.woocommerce div.product div.images img {
    border: 1px solid #ddd
}

.woocommerce #content div.product div.thumbnails a, .woocommerce div.product div.thumbnails a, .woocommerce-page #content div.product div.thumbnails a, .woocommerce-page div.product div.thumbnails a {
    margin-right: 1.5%;
    width: 18.5%;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.woocommerce #content div.product div.thumbnails a:hover, .woocommerce div.product div.thumbnails a:hover, .woocommerce-page #content div.product div.thumbnails a:hover, .woocommerce-page div.product div.thumbnails a:hover {
    border-style: solid
}

.woocommerce #content div.product div.thumbnails.columns-4 a, .woocommerce div.product div.thumbnails.columns-4 a, .woocommerce-page #content div.product div.thumbnails.columns-4 a, .woocommerce-page div.product div.thumbnails.columns-4 a {
    margin-right: 2.5%;
    width: 22.5%
}

.woocommerce #content div.product div.thumbnails.columns-3 a, .woocommerce div.product div.thumbnails.columns-3 a, .woocommerce-page #content div.product div.thumbnails.columns-3 a, .woocommerce-page div.product div.thumbnails.columns-3 a {
    margin-right: 3.3333%;
    width: 33%
}

.woocommerce #content div.product div.thumbnails a:before, .woocommerce div.product div.thumbnails a:before, .woocommerce-page #content div.product div.thumbnails a:before, .woocommerce-page div.product div.thumbnails a:before {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s
}

.woocommerce #content div.product div.thumbnails a:hover:before, .woocommerce div.product div.thumbnails a:hover:before, .woocommerce-page #content div.product div.thumbnails a:hover:before, .woocommerce-page div.product div.thumbnails a:hover:before {
    background-color: rgba(255, 255, 255, 0.4)
}

.woocommerce #content div.product div.thumbnails a.first, .woocommerce div.product div.thumbnails a.first, .woocommerce-page #content div.product div.thumbnails a.first, .woocommerce-page div.product div.thumbnails a.first {
    clear: none
}

.woocommerce #content div.product div.thumbnails a.last, .woocommerce div.product div.thumbnails a.last, .woocommerce-page #content div.product div.thumbnails a.last, .woocommerce-page div.product div.thumbnails a.last {
    margin-right: 1.5%
}

.woocommerce #content div.product div.thumbnails a:nth-child(5n+1), .woocommerce div.product div.thumbnails a:nth-child(5n+1), .woocommerce-page #content div.product div.thumbnails a:nth-child(5n+1), .woocommerce-page div.product div.thumbnails a:nth-child(5n+1) {
    clear: both
}

div.pp_woocommerce .pp_arrow_next, div.pp_woocommerce .pp_arrow_previous {
    margin-top: 4px
}

.woocommerce #content div.product div.summary, .woocommerce div.product div.summary, .woocommerce-page #content div.product div.summary, .woocommerce-page div.product div.summary {
    width: 55%;
    padding-left: 5em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.woocommerce #content div.product div.summary:after, .woocommerce div.product div.summary:after, .woocommerce-page #content div.product div.summary:after, .woocommerce-page div.product div.summary:after {
    content: ' ';
    display: block;
    width: 100%;
    height: 0;
    clear: both;
    float: none
}

.woocommerce .product_meta span {
    display: block
}

.woocommerce .product_meta span span {
    display: inline
}

.woocommerce .product_meta span {
    font-weight: 400
}

.woocommerce .product_meta span span, .woocommerce .product_meta a {
    font-weight: 400
}

.woocommerce div.product .summary .stock {
    font-size: 12px;
    font-weight: bold;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: 1px
}

.single-product .summary .quantity input.qty {
    border-width: 1px!important
}

.woocommerce div.product form.cart, .woocommerce #content div.product form.cart, .woocommerce-page div.product form.cart, .woocommerce-page #content div.product form.cart {
    overflow: hidden;
    padding: 0.5em 0;
    margin-bottom: 1em
}

.woocommerce div.product form.cart div.quantity, .woocommerce-page div.product form.cart div.quantity {
    margin: 0 0.5em 0 0;
    width: 8em;
    height: 3em;
    position: relative
}

.woocommerce div.product form.cart div.quantity span, .woocommerce-page div.product form.cart div.quantity span {
    display: block;
    position: absolute;
    z-index: 1;
    right: 0;
    width: 1.5em;
    height: 50%;
    text-align: center;
    line-height: 1.5em;
    cursor: pointer
}

.woocommerce div.product form.cart div.quantity span.q_inc, .woocommerce-page div.product form.cart div.quantity span.q_inc {
    top: 0
}

.woocommerce div.product form.cart div.quantity span.q_dec, .woocommerce-page div.product form.cart div.quantity span.q_dec {
    bottom: 0;
    border-top: 1px solid #ffffff
}

.woocommerce div.product form.cart div.quantity span:before, .woocommerce-page div.product form.cart div.quantity span:before {
    font-family: 'fontello'
}

.woocommerce div.product form.cart div.quantity span.q_inc:before, .woocommerce-page div.product form.cart div.quantity span.q_inc:before {
    content: '\e835'
}

.woocommerce div.product form.cart div.quantity span.q_dec:before, .woocommerce-page div.product form.cart div.quantity span.q_dec:before {
    content: '\e828'
}

.woocommerce div.product form.cart div.quantity input[type="number"], .woocommerce-page div.product form.cart div.quantity input[type="number"] {
    width: 100%;
    height: 100%;
    padding: 0;
    font-size: 1.25em
}

.woocommerce div.product .woocommerce-tabs ul.tabs, .woocommerce #content div.product .woocommerce-tabs ul.tabs, .woocommerce-page div.product .woocommerce-tabs ul.tabs, .woocommerce-page #content div.product .woocommerce-tabs ul.tabs {
    margin-bottom: -1px;
    padding-left: 30px
}

.woocommerce div.product .woocommerce-tabs ul.tabs:before, .woocommerce #content div.product .woocommerce-tabs ul.tabs:before, .woocommerce-page div.product .woocommerce-tabs ul.tabs:before, .woocommerce-page #content div.product .woocommerce-tabs ul.tabs:before {
    border-width: 0
}

.woocommerce div.product .woocommerce-tabs ul.tabs li, .woocommerce #content div.product .woocommerce-tabs ul.tabs li, .woocommerce-page div.product .woocommerce-tabs ul.tabs li, .woocommerce-page #content div.product .woocommerce-tabs ul.tabs li {
    background: #ffffff;
    box-shadow: none;
    border: none
}

.woocommerce div.product .woocommerce-tabs ul.tabs li.active, .woocommerce #content div.product .woocommerce-tabs ul.tabs li.active, .woocommerce-page div.product .woocommerce-tabs ul.tabs li.active, .woocommerce-page #content div.product .woocommerce-tabs ul.tabs li.active {
    border: 1px solid #dddddd;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-color: #ffffff
}

.woocommerce div.product .woocommerce-tabs ul.tabs li:before, .woocommerce #content div.product .woocommerce-tabs ul.tabs li:before, .woocommerce-page div.product .woocommerce-tabs ul.tabs li:before, .woocommerce-page #content div.product .woocommerce-tabs ul.tabs li:before, .woocommerce div.product .woocommerce-tabs ul.tabs li:after, .woocommerce #content div.product .woocommerce-tabs ul.tabs li:after, .woocommerce-page div.product .woocommerce-tabs ul.tabs li:after, .woocommerce-page #content div.product .woocommerce-tabs ul.tabs li:after {
    display: none
}

.woocommerce div.product .woocommerce-tabs ul.tabs li.active a, .woocommerce #content div.product .woocommerce-tabs ul.tabs li.active a, .woocommerce-page div.product .woocommerce-tabs ul.tabs li.active a, .woocommerce-page #content div.product .woocommerce-tabs ul.tabs li.active a {
    color: inherit;
    text-shadow: inherit
}

.woocommerce div.product .woocommerce-tabs ul.tabs li a, .woocommerce #content div.product .woocommerce-tabs ul.tabs li a, .woocommerce-page div.product .woocommerce-tabs ul.tabs li a, .woocommerce-page #content div.product .woocommerce-tabs ul.tabs li a {
    font-weight: 400;
    padding: 0.4em 2em
}

.woocommerce div.product .woocommerce-tabs .panel, .woocommerce #content div.product .woocommerce-tabs .panel, .woocommerce-page div.product .woocommerce-tabs .panel, .woocommerce-page #content div.product .woocommerce-tabs .panel {
    border-width: 1px;
    border-style: solid;
    margin: 0;
    padding: 1.5em 1.5em 1em 1.5em;
    display: none
}

.woocommerce div.product .woocommerce-tabs h2, .woocommerce #content div.product .woocommerce-tabs h2, .woocommerce-page div.product .woocommerce-tabs h2, .woocommerce-page #content div.product .woocommerce-tabs h2 {
    margin: 0 0 1em;
    font-size: 1.5385em;
    font-weight: 400
}

.woocommerce div.product .woocommerce-tabs h3, .woocommerce #content div.product .woocommerce-tabs h3, .woocommerce-page div.product .woocommerce-tabs h3, .woocommerce-page #content div.product .woocommerce-tabs h3 {
    font-size: 1.5385em;
    margin-bottom: 0.25em!important
}

.woocommerce div.product .woocommerce-tabs p, .woocommerce #content div.product .woocommerce-tabs p, .woocommerce-page div.product .woocommerce-tabs p, .woocommerce-page #content div.product .woocommerce-tabs p {
    margin: 0
}

.woocommerce div.product .woocommerce-tabs p+p, .woocommerce #content div.product .woocommerce-tabs p+p, .woocommerce-page div.product .woocommerce-tabs p+p, .woocommerce-page #content div.product .woocommerce-tabs p+p {
    margin-top: 0.5em
}

.woocommerce div.product .woocommerce-tabs #tab-description h2 {
    display: none
}

.single-product div.product .trx-stretch-width-wrap {
    clear: both;
    padding-top: 3em
}

.single-product div.product .trx-stretch-width .woocommerce-tabs {
    padding: 5em 0 0;
    border-top: 1px dotted #ddd
}

.single-product div.product .trx-stretch-width .woocommerce-tabs .panel {
    border: none;
    padding: 3em 0 0 0
}

.single-product div.product .trx-stretch-width .woocommerce-tabs .wc-tabs {
    padding-left: 0
}

.single-product div.product .trx-stretch-width .woocommerce-tabs .wc-tabs:before, .single-product div.product .trx-stretch-width .woocommerce-tabs .wc-tabs:after {
    display: none
}

.single-product div.product .trx-stretch-width .woocommerce-tabs .wc-tabs li {
    border: none!important;
    background-color: transparent!important;
    margin: 0!important;
    padding: 0!important
}

.single-product div.product .trx-stretch-width .woocommerce-tabs .wc-tabs li a {
    position: relative;
    font-size: 11px;
    font-style: normal;
    font-weight: bold;
    padding: 1em 4em;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 2px solid #000;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.single-product div.product .trx-stretch-width .woocommerce-tabs #review_form_wrapper {
    margin-top: 1em
}

.woocommerce div.product .woocommerce-product-rating, .woocommerce-page div.product .woocommerce-product-rating {
    float: right;
    margin-top: 0.15em;
    margin-bottom: 1em
}

.woocommerce .woocommerce-product-rating .woocommerce-review-link, .woocommerce-page .woocommerce-product-rating .woocommerce-review-link {
    display: none
}

.woocommerce .woocommerce-product-rating .star-rating, .woocommerce-page .woocommerce-product-rating .star-rating {
    margin-top: 4px;
    margin-bottom: 4px
}

.woocommerce .star-rating, .woocommerce-page .star-rating {
    height: 1.2em;
    margin-top: 4px;
    margin-bottom: 4px
}

.woocommerce #reviews #comments ol.commentlist {
    padding: 0;
    font-style: normal;
    font-weight: normal
}

.woocommerce #reviews #comments ol.commentlist li {
    margin-bottom: 0
}

.woocommerce .woocommerce-tabs .form-submit {
    display: block
}

.woocommerce #reviews #comments ol.commentlist li img.avatar, .woocommerce-page #reviews #comments ol.commentlist li img.avatar {
    background: none;
    border: none;
    padding: 0;
    width: 50px;
    float: left;
    margin: 0 1.5em 0.5em 0;
    position: static
}

.woocommerce #reviews #comments ol.commentlist li .comment-text, .woocommerce-page #reviews #comments ol.commentlist li .comment-text {
    margin: 0;
    padding: 0;
    border: 0
}

.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta, .woocommerce-page #reviews #comments ol.commentlist li .comment-text p.meta {
    margin-top: -5px
}

.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta strong, .woocommerce-page #reviews #comments ol.commentlist li .comment-text p.meta strong {
    font-size: 1.2857em
}

.woocommerce #review_form #respond textarea, .woocommerce-page #review_form #respond textarea {
    height: 10em;
    font-weight: 400;
    overflow: auto
}

.woocommerce #review_form #respond p {
    margin: 0 0 1.5em
}

.woocommerce #review_form #respond p.form-submit input[type="submit"], .woocommerce-page #review_form #respond p.form-submit input[type="submit"] {
    font-size: 11px;
    font-style: normal;
    font-weight: bold;
    padding: 1em 4em;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 2px solid #000;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.single-product .related {
    margin-top: 5em;
    padding-top: 4em;
    border-top: 1px dotted #ddd
}

.single-product .related h2 {
    text-align: center;
    margin-top: 0
}

.single-product ul.products li.product .post_item {
    padding: 0em;
    position: relative
}

.single-product ul.products li.product .post_featured {
    margin: 0;
    min-height: 12em;
    overflow: visible
}

.single-product ul.products li.product .post_data {
    position: absolute;
    z-index: 1001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 3em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all ease .5s;
    -moz-transition: all ease .5s;
    -ms-transition: all ease .5s;
    -o-transition: all ease .5s;
    transition: all ease .5s
}

.single-product ul.products li.product:hover .post_data {
    opacity: 1;
    filter: alpha(opacity=100)
}

.single-product ul.products li.product .post_data .post_header {
    margin-top: 2em;
    position: relative
}

.single-product ul.products li.product .post_data .post_header .post_tags {
    font-size: 1.0584em
}

.single-product ul.products li.product .post_data .post_header h3 {
    font-size: 1.3684em
}

.single-product ul.products li.product .post_data .post_header .star-rating {
    top: 4em
}

.single-product ul.products li.product .post_data .price {
    font-size: 1.5789em;
    line-height: 1em;
    margin: 1.5em 0
}

.single-product ul.products li.product .post_data .add_to_cart_button {
    display: inline-block;
    padding: 1em 3em
}

.single-product .copyright_wrap {
    margin-top: 5em
}

.single-product .logo_footer_wrap+.copyright_wrap, .single-product .socials_footer_wrap+.copyright_wrap, .single-product .menu_footer_wrap+.copyright_wrap {
    margin-top: 0
}

.woocommerce .related ul li.product, .woocommerce .related ul.products li.product, .woocommerce .upsells.products ul li.product, .woocommerce .upsells.products ul.products li.product, .woocommerce-page .related ul li.product, .woocommerce-page .related ul.products li.product, .woocommerce-page .upsells.products ul li.product, .woocommerce-page .upsells.products ul.products li.product {
    width: 50%;
    border: none;
    box-shadow: none
}

.woocommerce ul.products li.product.product-category .thumb_wrapper, .woocommerce-page ul.products li.product.product-category .thumb_wrapper {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    /*background: url(../images/woo/shop-cat-bg-1.html) center center no-repeat;*/
    padding: 1em;
    overflow: hidden
}

.woocommerce ul.products li.product.product-category .thumb_wrapper img, .woocommerce-page ul.products li.product.product-category .thumb_wrapper img {
    -moz-transform: scale(1.7) translate(15%, 15%);
    -o-transform: scale(1.7) translate(15%, 15%);
    -ms-transform: scale(1.7) translate(15%, 15%)
}

.woocommerce ul.products li.product.product-category:nth-child(6n+2) .thumb_wrapper, .woocommerce-page ul.products li.product.product-category:nth-child(6n+2) .thumb_wrapper {
    /*background: url(../images/woo/shop-cat-bg-2.html) center center no-repeat*/
}

.woocommerce ul.products li.product.product-category:nth-child(6n+3) .thumb_wrapper, .woocommerce-page ul.products li.product.product-category:nth-child(6n+3) .thumb_wrapper {
    /*background: url(../images/woo/shop-cat-bg-3.html) center center no-repeat*/
}

.woocommerce ul.products li.product.product-category:nth-child(6n+4) .thumb_wrapper, .woocommerce-page ul.products li.product.product-category:nth-child(6n+4) .thumb_wrapper {
    /*background: url(../images/woo/shop-cat-bg-4.html) center center no-repeat*/
}

.woocommerce ul.products li.product.product-category:nth-child(6n+5) .thumb_wrapper, .woocommerce-page ul.products li.product.product-category:nth-child(6n+5) .thumb_wrapper {
    /*background: url(../images/woo/shop-cat-bg-5.html) center center no-repeat*/
}

.woocommerce ul.products li.product.product-category:nth-child(6n+6) .thumb_wrapper, .woocommerce-page ul.products li.product.product-category:nth-child(6n+6) .thumb_wrapper {
    /*background: url(../images/woo/shop-cat-bg-6.html) center center no-repeat*/
}

.woocommerce ul.products li.product.product-category img, .woocommerce-page ul.products li.product.product-category img {
    width: 100%;
    height: auto
}

.woocommerce ul.products li.product.product-category h3, .woocommerce-page ul.products li.product.product-category h3 {
    margin-bottom: 0;
    border-bottom: none;
    text-align: center
}

.woocommerce ul.products li.product.product-category mark.count, .woocommerce-page ul.products li.product.product-category mark.count {
    font-weight: 400;
    font-style: normal
}

.woocommerce .sidebar aside {
    padding: 3.1em 3.5714em
}

.top_panel_navi .sidebar_cart {
    width: 250px!important;
    padding: 1.5em!important
}

.top_panel_navi .widget_shopping_cart {
    display: block!important
}

.top_panel_navi .widget_shopping_cart ul.cart_list {
    display: block;
    position: static;
    width: 100%;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.top_panel_navi .sidebar_cart>li, .top_panel_navi .widget_shopping_cart ul.cart_list>li {
    margin: 0;
    padding-left: 0
}

.top_panel_navi .sidebar_cart>li:before, .top_panel_navi .sidebar_cart>li>a:before, .top_panel_navi .widget_shopping_cart ul.cart_list>li:before, .top_panel_navi .widget_shopping_cart ul.cart_list>li>a:before {
    display: none
}

.woocommerce .top_panel_navi .widget_shopping_cart .empty, .woocommerce-page .top_panel_navi .widget_shopping_cart .empty {
    padding-bottom: 1em
}

.top_panel_navi .widget_shopping_cart ul.cart_list>li>a {
    padding: 0
}

.top_panel_navi .widget_shopping_cart ul.cart_list>li>a:not(.remove) {
    margin-top: -0.15em
}

.top_panel_navi .widget_shopping_cart ul.cart_list>li>a:hover {
    background-color: transparent;
    color: #ffffff
}

.top_panel_navi .widget_shopping_cart ul.cart_list>li img {
    width: 50px;
    margin-top: 0.5em
}

.woocommerce a.remove {
    margin-top: 1em;
    text-align: left
}

.woocommerce a.remove, .woocommerce a.remove:hover {
    color: #e05100!important;
    background-color: transparent!important
}

.woocommerce table.shop_table {
    border-collapse: collapse;
    margin-bottom: 2em
}

.woocommerce table.shop_table tr:first-child th {
    border-style: solid;
    border-width: 0 0 1px 0!important
}

.woocommerce table.shop_table tr:nth-child(n+1) th:first-child, .woocommerce table.shop_table tr:nth-child(n+1) td:first-child, .woocommerce table.shop_table td {
    border-style: solid;
    border-width: 1px 0
}

.woocommerce .shop_table p {
    margin: 0
}

.woocommerce .shop_table a.remove {
    margin-top: 0;
    font-weight: 400;
    text-align: center
}

.woocommerce .widget_shopping_cart .cart_list li, .woocommerce-page .widget_shopping_cart .cart_list li {
    padding: 0 0 0 1.5em;
    overflow: hidden
}

.woocommerce ul.cart_list li:before, .woocommerce ul.product_list_widget li:before, .woocommerce-page ul.cart_list li:before, .woocommerce-page ul.product_list_widget li:before {
    display: none
}

.woocommerce ul.cart_list li a, .woocommerce ul.product_list_widget li a, .woocommerce-page ul.cart_list li a, .woocommerce-page ul.product_list_widget li a {
    font-weight: 400
}

.woocommerce ul.cart_list li img, .woocommerce ul.product_list_widget li img, .woocommerce-page ul.cart_list li img, .woocommerce-page ul.product_list_widget li img {
    box-shadow: none;
    width: 4.6875em;
    float: left;
    margin: 0 0.75em 1em 0
}

.woocommerce.widget_shopping_cart .quantity, .woocommerce .widget_shopping_cart .quantity, .woocommerce-page.widget_shopping_cart .quantity, .woocommerce-page .widget_shopping_cart .quantity {
    margin: 0.5em 0 1em;
    display: inline-block
}

.woocommerce ul.cart_list li>.amount, .woocommerce ul.product_list_widget li>.amount, .woocommerce-page ul.cart_list li>.amount, .woocommerce-page ul.product_list_widget li>.amount, .woocommerce ul.cart_list li span .amount, .woocommerce ul.product_list_widget li span .amount, .woocommerce-page ul.cart_list li span .amount, .woocommerce-page ul.product_list_widget li span .amount, .woocommerce ul.cart_list li ins .amount, .woocommerce ul.product_list_widget li ins .amount, .woocommerce-page ul.cart_list li ins .amount, .woocommerce-page ul.product_list_widget li ins .amount {
    font-size: 1em;
    font-weight: 400
}

.woocommerce ul.cart_list li ins, .woocommerce ul.product_list_widget li ins, .woocommerce-page ul.cart_list li ins, .woocommerce-page ul.product_list_widget li ins {
    text-decoration: none;
    font-style: normal
}

.woocommerce ul.cart_list li del, .woocommerce ul.product_list_widget li del, .woocommerce-page ul.cart_list li del, .woocommerce-page ul.product_list_widget li del {
    font-size: 0.875em
}

.woocommerce.widget_shopping_cart .total, .woocommerce .widget_shopping_cart .total, .woocommerce-page.widget_shopping_cart .total, .woocommerce-page .widget_shopping_cart .total {
    font-size: 1em;
    padding: 0.75em 0 0
}

.woocommerce.widget_shopping_cart .total .amount, .woocommerce .widget_shopping_cart .total .amount, .woocommerce-page.widget_shopping_cart .total .amount, .woocommerce-page .widget_shopping_cart .total .amount {
    font-size: 1.2857em;
    font-weight: 400
}

.woocommerce .widget_layered_nav_filters>ul>li {
    padding: 0 0.625em 0.5em 0;
    display: block
}

.woocommerce .widget_layered_nav_filters>ul>li:before {
    display: none
}

.woocommerce .widget_layered_nav_filters>ul>li a, .woocommerce .widget_layered_nav ul li.chosen a, .woocommerce-page .widget_layered_nav ul li.chosen a {
    background-color: transparent;
    border: none;
    padding: 0
}

.woocommerce .widget_price_filter .price_slider_wrapper .ui-widget-content {
    margin-bottom: 1.225em;
    height: 5px
}

.woocommerce .widget_price_filter .price_label {
    font-size: 0.875em
}

.woocommerce .widget_price_filter .ui-slider-horizontal .ui-slider-range {
    height: 3px;
    top: 1px
}

.woocommerce.widget_product_search form {
    width: 100%;
    overflow: hidden;
    position: relative
}

.woocommerce.widget_product_search form:after {
    content: '\e8f9';
    font-family: 'fontello';
    display: block;
    text-align: center;
    position: absolute;
    right: 2.5em;
    top: 50%;
    margin-top: -0.4em;
    width: 1em;
    height: 1em;
    line-height: 1em;
    z-index: 0;
    pointer-events: none;
    cursor: pointer
}

.woocommerce.widget_product_search .search_field {
    width: 100%;
    padding: 1.6em 3em 1.6em 2em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.woocommerce.widget_product_search .search_button {
    display: block;
    text-indent: -1000px;
    position: absolute;
    right: 2.5em;
    top: 50%;
    margin-top: -0.6em;
    width: 1.2em;
    height: 1.2em;
    line-height: 1.2em;
    border: none!important;
    background: none!important;
    z-index: 1;
    cursor: pointer
}

aside.woocommerce .product-title, aside.woocommerce .mini_cart_item>a:not(.remove) {
    display: block;
    line-height: 1.5em
}

.woocommerce .quantity input.qty, .woocommerce #content .quantity input.qty, .woocommerce-page .quantity input.qty, .woocommerce-page #content .quantity input.qty {
    font-weight: 400;
    padding-left: 0.75em;
    padding-right: 0;
    width: 4em;
    font-size: 0.8421em;
    line-height: 1em
}

.woocommerce table.cart .product-remove, .woocommerce #content table.cart .product-remove, .woocommerce-page table.cart .product-remove, .woocommerce-page #content table.cart .product-remove, .woocommerce table.cart .product-thumbnail, .woocommerce #content table.cart .product-thumbnail, .woocommerce-page table.cart .product-thumbnail, .woocommerce-page #content table.cart .product-thumbnail {
    text-align: center;
    width: 40px
}

.woocommerce table.cart .product-name, .woocommerce #content table.cart .product-name, .woocommerce-page table.cart .product-name, .woocommerce-page #content table.cart .product-name {
    text-align: left!important
}

.woocommerce table.cart td.product-name, .woocommerce #content table.cart td.product-name, .woocommerce-page table.cart td.product-name, .woocommerce-page #content table.cart td.product-name {
    font-weight: bold;
    font-style: italic
}

.woocommerce table.cart th, .woocommerce #content table.cart th, .woocommerce-page table.cart th, .woocommerce-page #content table.cart th, .woocommerce table.cart td, .woocommerce #content table.cart td, .woocommerce-page table.cart td, .woocommerce-page #content table.cart td {
    padding: 12px;
    text-align: center
}

.woocommerce table.cart td.actions, .woocommerce #content table.cart td.actions, .woocommerce-page table.cart td.actions, .woocommerce-page #content table.cart td.actions {
    border-bottom: none!important;
    padding-top: 1em
}

.woocommerce table.cart td.actions .coupon .input-text, .woocommerce #content table.cart td.actions .coupon .input-text, .woocommerce-page table.cart td.actions .coupon .input-text, .woocommerce-page #content table.cart td.actions .coupon .input-text {
    padding: 0.65em 0.5em;
    margin-right: 0.5em;
    width: 7em
}

@-moz-document url-prefix() {
    .woocommerce table.cart td.actions .coupon .input-text,
    .woocommerce #content table.cart td.actions .coupon .input-text,
    .woocommerce-page table.cart td.actions .coupon .input-text,
    .woocommerce-page #content table.cart td.actions .coupon .input-text {
        padding: 0.75em 0.5em;
    }
}

.woocommerce form .form-row select,
.woocommerce-page form .form-row select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.woocommerce table.shop_table tfoot th, .woocommerce-page table.shop_table tfoot th {
    text-align: center
}

.woocommerce-cart table.cart input.button {
    line-height: 1em;
    padding: 0.6667em 1em
}

.woocommerce .cart .button[name="update_cart"],
.woocommerce .cart input.button[name="update_cart"]{
    float: right;
}

.woocommerce .cart-collaterals h2, .woocommerce-page .cart-collaterals h2 {
    font-size: 1.5em;
    font-weight: 400;
    line-height: 1.3em;
    margin: 1em 0 0.6em
}

.woocommerce .cart-collaterals .cart-subtotal .amount, .woocommerce-page .cart-collaterals .cart-subtotal .amount {
    font-size: 1em;
    font-weight: 700
}

.woocommerce .cart-collaterals .cart_totals table {
    border-collapse: collapse
}

.woocommerce .cart-collaterals .cart_totals table td, .woocommerce-page .cart-collaterals .cart_totals table td {
    padding: 6px;
    vertical-align: middle
}

.woocommerce .cart-collaterals .cart_totals table th, .woocommerce-page .cart-collaterals .cart_totals table th {
    padding: 6px;
    font-weight: 700;
    text-align: left;
    vertical-align: middle
}

.woocommerce .cart-collaterals .cart_totals table select, .woocommerce-page .cart-collaterals .cart_totals table select {
    font-size: 1em
}

.woocommerce table.cart td.actions .input-text, .woocommerce-page table.cart td.actions .input-text {
    width: 100px
}

.woocommerce .cart-collaterals .shipping_calculator .shipping-calculator-button:after, .woocommerce-page .cart-collaterals .shipping_calculator .shipping-calculator-button:after {
    font-size: 1em;
    font-weight: 400
}

.woocommerce-cart .wc-proceed-to-checkout a.checkout-button {
    font-size: 12px
}

.woocommerce form .form-row {
    padding: 0;
    margin-bottom: 0.8em
}

.woocommerce form .form-row label, .woocommerce-page form .form-row label {
    line-height: 1.5em
}

.woocommerce form .form-row label.inline {
    margin-left: 1em
}

.woocommerce form .form-row input.input-text, .woocommerce form .form-row textarea {
    line-height: 1.3em
}

.woocommerce-checkout h3 {
    margin-bottom: 0.6em;
    margin-top: 0
}

.woocommerce .checkout .col-2 h3#ship-to-different-address label, .woocommerce-page .checkout .col-2 h3#ship-to-different-address label {
    display: inline-block
}

.woocommerce form .form-row input.input-text, .woocommerce form .form-row textarea, .woocommerce-page form .form-row input.input-text, .woocommerce-page form .form-row textarea {
    font-weight: 400
}

.woocommerce .checkout table.shop_table {
    margin-bottom: 3em
}

.woocommerce .checkout table.shop_table th, .woocommerce .checkout table.shop_table td {
    text-align: left
}

.woocommerce .checkout table.shop_table .product-name {
    font-weight: bold;
    font-style: italic
}

.woocommerce .checkout table.shop_table th.product-name, .woocommerce .checkout table.shop_table .product-name strong {
    font-style: normal
}

.woocommerce .checkout table.shop_table tfoot th {
    text-align: right
}

.woocommerce .checkout table.shop_table tfoot td, .woocommerce .checkout table.shop_table tfoot th {
    font-weight: 700
}

.woocommerce table.shop_table .cart-subtotal .amount, .woocommerce-page table.shop_table .cart-subtotal .amount, .woocommerce table.shop_table .shipping td, .woocommerce-page table.shop_table .shipping td {
    font-weight: 400
}

.woocommerce .cart-collaterals .shipping_calculator, .woocommerce-page .cart-collaterals .shipping_calculator {
    clear: none;
    float: left;
    margin-bottom: 30px
}

.woocommerce .checkout #order_review, .woocommerce-page .checkout #order_review {
    margin-bottom: 3em
}

.woocommerce .checkout #order_review_heading, .woocommerce-page .checkout #order_review_heading {
    padding-top: 1.825em
}

.woocommerce .checkout #order_review .order-total .amount, .woocommerce-page .checkout #order_review .order-total .amount {
    font-size: 1.125em;
    line-height: 1.3em;
    font-weight: 400
}

.woocommerce .checkout #order_review .payment_methods li, .woocommerce-page .checkout #order_review .payment_methods li {
    list-style: none
}

.woocommerce .checkout #order_review .payment_methods li label, .woocommerce-page .checkout #order_review .payment_methods li label {
    display: inline-block
}

#add_payment_method #payment ul.payment_methods li img, .woocommerce-checkout #payment ul.payment_methods li img {
    margin: -14px 0.5em 0
}

.woocommerce-checkout #payment div.payment_box:after {
    top: 0
}

#add_payment_method #payment .payment_method_paypal .about_paypal, .woocommerce-cart #payment .payment_method_paypal .about_paypal, .woocommerce-checkout #payment .payment_method_paypal .about_paypal {
    line-height: 1.7em
}

.woocommerce .shop_table.order_details th, .woocommerce .shop_table.customer_details th {
    text-align: left
}

.woocommerce .shop_table.order_details td.product-name {
    font-weight: 600;
    font-style: italic
}

.woocommerce .shop_table.order_details td.product-name strong {
    font-style: normal
}

.woocommerce .order_details li, .woocommerce-page .order_details li {
    font-size: 1em;
    line-height: 1em;
    list-style: none
}

.woocommerce form .form-row #createaccount {
    display: none
}

.woocommerce form .form-row input[type="radio"]+label:before, .woocommerce form .form-row input[type="checkbox"]+label:before {
    top: 10px
}

.woocommerce form .form-row label.inline input[type="checkbox"] {
    display: inline;
    position: relative;
    top: 2px
}

.woocommerce-account h2, .woocommerce-account .address .title h3 {
    margin: 1em 0 0.4em
}

.woocommerce-MyAccount-navigation, .woocommerce-MyAccount-content .woocommerce-Address-title a {
    font-size: 11px;
    font-weight: bold;
    line-height: 1.5em;
    text-transform: uppercase;
    letter-spacing: 1px
}

.woocommerce-MyAccount-navigation ul {
    width: 80%;
    padding: 0;
    margin: 0;
    list-style-type: none
}

.woocommerce-MyAccount-navigation ul li {
    padding: 0;
    margin: 0
}

.woocommerce-MyAccount-navigation ul li+li {
    border-top: 1px dotted #ddd
}

.woocommerce-MyAccount-navigation ul li a {
    display: block;
    padding: 1em 0;
    -webkit-transition: color ease .3s;
    -moz-transition: color ease .3s;
    -ms-transition: color ease .3s;
    -o-transition: color ease .3s;
    transition: color ease .3s
}

.woocommerce-MyAccount-content .my_account_orders th {
    text-align: left
}

.woocommerce-MyAccount-content .woocommerce-Address-title {
    position: relative
}

.woocommerce-MyAccount-content .woocommerce-Address-title h3 {
    display: inline-block;
    margin-top: 0.5em
}

.woocommerce-MyAccount-content .woocommerce-Address-title a {
    display: inline-block;
    vertical-align: text-top;
    margin: 0 0 0 2em;
    text-decoration: underline;
    float: none!important
}

.woocommerce form.checkout_coupon, .woocommerce form.login, .woocommerce form.register {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0
}

.woocommerce table.shop_table_responsive thead, .woocommerce-page table.shop_table_responsive thead {
    display: table-row-group
}

.woocommerce table.shop_table_responsive tr, .woocommerce-page table.shop_table_responsive tr {
    display: table-row
}

.woocommerce table.shop_table_responsive tr td, .woocommerce-page table.shop_table_responsive tr td {
    display: table-cell
}

.woocommerce table.shop_table_responsive tr td.product-price, .woocommerce-page table.shop_table_responsive tr td.product-price, .woocommerce table.shop_table_responsive tr td.product-quantity, .woocommerce-page table.shop_table_responsive tr td.product-quantity, .woocommerce table.shop_table_responsive tr td.product-subtotal, .woocommerce-page table.shop_table_responsive tr td.product-subtotal {
    text-align: center!important
}

.woocommerce table.shop_table_responsive tr td:before, .woocommerce-page table.shop_table_responsive tr td:before {
    display: none
}

.shop_slider_slide h1, .shop_slider_slide h2, .shop_slider_slide h3, .shop_slider_slide h4, .shop_slider_slide h5, .shop_slider_slide h6 {
    margin: 0!important;
    letter-spacing: 0!important
}

.shop_slider_tags a, .shop_slider_title a, .shop_slider_add_to_cart a {
    -webkit-transition: all ease .3s!important;
    -moz-transition: all ease .3s!important;
    -ms-transition: all ease .3s!important;
    -o-transition: all ease .3s!important;
    transition: all ease .3s!important
}

.shop_slider_add_to_cart:before, .tp-caption.shop_slider_add_to_cart:before {
    content: ' ';
    display: inline-block;
    height: 0.85em;
    margin: 0 1em 0 0;
    border-left: 3px solid #000
}

.shop_slider_label span, .tp-caption.shop_slider_label span {
    padding-top: 1.1em!important;
    display: block!important
}

.shop_slider_label span+span, .tp-caption.shop_slider_label span+span {
    padding-top: 0!important;
    font-weight: 400!important
}

.shop_slider_content strong, .tp-caption.shop_slider_content strong {
    font-weight: 700!important
}

.shop_slider_content strong:before, .tp-caption.shop_slider_content strong:before {
    content: ' ';
    display: block;
    height: 0;
    width: 100%;
    margin-top: 2em
}

.tp-bullets.custom .tp-bullet {
    border: 2px solid #ddd;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    background: none;
    width: 0.8421em;
    height: 0.8421em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box
}

.events-archive .entry-content, .events-archive .entry-header {
    width: 100%
}

#tribe-events-bar {
    margin: 6px 0 1.5em
}

#tribe-bar-form.tribe-bar-mini *, #tribe-bar-form.tribe-bar-mini label {
    font-size: 1em
}

#tribe-bar-form {
    background: none
}

#tribe-bar-form .tribe-bar-submit input[type="submit"], #tribe-bar-form input, #tribe-bar-form button, #tribe-bar-form input, #tribe-bar-form a {
    font-size: 12px;
    font-weight: 700;
    font-style: normal;
    line-height: 1.5em;
    text-transform: uppercase;
    letter-spacing: 1px
}

#tribe-bar-form #tribe-bar-views, #tribe-bar-form.tribe-bar-mini #tribe-bar-views {
    left: 85.5%;
    width: 14.5%;
    padding: 0
}

.tribe-bar-views-inner {
    padding: 0;
    background: none
}

.tribe-bar-mini .tribe-bar-views-inner {
    padding: 0
}

#tribe-bar-form.tribe-bar-collapse .tribe-bar-views-inner {
    padding: 0
}

#tribe-bar-views .tribe-bar-views-list {
    padding: 0;
    border: 2px solid #ddd
}

#tribe-bar-form.tribe-bar-mini #tribe-bar-views .tribe-bar-views-list {
    padding: 0.2em 0
}

#tribe-bar-form.tribe-bar-collapse #tribe-bar-views .tribe-bar-views-list {
    padding: 1em 0
}

#tribe-bar-views .tribe-bar-views-list .tribe-bar-views-option a, .tribe-bar-mini #tribe-bar-views .tribe-bar-views-list .tribe-bar-views-option a {
    padding: 1.2em 1.7em 1.2em 3.6em;
    background: none
}

#tribe-bar-form #tribe-bar-views+.tribe-bar-filters {
    width: 85.5%;
    left: 0%;
    right: auto
}

.tribe-bar-date-filter, .tribe-bar-search-filter, .tribe-bar-submit, .tribe-bar-mini .tribe-bar-date-filter, .tribe-bar-mini .tribe-bar-search-filter, .tribe-bar-mini .tribe-bar-submit {
    padding: 0
}

.tribe-bar-collapse .tribe-bar-date-filter, .tribe-bar-collapse .tribe-bar-search-filter, .tribe-bar-collapse .tribe-bar-submit {
    padding: 0
}

.datepicker {
    z-index: 10005!important
}

.datepicker table {
    border-collapse: collapse!important
}

.datepicker table, .datepicker table td, .datepicker table th {
    border-width: 0!important
}

.tribe-bar-date-filter, .tribe-bar-mini .tribe-bar-date-filter {
    width: 37.9%;
    padding-right: 3.1em
}

.tribe-bar-mini .tribe-bar-date-filter {
    padding-left: 0
}

.tribe-bar-search-filter, .tribe-bar-mini .tribe-bar-search-filter {
    width: 40.9%;
    padding-right: 3.1em
}

.tribe-bar-submit, .tribe-bar-mini .tribe-bar-submit {
    width: 21.2%;
    padding-right: 1em;
    margin: 0
}

#tribe-bar-form label {
    display: none;
    text-transform: none;
    font-size: 1em;
    margin-bottom: 1.1em
}

#tribe-bar-form input[type="text"] {
    border: none;
    border-bottom: 2px solid #ddd;
    padding: 1.95em 0.8em 1.2em
}

#tribe-bar-form .tribe-bar-submit input[type="submit"], #tribe-bar-form.tribe-bar-mini .tribe-bar-submit input[type="submit"] {
    padding: 1.1667em 1.5em;
    border: 2px solid #ddd;
    margin: 0
}

.tribe-events-page-title {
    display: none
}

#tribe-events-content.tribe-events-month {
    font-size: 14px;
    line-height: 1.4em
}

#tribe-events-content {
    padding: 0;
    margin: 3.3em 0 0
}

#tribe-events-footer, #tribe-events-header {
    margin: 0
}

#tribe-events-content table.tribe-events-calendar {
    margin: 0;
    font-size: 1em
}

.events-list #tribe-events-footer, .tribe-events-day #tribe-events-footer, .tribe-events-map #tribe-events-footer, .tribe-events-calendar+#tribe-events-footer {
    margin: 3.4em 0 1.8em
}

.events-list #tribe-events-footer, .single-tribe_events #tribe-events-footer, .tribe-events-day #tribe-events-footer, .tribe-events-map #tribe-events-footer, .tribe-events-photo #tribe-events-footer {
    padding-top: 3em;
    border-top-style: dotted
}

.tribe-events-calendar thead th {
    padding: 1.8em 0!important;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: relative;
    border: 2px solid #ddd!important
}

.tribe-events-calendar thead th+th:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -0.6em;
    width: 2px;
    height: 1.2em
}

#tribe-events-content .tribe-events-calendar td {
    font-size: 1em;
    padding: 0;
    height: 10em;
    text-align: left;
    border: 2px solid #ddd!important
}

#tribe-events-content .tribe-events-calendar td:hover {
    box-shadow: none;
    background: none
}

.tribe-events-calendar td div[id*="tribe-events-daynum-"], .tribe-events-calendar td div[id*="tribe-events-daynum-"] a {
    font-size: 1em
}

.tribe-events-calendar td div[id*="tribe-events-daynum-"] {
    padding: 0.9em 1.7em 0.2em;
    margin-bottom: 0.4em;
    text-align: left
}

.tribe-events-calendar td div[id*="tribe-events-daynum-"], .tribe-events-calendar td div[id*="tribe-events-daynum-"]>a, .tribe-events-calendar td.tribe-events-present div[id*="tribe-events-daynum-"], .tribe-events-calendar td.tribe-events-present div[id*="tribe-events-daynum-"]>a, .tribe-events-calendar td.tribe-events-past div[id*="tribe-events-daynum-"], .tribe-events-calendar td.tribe-events-past div[id*="tribe-events-daynum-"]>a {
    background: none
}

.tribe-events-calendar td.tribe-events-present {
    position: relative
}

.tribe-events-calendar td.tribe-events-present:before {
    content: ' ';
    position: absolute;
    left: -3px;
    top: -3px;
    width: 100%;
    height: 100%;
    border: 2px solid #ddd
}

.events-archive.events-gridview #tribe-events-content table .type-tribe_events {
    margin: 0;
    padding: 0.1em 1.5em
}

#tribe-events-content .tribe-events-calendar div[id*="tribe-events-event-"] h3.tribe-events-month-event-title {
    font-size: 15px;
    font-weight: 600
}

.tribe-bar-collapse #tribe-bar-collapse-toggle {
    padding: 0.8em 0.7em 0.6em;
    width: 66%;
    font-size: 14px
}

#tribe-bar-form.tribe-bar-collapse #tribe-bar-views .tribe-bar-views-list {
    padding: 0
}

#tribe-bar-form.tribe-bar-collapse #tribe-bar-views {
    float: right;
    width: 32%
}

.tribe-bar-collapse .tribe-bar-filters-inner>.tribe-bar-date-filter, .tribe-bar-collapse .tribe-bar-filters-inner>.tribe-bar-search-filter, .tribe-bar-collapse .tribe-bar-filters-inner>.tribe-bar-submit {
    float: left;
    width: 32%
}

.tribe-bar-collapse .tribe-bar-filters-inner>.tribe-bar-date-filter, .tribe-bar-collapse .tribe-bar-filters-inner>.tribe-bar-search-filter {
    margin-right: 2%
}

.tribe-events-calendar .tribe-events-has-events.mobile-trigger:after {
    margin: 1em auto 0
}

.tribe-events-calendar td.mobile-trigger div[id*="tribe-events-daynum-"], .tribe-events-calendar td.mobile-trigger div[id*="tribe-events-daynum-"] a {
    margin-top: 0
}

.tribe-mobile-day-heading {
    font-size: 1.4286em;
    line-height: 1.25em
}

#tribe-mobile-container .type-tribe_events {
    padding-top: 10px
}

#tribe-mobile-container .type-tribe_events h4 {
    font-size: 1.0714em;
    margin-bottom: 1em
}

#tribe-mobile-container .type-tribe_events .tribe-event-date-start {
    display: inline-block;
    margin-bottom: 0.8em
}

#tribe-mobile-container .type-tribe_events .tribe-events-read-more {
    margin-top: 0
}

.recurring-info-tooltip, .tribe-events-calendar .tribe-events-tooltip, .tribe-events-week .tribe-events-tooltip {
    box-shadow: none;
    padding: 0;
    bottom: 5.5em!important;
    left: -2em;
    width: 32.5em;
    z-index: 10000;
    text-transform: none
}

.tribe-events-tooltip .tribe-events-arrow {
    background: none;
    width: 14px;
    height: 14px;
    bottom: -7px;
    left: 5.2em;
    display: block;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.tribe-events-tooltip .tribe-events-event-body {
    font-size: 1em;
    line-height: 2em;
    padding: 1em 1.8em 1.5em
}

#tribe-events-content .tribe-events-tooltip h4 {
    font-size: 1.4em;
    font-weight: 700;
    line-height: 1.25em;
    padding: 1.2em 1.5em 2.2em
}

.tribe-events-tooltip .tribe-event-duration {
    margin: -4.4em 0 3em
}

.tribe-events-tooltip .tribe-events-event-thumb {
    padding: 0.5em 1.5em 0 0;
}

.tribe-events-tooltip .tribe-event-description p {
    margin: 0;
    line-height: 1.7em
}

.tribe-events-tooltip .tribe-event-description p+p {
    margin-top: 1em
}

.tribe-events-ical {
    display: none!important
}

#tribe-events-footer .tribe-events-sub-nav li {
    width: 45%!important;
    margin: 0!important;
    padding: 0!important
}

#tribe-events-footer .tribe-events-sub-nav .tribe-events-nav-next {
    text-align: right
}

#tribe-events .tribe-events-button, .tribe-events-button, .tribe-events-cal-links a, .tribe-events-sub-nav li a {
    display: inline-block;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 1.4em 2.4em;
    margin-bottom: 0;
    font-size: 11px;
    font-weight: bold;
    line-height: 1em;
    letter-spacing: 1px;
    background: none;
    border: 2px solid #ddd;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.tribe-events-read-more {
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5em;
    letter-spacing: 1px;
    display: block;
    margin-top: 1em
}

.tribe-events-sub-nav li a span {
    display: none!important
}

#tribe-events .tribe-events-button:after, .tribe-events-button:after, .tribe-events-cal-links a:after, .tribe-events-sub-nav li.tribe-events-nav-next a:after {
    content: '\e908';
    font-family: 'fontello';
    margin-left: 0.5em
}

.tribe-events-sub-nav li.tribe-events-nav-previous a:before {
    content: '\e907';
    font-family: 'fontello';
    margin-right: 0.5em
}

.events-list .tribe-events-loop, .single-tribe_organizer .tribe-events-loop, .single-tribe_venue .tribe-events-loop, .tribe-events-day .tribe-events-loop {
    max-width: 770px
}

.tribe-events-list .tribe-events-list-separator-month {
    margin: 2.5em auto;
    font-size: 1em;
    font-weight: 700;
    line-height: 1.6em;
    text-transform: uppercase;
    letter-spacing: 4px
}

.tribe-events-list .tribe-events-list-separator-month:first-child {
    margin-top: 0
}

.tribe-events-list .tribe-events-event-cost {
    margin: 1px 0 0.2em 1em
}

.tribe-events-list .tribe-events-event-cost span {
    padding: 0.6em 1em
}

.tribe-events-list .tribe-events-list-event-title {
    font-weight: 700;
    font-style: italic
}

.tribe-events-event-meta address.tribe-events-address, .tribe-events-list .tribe-events-venue-details {
    font-style: normal;
    line-height: 1.5em
}

.tribe-events-event-meta .tribe-event-schedule-details {
    margin-bottom: 0.8em
}

.tribe-events-list .type-tribe_events, .tribe-events-list-separator-month+.type-tribe_events.tribe-events-first {
    padding: 0;
    margin: 0
}

.tribe-events-list .type-tribe_events+.type-tribe_events {
    border-top: 1px dotted #eee;
    margin-top: 3em;
    padding-top: 3em
}

.tribe-events-list .tribe-events-event-image {
    margin-top: 0.4em;
    width: 30%
}

.tribe-events-list .tribe-events-loop .tribe-events-content {
    text-align: justify
}

#tribe-events .tribe-events-content p, .tribe-events-after-html p, .tribe-events-before-html p {
    line-height: 1.4em
}

.tribe-events-loop .tribe-events-content {
    padding-right: 0
}

.tribe-events-day .tribe-events-day-time-slot h5 {
    margin: 0 0 1em;
    padding: 1em 2em
}

.tribe-events-day .tribe-events-day-time-slot+.tribe-events-day-time-slot+.tribe-events-day-time-slot {
    margin-top: 3em
}

.single-tribe_events .top_panel_title .page_title, .single-tribe_events .top_panel_title .breadcrumbs {
    display: none
}

#tribe-events-content.tribe-events-single {
    margin-top: 0
}

.single-tribe_events .top_panel {
    margin-bottom: 0
}

.tribe-events-back {
    display: none
}

.tribe-events-notices {
    margin: 0 0 1em 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.tribe-events-single-event-title {
    margin: 0;
    text-align: center
}

.tribe-events-notices+.tribe-events-single-event-title {
    margin-top: 1.5em
}

.tribe-events-schedule {
    position: relative
}

.tribe-events-schedule h2 {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0;
    display: block;
    text-align: center
}

.single-tribe_events .tribe-events-schedule .tribe-events-divider, .single-tribe_events .tribe-events-schedule .tribe-events-cost {
    font-size: 1em
}

.single-tribe_events .tribe-events-schedule .tribe-events-divider {
    display: none
}

.single-tribe_events .tribe-events-schedule .tribe-events-cost {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    font-weight: bold
}

.single-tribe_events .tribe-events-event-image {
    float: left;
    width: 40%;
    margin: 0.4em 2.1429em 2em 0
}

.single-tribe_events .tribe-events-event-meta {
    font-size: 0.8947em;
    line-height: 1.4em
}

.single-tribe_events .tribe-events-single-section {
    clear: both;
    margin-top: 3em;
    padding: 3em 0 0;
    border: none;
    border-top: 1px dotted #ddd;
    background-color: transparent
}

.single-tribe_events .tribe-events-event-meta .tribe-events-meta-group {
    padding: 0;
    margin: 0
}

.single-tribe_events .tribe-events-event-meta .tribe-events-meta-group+.tribe-events-meta-group {
    margin-left: 3em
}

.single-tribe_events .tribe-events-meta-group .tribe-events-single-section-title {
    margin-top: 0;
    font-size: 1.2em
}

.single-tribe_events #tribe-events-content .tribe-events-event-meta dt, .single-tribe_events #tribe-events-content .tribe-events-event-meta dd {
    line-height: 1.4em
}

.single-tribe_events .tribe-events-venue-map {
    margin: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.single-tribe_events #tribe-events-footer {
    border-top: none
}

.single-tribe_events .tribe-events-sub-nav li a {
    position: relative;
    white-space: normal;
    line-height: 1.5em;
    max-height: 1.45em;
    overflow: hidden;
    padding: 1.9em 3.4em 1.9em 4em;
    max-width: 95%
}

.single-tribe_events .tribe-events-sub-nav li a:before {
    position: absolute;
    left: 2em;
    top: 50%;
    margin-top: -0.8em
}

.single-tribe_events .tribe-events-sub-nav li+li a {
    padding: 1.9em 4em 1.9em 3.4em
}

.single-tribe_events .tribe-events-sub-nav li+li a:after {
    position: absolute;
    right: 2em;
    top: 50%;
    margin-top: -0.8em
}

.single-tribe_events .tribe-events-sub-nav .tribe-events-nav-next, .single-tribe_events .tribe-events-sub-nav .tribe-events-nav-next a {
    text-align: right
}

#tribe-events-footer:before, #tribe-events-footer:after {
    display: none
}

.tribe-events-list-widget ol li {
    margin-bottom: 1.5em
}

.tribe-events-list-widget ol li .tribe-event-title {
    font-size: 1em;
    margin: 0
}
