/* TABLE OF CONTENTS:

1. Reset tags
2. Default tag's settings
3. Form fields settings
4. WP styles and Screen readers
5. Theme grid
6. Page layouts
7. Section's decorations
   7.1 Header: Logo and Menu
   7.2 Sliders
   7.3 Page info (page/post title, category or tag name, author, etc.)
   7.4 Blog layouts
   7.5 Post Formats
   7.6 Paginations
8. Single page parts
   8.1 Post header
   8.2 Post footer
   8.3 Post author
   8.4 Related posts
   8.5 Comments
   8.6 Page 404
   8.7 Page 'No search results' and 'No archive results'
9. Sidebars
10. Footer areas
11. Utils
12. Registration and Login popups
13. Third part plugins
14. Predefined classes for users
15. Styles for custom blocks
16. Styles for differents Post types.

-------------------------------------------------------------- */

/* 1. Reset tags
-------------------------------------------------------------- */
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,font,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,input,textarea,button,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,address,section,video,audio {
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  border: 0;
  outline: 0;
  -webkit-font-smoothing: antialiased;
  -ms-word-wrap: break-word;
  word-wrap: break-word
}

html,body {
  margin: 0;
  padding: 0;
  width: 100%;
  line-height: 136.8421%
}

html {
  height: 100%;
  font-size: 100%;
  overflow-y: scroll !important;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0)
}

/* 2. Default tag's settings
-------------------------------------------------------------- */
html,body {
  font-size: 19px
}

body {
  text-rendering: optimizeLegibility;
  font-weight: 400
}

article,aside,details,figcaption,figure,footer,header,hgroup,nav,section {
  display: block
}

h1 {
  font-size: 2.10526em;
  font-weight: 700;
  font-style: normal;
  line-height: 1.5em;
  margin-top: 1.65em;
  margin-bottom: .75em;
  letter-spacing: 3px;
  text-transform: uppercase
}

h2 {
  font-size: 1.89474em;
  font-weight: 700;
  font-style: normal;
  line-height: 1.3889em;
  margin-top: 1.75em;
  margin-bottom: .95em;
  letter-spacing: 2px;
  text-transform: uppercase
}

h3 {
  font-size: 2.10526em;
  font-weight: 600;
  font-style: italic;
  line-height: 1.125em;
  margin-top: 1.75em;
  margin-bottom: .50em;
  letter-spacing: 0
}

h4 {
  font-size: 1.57895em;
  font-weight: 600;
  font-style: italic;
  line-height: 1.2em;
  margin-top: 1.75em;
  margin-bottom: .50em;
  letter-spacing: 0
}

h5 {
  font-size: .8421em;
  font-weight: 700;
  font-style: normal;
  line-height: 1.5em;
  margin-top: 2.90em;
  margin-bottom: 1.35em;
  letter-spacing: 1px;
  text-transform: uppercase
}

h6 {
  font-size: 1.05263em;
  font-weight: 700;
  font-style: italic;
  line-height: 1.2em;
  margin-top: 2.00em;
  margin-bottom: .40em;
  letter-spacing: 0
}

p,ul,ol,dl,blockquote,address {
  margin: 0 0 1.5789em
}

li>ol,li>ul,dl>dd {
  margin-bottom: 0
}

li>p {
  margin-bottom: 0
}

li>p+p {
  margin-top: .5em
}

ol,ul {
  padding-left: 1.5em
}

a {
  text-decoration: none;
  background: transparent
}

a,a:hover,a:focus,a:active {
  outline: 0
}

a img {
  border: 0
}

a,a:before,button,input[type="button"],input[type="submit"] {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

:active,:focus {
  outline: 0
}

table {
  border-collapse: separate;
  border-spacing: 0 4px;
  border-color: transparent !important
}

table>p {
  margin: 0 !important
}

table td {
  padding: .7em 1em;
  border-style: solid;
  border-width: 1px
}

table th {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  font-style: normal;
  padding: 1em 1.5em;
  text-align: center;
  border-style: solid;
  border-width: 1px
}

table th+th,table th+td,table td+th,table td+td {
  border-left-width: 0
}

caption {
  font-weight: 700;
  text-align: center
}

body[class*="woocommerce-"] table th,body[class*="woocommerce-"] table td {
  border-collapse: collapse;
  border-width: 0
}

table>thead+body>tr:first-child {
  background-color: transparent
}

blockquote {
  text-align: left;
  padding: 1em 7em;
  position: relative
}

blockquote:after,q:before,q:after {
  content: ""
}

blockquote,q {
  quotes: "" ""
}

blockquote p {
  margin: 0;
  font-size: 1.5789em;
  line-height: 1.2em;
  font-weight: 600;
  font-style: italic
}

blockquote p+p {
  margin-top: .4em
}

blockquote a,blockquote cite {
  display: block;
  font-size: .9em;
  font-weight: 400
}

blockquote:before {
  content: '\e8b6';
  font-family: 'fontello';
  font-size: 1.6em;
  display: block;
  position: absolute;
  top: .7em;
  left: 2.2em
}

.post_layout_excerpt blockquote {
  padding: 1em 10em 0 3.5em;
  margin-bottom: 1.3em
}

.post_layout_excerpt blockquote:before {
  left: 0
}

.post_layout_excerpt .post_content blockquote p+p {
  margin-top: .3em
}

.blog_mode_post blockquote,.blog_mode_page blockquote {
  margin: 1.5em 0
}

.blog_mode_post .comments_list blockquote,.blog_mode_page .comments_list blockquote {
  margin: 1.5em 0
}

dd {
  margin-left: 1.5em
}

dt,b,strong {
  font-weight: bold
}

dfn,em,i {
  font-style: italic
}

pre,code,kbd,tt,var,samp {
  font-family: "Courier New",Courier,monospace;
  font-size: 1em
}

pre {
  overflow: auto;
  max-width: 100%
}

abbr,acronym {
  border-bottom: 1px dotted;
  cursor: help
}

mark,ins {
  background-color: transparent;
  text-decoration: none;
  font-style: italic;
  font-weight: bold
}

sup,sub {
  font-size: 75%;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sup {
  bottom: 1ex
}

sub {
  top: .5ex
}

small {
  font-size: 80%
}

big {
  font-size: 120%
}

[hidden],template {
  display: none
}

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  border: 0;
  border-top: 1px solid #eee;
  margin: 4.3em 0 4em
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top
}

figure,.wp-caption,.wp-caption-overlay .wp-caption {
  border: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  max-width: 100%
}

figure figcaption,.wp-caption .wp-caption-text,.wp-caption .wp-caption-dd,.wp-caption-overlay .wp-caption .wp-caption-text,.wp-caption-overlay .wp-caption .wp-caption-dd {
  -webkit-transition: all ease-in-out .3s;
  -moz-transition: all ease-in-out .3s;
  -ms-transition: all ease-in-out .3s;
  -o-transition: all ease-in-out .3s;
  transition: all ease-in-out .3s;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin-bottom: 0;
  background-color: rgba(0,0,0,0.5);
  font-size: .8421em;
  font-weight: 700;
  font-style: italic;
  text-align: center;
  padding: .5em 1em
}

figure:hover figcaption,.wp-caption:hover .wp-caption-text,.wp-caption:hover .wp-caption-dd,.wp-caption-overlay .wp-caption:hover .wp-caption-text,.wp-caption-overlay .wp-caption:hover .wp-caption-dd {
  margin-bottom: -5em
}

svg:not(:root) {
  overflow: hidden
}

audio,canvas,progress,video {
  display: inline-block;
  vertical-align: baseline
}

audio:not([controls]) {
  display: none;
  height: 0
}

iframe,video,embed {
  max-width: 100%;
  min-height: 100px;
  vertical-align: top
}

.me-cannotplay {
  overflow: hidden
}

.me-plugin {
  position: absolute;
  height: 0;
  width: 0
}

[class^="icon-"]:before,[class*=" icon-"]:before {
  line-height: inherit;
  font-weight: inherit;
  font-size: inherit;
  width: auto;
  margin: 0
}
/* 3. Form fields settings
-------------------------------------------------------------- */
form {
  margin-bottom: 0
}

fieldset {
  padding: 1em 1.5em;
  margin: 1.5em .2em;
  border: 1px solid #ddd;
  position: relative
}

fieldset legend {
  position: absolute;
  top: -0.6em;
  left: 1.5em;
  background-color: #fff;
  font-weight: 600;
  font-style: italic;
  padding: 0 .2em
}

button,input,optgroup,select,textarea {
  font-family: inherit;
  font-size: 1em;
  margin: 0;
  vertical-align: baseline
}

button {
  overflow: visible
}

button,input[type="button"],input[type="reset"],input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px
}

button[disabled],html input[disabled] {
  cursor: default
}

button::-moz-focus-inner,input::-moz-focus-inner {
  border: 0;
  padding: 0
}

textarea,select,input[type="text"],input[type="number"],input[type="email"],input[type="tel"],input[type="search"],input[type="password"],input[type="checkbox"],input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

input[type="checkbox"],input[type="radio"] {
  padding: 0
}

input[type="number"]::-webkit-inner-spin-button,input[type="number"]::-webkit-outer-spin-button {
  height: auto
}

input[type="search"] {
  -webkit-appearance: none;
  -webkit-appearance: textfield
}

input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none
}

button::-moz-focus-inner,input::-moz-focus-inner {
  border: 0;
  padding: 0
}

input[type="text"],input[type="number"],input[type="email"],input[type="tel"],input[type="password"],input[type="search"],select,textarea {
  -webkit-appearance: none;
  border: 1px solid #eee;
  outline: 0;
  resize: none;
  padding: .9em 1.5em;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.3em
}

button::-moz-focus-inner {
  border: 0
}

button:focus,input[type="text"]:focus,input[type="email"]:focus,input[type="password"]:focus,input[type="search"]:focus,select,textarea:focus {
  outline: 0
}

textarea {
  overflow: auto;
  vertical-align: top;
  min-height: 10em
}

label.required:after {
  content: '*';
  display: inline-block;
  vertical-align: text-top;
  font-size: 80%;
  color: #da6f5b
}

input[type="radio"],input[type="checkbox"] {
  display: none
}

input[type="radio"]+label,input[type="checkbox"]+label {
  position: relative;
  padding-left: 1.75em;
  line-height: 1.5em
}

input[type="radio"]+label:before,input[type="checkbox"]+label:before {
  content: ' ';
  font-family: fontello;
  display: block;
  text-align: center;
  border: 1px solid #eee;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1.5em;
  width: .6em;
  height: .6em;
  line-height: .53em
}

input[type="radio"]+label:before {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%
}

input[type="checkbox"]:checked+label:before {
  content: '\e8ab'
}

input[type="radio"]:checked+label:before {
  content: '\e83c'
}

.widget_search form,.select_container {
  width: 100%;
  overflow: hidden;
  position: relative
}

.select_container select {
  border: 1px solid #eee
}

.select_container,.select_container:after,.select_container select {
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s
}

.widget_search form:after,.select_container:after {
  content: '\e828';
  font-family: 'fontello';
  display: block;
  text-align: center;
  position: absolute;
  right: 1em;
  top: 50%;
  margin-top: -0.5em;
  width: 1em;
  height: 1em;
  line-height: 1em;
  z-index: 0;
  pointer-events: none;
  cursor: pointer
}

.widget_search form:after {
  content: '\e913';
  right: 1.2em;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s
}

.widget_search .search-field,.select_container select {
  width: 100%;
  padding-right: 3em !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.select_container select {
  width: 110%;
  background: none !important
}

.select_container select,.select_container select option {
  padding: .9em 1em;
}

@-moz-document url-prefix() {
  .select_container select,.select_container select option {
    padding: .5em 1em;
  }
}

.select2-container .select2-choice {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0
}
/* 4. WP styles and Screen readers
-------------------------------------------------------------- */
.screen-reader-text {
  clip: rect(1px,1px,1px,1px);
  position: absolute !important;
  margin: 0 !important;
  padding: 0 !important
}

.screen-reader-text:hover,.screen-reader-text:active,.screen-reader-text:focus {
  display: block;
  top: 5px;
  left: 5px;
  width: auto;
  height: auto;
  padding: 1em 1.5em;
  font-size: .8em;
  line-height: normal;
  color: #21759b;
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0,0,0,0.6);
  clip: auto !important;
  text-decoration: none;
  z-index: 100000
}

.alignleft {
  display: inline-block;
  float: left;
  margin-right: 2em
}

.alignright {
  display: inline-block;
  float: right;
  margin-left: 2em
}

.aligncenter {
  clear: both;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center
}

figure.alignleft,figure.alignright {
  margin-top: .5em
}

.sticky_wrap {
  margin-bottom: 1.7333em
}

.sticky {
  position: relative;
  border: 2px solid #ddd;
  padding: 2em
}

.sticky .label_sticky {
  display: block;
  position: absolute;
  z-index: 1;
  right: -14px;
  top: -14px;
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-top-color: #ddd;
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg)
}

.sticky .post_categories {
  margin-top: 1em
}

.sticky .post_title {
  margin-top: .5em;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1px
}

.gallery {
  margin: 0 -5px
}

.gallery .gallery-item {
  display: inline-block;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.gallery .gallery-item a {
  display: block
}

.gallery .gallery-item a img {
  border: none !important;
  display: block;
  width: 100%
}

.gallery .gallery-item .gallery-icon {
  margin: 0 5px
}

.gallery .gallery-item .gallery-caption {
  line-height: 1.3em;
  margin: 0 5px;
  padding: 6px;
  font-size: .8em;
  max-height: 3.8em
}

.gallery .gallery-item:hover .gallery-caption {
  bottom: -200px
}

.gallery.gallery-columns-9 .gallery-item {
  width: 11.1111% !important
}

.gallery.gallery-columns-8 .gallery-item {
  width: 12.5% !important
}

.gallery.gallery-columns-7 .gallery-item {
  width: 14.2857% !important
}

.gallery.gallery-columns-6 .gallery-item {
  width: 16.6666% !important
}

.gallery.gallery-columns-5 .gallery-item {
  width: 20% !important
}

.gallery.gallery-columns-4 .gallery-item {
  width: 25% !important
}

.gallery.gallery-columns-3 .gallery-item {
  width: 33.3333% !important
}

.gallery.gallery-columns-2 .gallery-item {
  width: 50% !important
}

.gallery.gallery-columns-1 .gallery-item {
  width: 100% !important
}

.gallery.gallery-columns-9 .gallery-caption {
  font-size: .625em
}

.gallery.gallery-columns-8 .gallery-caption {
  font-size: .6875em
}

.gallery.gallery-columns-7 .gallery-caption {
  font-size: .75em
}

.gallery.gallery-columns-6 .gallery-caption {
  font-size: .8125em
}

.post-password-form label {
  display: inline-block;
  vertical-align: top
}

.post-password-form input[type="submit"] {
  display: inline-block;
  margin-left: .2em;
  padding: .4em 1em;
  border: 2px solid #000
}

/* 5. Theme grid
-------------------------------------------------------------- */
.container,.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 25px;
  padding-right: 25px
}

.row,.columns_wrap {
  margin-left: 0;
  margin-right: -50px
}

.row>[class*="column-"],.columns_wrap>[class*="column-"] {
  display: inline-block;
  /*float: left;*/
  vertical-align: top;
  position: relative;
  min-height: 1px;
  padding-left: 0;
  padding-right: 50px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.row.columns_padding_left,.columns_wrap.columns_padding_left,.row.columns_padding_left {
  margin-left: -50px;
  margin-right: 0
}

.row.columns_padding_left .vc_column_container>.vc_column-inner,.row.columns_padding_left>[class*="column-"],.columns_wrap.columns_padding_left>[class*="column-"],.row>[class*="column-"].columns_padding_left,.columns_wrap>[class*="column-"].columns_padding_left {
  padding-left: 50px;
  padding-right: 0
}

.row.columns_padding_right,.row.columns_padding_right,.columns_wrap.columns_padding_right {
  margin-left: 0;
  margin-right: -50px
}

.row.columns_padding_right .vc_column_container>.vc_column-inner,.row.columns_padding_right>[class*="column-"],.columns_wrap.columns_padding_right>[class*="column-"],.row>[class*="column-"].columns_padding_right,.columns_wrap>[class*="column-"].columns_padding_right {
  padding-left: 0;
  padding-right: 50px
}

.row,.row.columns_padding_center,.columns_wrap.columns_padding_center {
  margin-left: -15px;
  margin-right: -15px
  /*margin-left: -25px;
  margin-right: -25px*/
}

.vc_column_container>.vc_column-inner,.row.columns_padding_center>[class*="column-"],.columns_wrap.columns_padding_center>[class*="column-"],.row>[class*="column-"].columns_padding_center,.columns_wrap>[class*="column-"].columns_padding_center {
  padding-left: 25px;
  padding-right: 25px
}

.row.columns_padding_bottom>[class*="column-"],.columns_wrap.columns_padding_bottom>[class*="column-"],.row>[class*="column-"].columns_padding_bottom,.columns_wrap>[class*="column-"].columns_padding_bottom {
  padding-bottom: 50px
}

.sc_blogger.no_margins .row,.sc_blogger.no_margins .columns_wrap,.row.no_margins,.columns_wrap.no_margins {
  margin: 0 !important
}

.sc_blogger.no_margins .row>[class*="column-"],.sc_blogger.no_margins .columns_wrap>[class*="column-"],.row.no_margins>[class*="column-"],.columns_wrap.no_margins>[class*="column-"] {
  padding: 0 !important
}

.column-1,.column-1_1,.column-2_2,.column-3_3,.column-4_4,.column-5_5,.column-6_6,.column-7_7,.column-8_8,.column-9_9,.column-10_10,.column-11_11,.column-12_12 {
  width: 100%
}

.column-1_2,.column-2_4,.column-3_6,.column-4_8,.column-5_10,.column-6_12 {
  width: 50%
}

.column-1_3,.column-2_6,.column-3_9,.column-4_12 {
  width: 33.33333333%
}

.column-2_3,.column-4_6,.column-8_12 {
  width: 66.66666667%
}

.column-1_4,.column-2_8,.column-3_12 {
  width: 25%
}

.column-3_4,.column-6_8,.column-9_12 {
  width: 75%
}

.column-1_5,.column-2_10 {
  width: 20%
}

.column-2_5,.column-4_10 {
  width: 40%
}

.column-3_5,.column-6_10 {
  width: 60%
}

.column-4_5,.column-8_10 {
  width: 80%
}

.column-1_6,.column-2_12 {
  width: 16.66666667%
}

.column-5_6,.column-10_12 {
  width: 83.33333333%
}

.column-1_7 {
  width: 14.28571429%
}

.column-2_7 {
  width: 28.57142857%
}

.column-3_7 {
  width: 42.85714286%
}

.column-4_7 {
  width: 57.14285714%
}

.column-5_7 {
  width: 71.42857143%
}

.column-6_7 {
  width: 85.71428571%
}

.column-1_8 {
  width: 12.5%
}

.column-3_8 {
  width: 37.5%
}

.column-5_8 {
  width: 62.5%
}

.column-7_8 {
  width: 87.5%
}

.column-1_9 {
  width: 11.11111111%
}

.column-2_9 {
  width: 22.22222222%
}

.column-3_9 {
  width: 33.33333333%
}

.column-4_9 {
  width: 44.44444444%
}

.column-5_9 {
  width: 55.55555556%
}

.column-6_9 {
  width: 66.66666667%
}

.column-7_9 {
  width: 77.77777778%
}

.column-8_9 {
  width: 88.88888889%
}

.column-1_10 {
  width: 10%
}

.column-3_10 {
  width: 30%
}

.column-7_10 {
  width: 70%
}

.column-9_10 {
  width: 90%
}

.column-1_11 {
  width: 9.09090909%
}

.column-2_11 {
  width: 18.18181818%
}

.column-3_11 {
  width: 27.27272727%
}

.column-4_11 {
  width: 36.36363636%
}

.column-5_11 {
  width: 45.45454545%
}

.column-6_11 {
  width: 54.54545455%
}

.column-7_11 {
  width: 63.63636364%
}

.column-8_11 {
  width: 72.72727273%
}

.column-9_11 {
  width: 81.81818182%
}

.column-10_11 {
  width: 90.90909091%
}

.column-1_12 {
  width: 8.33333333%
}

.column-5_12 {
  width: 41.66666667%
}

.column-7_12 {
  width: 58.33333333%
}

.column-11_12 {
  width: 91.66666667%
}

.push-1_2,.push-2_4,.push-3_6,.push-4_8,.push-5_10,.push-6_12 {
  left: 50%
}

.push-1_3,.push-2_6,.push-3_9,.push-4_12 {
  left: 33.33333333%
}

.push-2_3,.push-4_6,.push-8_12 {
  left: 66.66666667%
}

.push-1_4,.push-2_8,.push-3_12 {
  left: 25%
}

.push-3_4,.push-6_8,.push-9_12 {
  left: 75%
}

.push-1_5,.push-2_10 {
  left: 20%
}

.push-2_5,.push-4_10 {
  left: 40%
}

.push-3_5,.push-6_10 {
  left: 60%
}

.push-4_5,.push-8_10 {
  left: 80%
}

.push-1_6,.push-2_12 {
  left: 16.66666667%
}

.push-5_6,.push-10_12 {
  left: 83.33333333%
}

.push-1_7 {
  left: 14.28571429%
}

.push-2_7 {
  left: 28.57142857%
}

.push-3_7 {
  left: 42.85714286%
}

.push-4_7 {
  left: 57.14285714%
}

.push-5_7 {
  left: 71.42857143%
}

.push-6_7 {
  left: 85.71428571%
}

.push-1_8 {
  left: 12.5%
}

.push-3_8 {
  left: 37.5%
}

.push-5_8 {
  left: 62.5%
}

.push-7_8 {
  left: 87.5%
}

.push-1_9 {
  left: 11.11111111%
}

.push-2_9 {
  left: 22.22222222%
}

.push-3_9 {
  left: 33.33333333%
}

.push-4_9 {
  left: 44.44444444%
}

.push-5_9 {
  left: 55.55555556%
}

.push-6_9 {
  left: 66.66666667%
}

.push-7_9 {
  left: 77.77777778%
}

.push-8_9 {
  left: 88.88888889%
}

.push-1_10 {
  left: 10%
}

.push-3_10 {
  left: 30%
}

.push-7_10 {
  left: 70%
}

.push-9_10 {
  left: 90%
}

.push-1_11 {
  left: 9.09090909%
}

.push-2_11 {
  left: 18.18181818%
}

.push-3_11 {
  left: 27.27272727%
}

.push-4_11 {
  left: 36.36363636%
}

.push-5_11 {
  left: 45.45454545%
}

.push-6_11 {
  left: 54.54545455%
}

.push-7_11 {
  left: 63.63636364%
}

.push-8_11 {
  left: 72.72727273%
}

.push-9_11 {
  left: 81.81818182%
}

.push-10_11 {
  left: 90.90909091%
}

.push-1_12 {
  left: 8.33333333%
}

.push-5_12 {
  left: 41.66666667%
}

.push-7_12 {
  left: 58.33333333%
}

.push-11_12 {
  left: 91.66666667%
}

.pull-1_2,.pull-2_4,.pull-3_6,.pull-4_8,.pull-5_10,.pull-6_12 {
  right: 50%
}

.pull-1_3,.pull-2_6,.pull-3_9,.pull-4_12 {
  right: 33.33333333%
}

.pull-2_3,.pull-4_6,.pull-8_12 {
  right: 66.66666667%
}

.pull-1_4,.pull-2_8,.pull-3_12 {
  right: 25%
}

.pull-3_4,.pull-6_8,.pull-9_12 {
  right: 75%
}

.pull-1_5,.pull-2_10 {
  right: 20%
}

.pull-2_5,.pull-4_10 {
  right: 40%
}

.pull-3_5,.pull-6_10 {
  right: 60%
}

.pull-4_5,.pull-8_10 {
  right: 80%
}

.pull-1_6,.pull-2_12 {
  right: 16.66666667%
}

.pull-5_6,.pull-10_12 {
  right: 83.33333333%
}

.pull-1_7 {
  right: 14.28571429%
}

.pull-2_7 {
  right: 28.57142857%
}

.pull-3_7 {
  right: 42.85714286%
}

.pull-4_7 {
  right: 57.14285714%
}

.pull-5_7 {
  right: 71.42857143%
}

.pull-6_7 {
  right: 85.71428571%
}

.pull-1_8 {
  right: 12.5%
}

.pull-3_8 {
  right: 37.5%
}

.pull-5_8 {
  right: 62.5%
}

.pull-7_8 {
  right: 87.5%
}

.pull-1_9 {
  right: 11.11111111%
}

.pull-2_9 {
  right: 22.22222222%
}

.pull-3_9 {
  right: 33.33333333%
}

.pull-4_9 {
  right: 44.44444444%
}

.pull-5_9 {
  right: 55.55555556%
}

.pull-6_9 {
  right: 66.66666667%
}

.pull-7_9 {
  right: 77.77777778%
}

.pull-8_9 {
  right: 88.88888889%
}

.pull-1_10 {
  right: 10%
}

.pull-3_10 {
  right: 30%
}

.pull-7_10 {
  right: 70%
}

.pull-9_10 {
  right: 90%
}

.pull-1_11 {
  right: 9.09090909%
}

.pull-2_11 {
  right: 18.18181818%
}

.pull-3_11 {
  right: 27.27272727%
}

.pull-4_11 {
  right: 36.36363636%
}

.pull-5_11 {
  right: 45.45454545%
}

.pull-6_11 {
  right: 54.54545455%
}

.pull-7_11 {
  right: 63.63636364%
}

.pull-8_11 {
  right: 72.72727273%
}

.pull-9_11 {
  right: 81.81818182%
}

.pull-10_11 {
  right: 90.90909091%
}

.pull-1_12 {
  right: 8.33333333%
}

.pull-5_12 {
  right: 41.66666667%
}

.pull-7_12 {
  right: 58.33333333%
}

.pull-11_12 {
  right: 91.66666667%
}

.offset-1_2,.offset-2_4,.offset-3_6,.offset-4_8,.offset-5_10,.offset-6_12 {
  margin-left: 50%
}

.offset-1_3,.offset-2_6,.offset-3_9,.offset-4_12 {
  margin-left: 33.33333333%
}

.offset-2_3,.offset-4_6,.offset-8_12 {
  margin-left: 66.66666667%
}

.offset-1_4,.offset-2_8,.offset-3_12 {
  margin-left: 25%
}

.offset-3_4,.offset-6_8,.offset-9_12 {
  margin-left: 75%
}

.offset-1_5,.offset-2_10 {
  margin-left: 20%
}

.offset-2_5,.offset-4_10 {
  margin-left: 40%
}

.offset-3_5,.offset-6_10 {
  margin-left: 60%
}

.offset-4_5,.offset-8_10 {
  margin-left: 80%
}

.offset-1_6,.offset-2_12 {
  margin-left: 16.66666667%
}

.offset-5_6,.offset-10_12 {
  margin-left: 83.33333333%
}

.offset-1_7 {
  margin-left: 14.28571429%
}

.offset-2_7 {
  margin-left: 28.57142857%
}

.offset-3_7 {
  margin-left: 42.85714286%
}

.offset-4_7 {
  margin-left: 57.14285714%
}

.offset-5_7 {
  margin-left: 71.42857143%
}

.offset-6_7 {
  margin-left: 85.71428571%
}

.offset-1_8 {
  margin-left: 12.5%
}

.offset-3_8 {
  margin-left: 37.5%
}

.offset-5_8 {
  margin-left: 62.5%
}

.offset-7_8 {
  margin-left: 87.5%
}

.offset-1_9 {
  margin-left: 11.11111111%
}

.offset-2_9 {
  margin-left: 22.22222222%
}

.offset-3_9 {
  margin-left: 33.33333333%
}

.offset-4_9 {
  margin-left: 44.44444444%
}

.offset-5_9 {
  margin-left: 55.55555556%
}

.offset-6_9 {
  margin-left: 66.66666667%
}

.offset-7_9 {
  margin-left: 77.77777778%
}

.offset-8_9 {
  margin-left: 88.88888889%
}

.offset-1_10 {
  margin-left: 10%
}

.offset-3_10 {
  margin-left: 30%
}

.offset-7_10 {
  margin-left: 70%
}

.offset-9_10 {
  margin-left: 90%
}

.offset-1_11 {
  margin-left: 9.09090909%
}

.offset-2_11 {
  margin-left: 18.18181818%
}

.offset-3_11 {
  margin-left: 27.27272727%
}

.offset-4_11 {
  margin-left: 36.36363636%
}

.offset-5_11 {
  margin-left: 45.45454545%
}

.offset-6_11 {
  margin-left: 54.54545455%
}

.offset-7_11 {
  margin-left: 63.63636364%
}

.offset-8_11 {
  margin-left: 72.72727273%
}

.offset-9_11 {
  margin-left: 81.81818182%
}

.offset-10_11 {
  margin-left: 90.90909091%
}

.offset-1_12 {
  margin-left: 8.33333333%
}

.offset-5_12 {
  margin-left: 41.66666667%
}

.offset-7_12 {
  margin-left: 58.33333333%
}

.offset-11_12 {
  margin-left: 91.66666667%
}

.clearfix:before,.clearfix:after,.container:before,.container:after,.container-fluid:before,.container-fluid:after,.row:before,.row:after,.columns_wrap:before,.columns_wrap:after {
  content: " ";
  display: table
}

.clearfix:after,.container:after,.container-fluid:after,.row:after,.columns_wrap:after {
  clear: both;
  width: 100%;
  height: 0;
  display: block
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto
}

.pull-right {
  float: right !important
}

.pull-left {
  float: left !important
}

.affix {
  position: fixed;
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.show {
  display: block !important
}

.hide {
  display: none !important
}

.hidden {
  display: none !important;
  visibility: hidden !important
}

.text-hide {
  font-size: 0;
  line-height: 0;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0
}

.vertical-centered {
  overflow: hidden
}

.vertical-centered:before {
  content: ' ';
  height: 100%;
  width: 0;
  margin-left: -0.3em
}

.vertical-centered:before,.vertical-centered>* {
  display: inline-block;
  vertical-align: middle
}

.laon_wine_house_loading {
  background-image: url("../images/preloader.gif");
  background-position: center;
  background-repeat: no-repeat
}

/* 6. Page layouts
-------------------------------------------------------------- */
.page_wrap,.content_wrap {
  margin: 0 auto
}

#page_preloader,.body_style_boxed {
  background-color: #f0f0f0
}

.body_style_boxed .page_wrap {
  width: 1290px
}
.page_content_wrap .wrap {
  position: relative;
  overflow: hidden;
}
.wrap,.content_container {
  width: 1170px;
  margin: 0 auto
}
/*.content_wrap,.content_container {
  width: 1170px;
  margin: 0 auto
}*/

.content_wrap .content_wrap,.content_wrap .content_container,.content_container .content_wrap,.content_container .content_container {
  width: 100%
}

.content_wrap:after,.wrap:after,.content_container:after {
  content: " ";
  display: block;
  width: 100%;
  height: 0;
  clear: both
}

.body_style_fullwide .wrap,
.body_style_fullwide .content_wrap {
  width: 100%;
  padding-left: 130px;
  padding-right: 130px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.content,.sidebar,.sidebar_inner {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.page_content_wrap .content_wrap {
  position: relative
}

body:not(.expand_content):not(.body_style_fullwide):not(.body_style_fullscreen) .content {
  width: 770px
}

.sidebar {
  width: 370px
}

.sidebar_hide .content {
  margin-left: auto;
  margin-right: auto
}

.sidebar_right .content {
  float: left
}

.sidebar_right .sidebar {
  float: right
}

.sidebar_left .content {
  float: right
}

.sidebar_left .sidebar {
  float: left
}

.body_style_fullwide .content,.body_style_fullscreen .content {
  width: auto;
  max-width: 100%
}

.body_style_fullwide.sidebar_right .content,.body_style_fullscreen.sidebar_right .content {
  padding-right: 400px
}

.body_style_fullwide.sidebar_right .sidebar,.body_style_fullscreen.sidebar_right .sidebar {
  margin-left: -370px
}

.body_style_fullwide.sidebar_left .content,.body_style_fullscreen.sidebar_left .content {
  padding-left: 400px
}

.body_style_fullwide.sidebar_left .sidebar,.body_style_fullscreen.sidebar_left .sidebar {
  margin-right: -370px
}

.body_style_fullscreen .page_content_wrap {
  overflow: hidden;
  padding: 0
}

.body_style_fullscreen .content>article.page {
  padding: 0
}

/* 7. Section's decorations
-------------------------------------------------------------- */


/* 7.1 Header: Logo and Menu
-------------------------------------------------------------- */
.top_panel {
  position: relative;
  z-index: 10000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: .1px 0;
  margin-bottom: 1.1em
}

.remove_margins .top_panel {
  margin-bottom: 0
}

.top_panel:before {
  content: ' ';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.25);
  background: linear-gradient(to top,rgba(0,0,0,0.3) 0,rgba(0,0,0,0) 70%) no-repeat scroll right top / 100% 100% rgba(0,0,0,0);
  display: none
}

.top_panel .post_featured_fullwide {
  min-height: 34.74em;
  margin: 3.8em 0 3.4em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover
}

.top_panel.with_featured_image:before {
  display: none
}

.top_panel.with_bg_image:before {
  display: block
}

.top_panel_style_1.with_bg_image.header_fullheight .logo {
  margin-bottom: 11em
}

.top_panel_style_1.with_bg_image.header_fullheight .top_panel_title:after {
  margin-bottom: 11em
}

.top_panel_style_2.with_bg_image.header_fullheight .top_panel_title_2 {
  margin-top: 5em
}

.top_panel_style_2.with_bg_image.header_fullheight .menu_header_nav_area {
  margin-bottom: 25.5em
}

#background_video {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1
}

.top_panel.with_bg_video {
  background: #000
}

.header_position_over .top_panel {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  background: none !important
}

.header_position_under .top_panel {
  background-size: cover;
  position: relative;
  z-index: 2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box
}

.header_position_under .top_panel_mask {
  display: none;
  position: absolute;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  filter: alpha(opacity=0);
  pointer-events: none
}

.header_position_under .page_content_wrap {
  position: relative;
  z-index: 3
}

.header_position_under .site_footer_wrap {
  position: relative;
  z-index: 1
}

.top_panel_fixed_wrap {
  display: none
}

.menu_style_side .top_panel_fixed_wrap,.header_style_header-2 .top_panel_fixed_wrap {
  display: none !important
}

.top_panel_fixed .top_panel_fixed_wrap {
  display: block
}

.top_panel_fixed .top_panel_navi {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  padding-top: 0;
  padding-bottom: 0 !important;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  transform: none
}

.top_panel_fixed .menu_main_wrap {
  padding: 1em 0;
  background-color: #fff;
  -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
  -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.3)
}

.top_panel_fixed .logo {
  display: none
}

.top_panel_fixed .menu_main_nav_area {
  margin-top: 0
}

.admin-bar.top_panel_fixed .top_panel_navi {
  top: 32px
}

.logo {
  position: relative;
  display: block;
  text-align: center;
  margin: 0 4em
}

.logo img {
  display: inline-block;
  margin: 0;
  max-width: 100%;
  max-height: 18em;
  width: auto
}

.logo_text {
  display: block;
  font-size: 1.7857em;
  font-style: normal;
  line-height: 1.3em;
  text-transform: uppercase;
  letter-spacing: 4px
}

.logo_slogan {
  display: block;
  font-size: .9286em;
  line-height: 1.7em;
  letter-spacing: 1px
}

.top_panel_title_2 {
  text-align: center
}

.top_panel_title_2_image {
  max-height: 7em
}

.top_panel_title_2_image+.top_panel_title_2_text {
  margin-top: 3.66em
}

.top_panel_title_2_text {
  font-size: .8421em;
  font-weight: 600;
  line-height: 2em;
  text-transform: uppercase;
  letter-spacing: 2px;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto
}

.top_panel_navi [class^="icon-"]:before,.top_panel_navi [class*=" icon-"]:before {
  margin-right: .5em
}

.top_panel_navi {
  position: relative;
  z-index: 3;
  padding-top: 3.7em
}

.header_title_off .top_panel_navi {
  padding-bottom: 3.7em
}

.top_panel_navi_header .menu_header_nav_area {
  padding-right: 0
}

.top_panel_navi_header ul,.top_panel_navi ul {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style-type: none
}

.top_panel_navi_header ul:after,.top_panel_navi ul:after {
  content: ' ';
  display: block;
  width: 100%;
  height: 0;
  clear: both
}

.top_panel_navi_header li,.top_panel_navi li {
  margin: 0;
  position: relative;
  letter-spacing: 2px
}

.top_panel_navi_header li>a,.top_panel_navi li>a {
  display: block;
  position: relative
}

.top_panel_navi_header .menu_header_nav>li.menu-item-has-children>a:after,.top_panel_navi .menu_main_nav>li.menu-item-has-children>a:after {
  font-family: 'fontello';
  display: inline-block;
  position: absolute;
  z-index: 1;
  font-weight: normal;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

.top_panel_navi_header .menu_header_nav>li.menu-item-has-children>a:after,.top_panel_navi .menu_main_nav>li.menu-item-has-children>a:after {
  content: '\e828';
  right: 2.2em
}

.top_panel_navi_header li li.menu-item-has-children>a:after,.top_panel_navi li li.menu-item-has-children>a:after {
  content: '\e836';
  font-family: 'fontello';
  font-weight: normal;
  position: absolute;
  right: 1em;
  top: 50%;
  margin-top: -0.8em
}

.menu_header_wrap,.menu_main_wrap {
  text-align: center
}

.menu_header_nav_area,.menu_main_nav_area {
  font-size: 11px;
  font-weight: bold;
  line-height: 1.5em;
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 3.5em;
  margin-top: 4.8em;
  visibility: hidden;
  text-align: center
}

.menu_header_nav_area {
  margin-top: 2em;
  margin-bottom: 6em
}

.menu_header_nav_area.menu_show,.menu_main_nav_area.menu_show {
  visibility: visible
}

.menu_header_nav,.menu_main_nav {
  text-align: center;
  vertical-align: middle
}

.menu_header_nav>li,.menu_main_nav>li {
  position: relative;
  float: left;
  z-index: 20
}

.menu_main_nav>li+li {
  z-index: 19
}

.menu_main_nav>li+li+li {
  z-index: 18
}

.menu_main_nav>li+li+li+li {
  z-index: 17
}

.menu_main_nav>li+li+li+li+li {
  z-index: 16
}

.menu_main_nav>li+li+li+li+li+li {
  z-index: 15
}

.menu_main_nav>li+li+li+li+li+li+li {
  z-index: 14
}

.menu_main_nav>li+li+li+li+li+li+li+li {
  z-index: 13
}

.menu_main_nav>li+li+li+li+li+li+li+li+li {
  z-index: 12
}

.menu_main_nav>li+li+li+li+li+li+li+li+li+li {
  z-index: 11
}

.menu_main_nav>li ul {
  position: absolute;
  display: none;
  z-index: 10000
}

.menu_header_nav>li>a,.menu_main_nav>li>a {
  text-transform: uppercase;
  padding: 1em 2.5em;
  position: relative
}

.menu_header_nav>li.menu-item-has-children>a,.menu_main_nav>li.menu-item-has-children>a {
  padding-right: 3.5em
}

.menu_header_nav>li+li>a:before,.menu_main_nav>li+li>a:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -5px;
  width: 0;
  height: 11px;
  border-left: 1px dotted #ddd
}

.menu_header_nav>li ul,.menu_main_nav>li ul {
  width: 16.3636em;
  padding: 2.3em 0;
  line-height: 1.5em;
  text-transform: uppercase;
  text-align: center;
  border: 2px solid #ddd;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box
}

.menu_header_nav>li ul:before,.menu_main_nav>li ul:before {
  content: ' ';
  position: absolute;
  z-index: 1;
  top: -16px;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
  border: 8px solid transparent
}

.menu_header_nav>li ul>li>a,.menu_main_nav>li ul>li>a {
  padding: .6em 2em
}

.menu_header_nav>li>ul,.menu_main_nav>li>ul {
  top: 4.5em;
  left: 50%;
  margin-left: -8.1818em
}

.menu_header_nav>li>ul ul,.menu_main_nav>li>ul ul {
  left: 16.3636em;
  top: -2.3em;
  margin: -2px 0 0 10px
}

.menu_header_nav>li>ul ul:before,.menu_main_nav>li>ul ul:before {
  top: 2.3em;
  left: -16px;
  margin-left: 0;
  margin-top: 8px
}

.menu_header_nav>li>ul ul.submenu_left,.menu_main_nav>li>ul ul.submenu_left {
  left: -16.3636em;
  margin: -2px 0 0 -14px
}

.menu_header_nav>li>ul ul.submenu_left:before,.menu_main_nav>li>ul ul.submenu_left:before {
  top: 2.3em;
  left: auto;
  right: -16px
}

body.menu_style_side {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.menu_style_side .body_wrap {
  position: relative;
  overflow: hidden;
  max-width: 100%
}

.menu_style_side .menu_side_wrap {
  position: fixed;
  z-index: 20000;
  left: auto;
  right: 0;
  top: 0;
  bottom: 0;
  width: 49px
}

.menu_side_wrap .menu_side_inner {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.menu_side_wrap .menu_mobile_button {
  display: block;
  right: 50%;
  top: 1.5em;
  -webkit-transform: translateX(50%);
  -moz-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%)
}

.admin-bar .menu_side_wrap {
  top: 32px
}

body.mobile_layout.menu_style_side {
  padding-right: 0
}

.mobile_layout.menu_style_side .menu_side_wrap {
  position: absolute;
  z-index: 3;
  right: -49px;
  top: 1em
}

.mobile_layout.menu_style_side .menu_side_wrap .menu_mobile_button {
  left: -49px;
  right: auto;
  display: none
}

.mobile_layout .menu_mobile .socials_mobile {
  position: static
}

.mobile_layout .menu_mobile_button {
  top: 4.25em;
  right: 2em;
  z-index: 5
}

.mobile_layout .menu_mobile_inner {
  padding: 5em 3em
}

.mobile_layout .menu_mobile_close {
  top: 0;
  margin: 1em 2em 0 0
}

.no_layout .top_panel_navi {
  visibility: hidden
}

.mobile_layout .top_panel .logo {
  position: static;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  transform: none
}

.mobile_layout .top_panel_navi .content_wrap {
  position: relative
}

.mobile_layout .menu_main_nav_area {
  display: none
}

.menu_mobile_close,.menu_mobile_button {
  display: none;
  width: 1.25em;
  height: 1em;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.menu_mobile_button {
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff
}

.menu_mobile_button:before,.menu_mobile_close:before,.menu_mobile_close:after {
  content: ' ';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 0;
  margin-top: -1px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-top: 2px solid #fff
}

.menu_mobile_button_text {
  border: 0;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  height: auto
}

.menu_mobile_button_text:before {
  display: none
}

.mobile_layout .menu_mobile_button {
  display: block
}

.menu_mobile_close {
  display: block;
  margin: 2em 2em 0 0
}

.menu_mobile_close:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg)
}

.menu_mobile_close:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.menu_mobile_overlay {
  display: none !important;
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.8)
}

.menu_mobile {
  position: fixed;
  z-index: 100002;
  right: 0;
  top: 0;
  bottom: 0;
  width: 300px;
  margin-right: -300px;
  -webkit-transition: margin ease .3s;
  -moz-transition: margin ease .3s;
  -ms-transition: margin ease .3s;
  -o-transition: margin ease .3s;
  transition: margin ease .3s
}

.menu_mobile_inner {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 6em 2em;
  overflow-y: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.admin-bar .menu_mobile_overlay,.admin-bar .menu_mobile {
  top: 32px
}

.menu_mobile.opened {
  margin-right: 0
}

.menu_mobile a {
  display: block
}

.menu_mobile .menu_mobile_nav_area ul {
  margin: 0;
  padding: 0;
  list-style: none
}

.menu_mobile .menu_mobile_nav_area ul ul {
  margin-left: 1.5em;
  display: none
}

.menu_mobile .menu_mobile_nav_area li {
  margin-bottom: 0
}

.menu_mobile .menu_mobile_nav_area li>a {
  font-size: 13px;
  line-height: 1.5em;
  padding: .5em 0;
  text-transform: uppercase;
  letter-spacing: 2px
}

.menu_mobile .menu_mobile_nav_area li.menu-item-has-children>a {
  position: relative;
  padding-right: 3em
}

.menu_mobile .menu_mobile_nav_area .open_child_menu {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  width: 1em;
  height: 1em;
  line-height: 1em;
  padding: .7em;
  text-align: center;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s
}

.menu_mobile .menu_mobile_nav_area a:hover>.open_child_menu {
  background-color: rgba(255,255,255,0.1)
}

.menu_mobile .menu_mobile_nav_area .open_child_menu:before {
  font-family: 'fontello';
  content: '\e828'
}

.menu_mobile .menu_mobile_nav_area li.opened>a>.open_child_menu:before {
  content: '\e835'
}

.menu_mobile .socials_mobile {
  text-align: left;
  margin: 3em;
  position: absolute;
  bottom: 0;
  left: 0
}

.menu_mobile .social_item {
  display: inline-block;
  margin: 1em 1em 0 0
}

.menu_mobile .social_item a {
  display: block;
  font-size: 1em;
  width: 1em;
  height: 1em;
  line-height: 1em;
  text-align: center
}

.menu_mobile .search_mobile {
  margin-top: 3em
}

.menu_mobile .search_mobile form {
  position: relative
}

.menu_mobile .search_mobile .search_field {
  width: 100%;
  padding: 1.5em 3em 1.5em 1.5em
}

.menu_mobile .search_mobile .search_submit {
  position: absolute;
  right: 0;
  top: 0;
  padding: 1.2em 1.4em 1.1em 1em;
  background-color: transparent
}

.search_wrap {
  display: block;
  vertical-align: middle;
  position: relative
}

.search_wrap .search_form {
  position: relative;
  vertical-align: middle
}

.search_wrap .search_field {
  width: 7em;
  line-height: 1.3em;
  letter-spacing: 1px;
  padding: .5em 2.2em .3em .3em;
  background-color: transparent !important
}

.search_wrap .search_submit {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  padding: .5em .5em .3em .5em;
  line-height: 1.3em
}

.search_wrap .icon-search:before {
  content: '\e8f9';
  margin: 0;
  line-height: 1.3em
}

.top_panel_navi .search_wrap {
  display: inline-block;
  position: absolute;
  z-index: 1000;
  right: 0;
  top: -0.6em;
  border: 1px solid transparent;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.top_panel_navi .search_wrap .search_form {
  position: static;
  font-size: .8em
}

.top_panel_navi .search_wrap .search_field {
  width: 0;
  visibility: hidden;
  padding: .7em 1.5em .7em 1em;
  font-size: 2em;
  border: 0;
  top: -0.5em
}

.top_panel_navi .search_wrap .search_submit {
  display: inline-block;
  padding: .7em 0;
  font-size: 2em;
  line-height: 1em;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  background: none !important
}

.search_style_expand.search_opened {
  width: 100%
}

.search_style_expand.search_opened .search_field {
  width: 100%;
  visibility: visible
}

.search_style_expand.search_opened .search_submit {
  padding: .8em
}

.search_style_expand.search_opened .search_submit:before {
  margin: 0
}

.search_style_fullscreen.search_opened .search_form_wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  overflow: hidden;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s
}

.search_style_fullscreen.search_opened .search_form {
  position: relative;
  width: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  border-bottom: 1px solid #fff;
  font-size: 1em
}

.search_style_fullscreen .search_close {
  display: none
}

.search_style_fullscreen.search_opened .search_close {
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  font-size: 2em;
  font-weight: 300;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s
}

.search_style_fullscreen.search_opened .search_close:before {
  margin: 0
}

.search_style_fullscreen.search_opened .search_submit {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: none;
  font-size: 1.5em;
  width: auto;
  padding: 0
}

.search_style_fullscreen.search_opened .search_field {
  font-size: 2em;
  font-weight: 400;
  height: 3em;
  letter-spacing: 1px;
  line-height: 3em;
  margin: 0;
  padding: 0 0 0 2em;
  white-space: pre-wrap;
  word-wrap: break-word;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  resize: none;
  overflow: hidden;
  display: block;
  visibility: visible;
  width: 100%
}

.search_style_fullscreen.search_opened .search_field,.search_style_fullscreen.search_opened .search_field:hover,.search_style_fullscreen.search_opened .search_field:focus {
  background-color: transparent !important
}

.content .search_wrap {
  max-width: 50%;
  margin: 0
}

.post_item_none_search .search_wrap,.post_item_none_archive .search_wrap {
  max-width: none
}

.content .search_wrap .search_form_wrap {
  width: 100%
}

.content .search_wrap .search_form_wrap .search_field {
  width: 100%
}

.post_item_none_search .search_wrap .search_field,.post_item_none_archive .search_wrap .search_field {
  padding: 1.2em 3em 1em 1em
}

.post_item_none_search .search_wrap .search_submit,.post_item_none_archive .search_wrap .search_submit {
  right: .8em;
  top: .8em
}

.content .search_wrap .search_results {
  width: 100%
}

.search_wrap .search_results {
  display: none;
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 5.1em;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  width: 22em;
  padding: 2em 1.5em;
  font-size: 1.1818em;
  line-height: 1.5em;
  text-align: left
}

.search_wrap .search_results:after {
  content: " ";
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-left: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  position: absolute;
  top: -6px;
  left: 3em;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg)
}

.search_wrap .search_results .search_results_close {
  position: absolute;
  top: 3px;
  right: 3px;
  display: block;
  text-align: center;
  overflow: hidden;
  font-size: 1em;
  width: 1.2em;
  height: 1.2em;
  line-height: 1.2em;
  cursor: pointer
}

.search_wrap .post_more {
  display: block;
  text-align: center
}

.widget_area .post_item,aside .post_item {
  overflow: hidden;
  text-align: left;
  margin-bottom: 0;
  position: relative;
  background-color: transparent !important
}

aside .post_item+.post_item {
  margin-top: 2em;
  padding-top: 0;
  border-top: 0
}

.search_results.widget_area .post_item+.post_item {
  border-top: 1px solid #e5e5e5;
  margin-top: 1.5em;
  padding-top: 1.5em
}

.widget_area .post_item .post_content,aside .post_item .post_content {
  overflow: hidden
}

.widget_area .post_item .post_thumb,aside .post_item .post_thumb {
  float: left;
  width: 5.3571em;
  height: 5.3571em;
  margin: 0 1em 4px 0;
  overflow: hidden
}

.widget_area .post_item .post_thumb img,aside .post_item .post_thumb img {
  width: 100%
}

.widget_area .post_item .post_title,aside .post_item .post_title {
  font-weight: 600;
  margin: -2px 0 6px 0;
  text-align: left
}

.widget_area .post_item .post_info,aside .post_item .post_info {
  font-size: 12px;
  margin-bottom: 0
}

.widget_area .post_item .post_info .post_info_item+.post_info_item:before,aside .post_item .post_info .post_info_item+.post_info_item:before {
  content: "";
  margin: 0 0 0 5px
}

.widget_area .post_item .post_categories,aside .post_item .post_categories {
  font-size: 10px;
  font-weight: 700;
  line-height: 1.5em;
  text-transform: uppercase;
  margin-top: -0.4em;
  margin-bottom: 1em;
  position: relative;
  padding-right: 3em
}

.widget_area .post_item .post_categories .post_info_counters,aside .post_item .post_categories .post_info_counters {
  position: absolute;
  right: 0;
  top: 0
}

.header_widgets_wrap {
  position: relative;
  z-index: 2
}

.header_widgets_wrap.header_fullwidth {
  overflow: hidden
}

.header_widgets_wrap.header_fullwidth aside {
  margin-bottom: 0
}

/* 7.2 Sliders
-------------------------------------------------------------- */
/* Revolution slider */
.slider_wrap .rev_slider_wrapper {
  z-index: 0
}

/* 7.3 Page info (page/post title, category or tag name, author, etc.)
-------------------------------------------------------------- */
.top_panel_title_wrap {
  position: relative;
  z-index: 2;
  text-align: center
}

.top_panel_title:after {
  content: ' ';
  width: 1px;
  height: 3.8em;
  display: block;
  margin: 1.3em auto 2.7em
}

.top_panel_title .post_meta {
  margin-top: 4.7em
}

.top_panel_title .page_caption {
  margin: 1.15em auto 0;
  letter-spacing: 2px
}

.top_panel_title .post_meta+.page_caption {
  margin-top: .4em;
  font-size: 1.8947em;
  line-height: 1.45em
}

.top_panel_title .page_caption img {
  display: inline-block;
  max-height: 1.5em;
  vertical-align: middle;
  width: auto;
  margin: -0.2em .2em 0 0
}

.top_panel_title .page_title_link {
  margin: 2.5em 0
}

.top_panel_title .page_description {
  font-size: .8em;
  line-height: 1.5em;
  margin: .5em auto 0;
  max-width: 75%
}

.top_panel_title .page_description p {
  margin: 0
}

.breadcrumbs {
  font-size: .9474em;
  font-weight: 700;
  font-style: italic;
  letter-spacing: 1px;
  margin-top: .5em
}

.breadcrumbs .breadcrumbs_item {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap
}

.breadcrumbs .breadcrumbs_delimiter {
  display: inline-block;
  position: relative;
  margin: 0 .45em;
  vertical-align: top;
  text-align: center
}

.breadcrumbs .breadcrumbs_delimiter:before {
  content: '/'
}

/* 7.4 Blog layouts
-------------------------------------------------------------- */
.blog_archive {
  padding-bottom: 4em
}

.post_item {
  margin-bottom: 2.3077em
}

.post_item p {
  margin-bottom: 0
}

.post_item>.post_title {
  margin-top: 0
}

.post_item .more-link {
  display: inline-block;
  text-transform: uppercase;
  white-space: nowrap;
  padding: .5em 3em;
  margin-top: 2.5em;
  font-size: 12px;
  line-height: 1.75em;
  font-weight: bold;
  text-transform: uppercase;
  border: 2px solid #000
}

.laon_wine_house_tabs .laon_wine_house_tabs_titles {
  list-style-type: none;
  padding: 0;
  font-size: 12px;
  line-height: 1.4em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 4em
}

.laon_wine_house_tabs .laon_wine_house_tabs_titles li {
  display: inline-block;
  margin-bottom: .25em
}

.laon_wine_house_tabs .laon_wine_house_tabs_titles li+li {
  margin-left: .25em
}

.laon_wine_house_tabs .laon_wine_house_tabs_titles li a {
  display: block;
  padding: 1em 1.5em
}

/*.laon_wine_house_tabs .laon_wine_house_tab_content_remove {
  opacity: 0;
  filter: alpha(opacity=0)
}*/

.laon_wine_house_tabs .nav-links {
  margin-top: 3.2em
}

.post_layout_excerpt {
  position: relative;
  margin-bottom: 0
}

.post_layout_excerpt+.post_layout_excerpt {
  margin-top: 4.6em;
  padding-top: 4.7em;
  border-top: 1px dotted #eee
}

.post_layout_excerpt .post_header {
  margin-bottom: .65em
}

.post_layout_excerpt .post_title {
  margin: 0
}

.post_layout_classic {
  position: relative;
  margin-bottom: 4.4em
}

.post_layout_classic .post_header {
  text-align: center;
  padding-top: 1px
}

.post_layout_classic .post_meta {
  margin-top: .5em
}

.post_layout_classic .post_title {
  margin: .5em 0 0
}

.post_layout_classic .post_content {
  padding-top: .7692em;
  text-align: center
}

.post_layout_classic blockquote {
  padding: 0 0 0 3em
}

.post_layout_classic blockquote:before {
  left: 0;
  top: 0
}

.post_layout_classic.post_format_quote .post_meta {
  margin-left: 4.4em
}

.body_style_fullwide .post_layout_classic .post_header,.body_style_fullwide .post_layout_classic .post_content {
  max-width: 75%;
  margin-left: auto;
  margin-right: auto
}

.chess_wrap {
  overflow: hidden
}

.post_layout_chess {
  position: relative;
  overflow: hidden;
  margin-bottom: 0
}

.post_layout_chess .post_featured {
  margin-bottom: 0;
  width: 50%;
  float: right;
  max-height: none !important
}

.post_layout_chess .post_featured_bg:before {
  padding-top: 100%
}

.post_layout_chess .post_inner {
  width: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

.post_layout_chess_1 .post_inner_content {
  padding: 0 14%
}

.post_layout_chess_2 .post_inner_content {
  padding: 0 12%
}

.post_layout_chess_3 .post_inner_content {
  padding: 0 10%
}

.post_layout_chess .post_header {
  text-align: center;
  padding-top: 1px
}

.post_layout_chess .post_title {
  margin: 0
}

.post_layout_chess .post_meta {
  margin-top: 1em;
  margin-bottom: 0 !important
}

.post_layout_chess .post_content {
  text-align: center
}

.post_layout_chess .post_header+.post_content {
  margin-top: .8421em
}

.post_layout_chess .post_content_inner {
  overflow: hidden;
  position: relative;
  padding-bottom: 1em
}

.post_layout_chess:not(.post_format_aside) .post_content_inner {
  padding-top: 0
}

.post_layout_chess .post_content_inner:after {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1em
}

.post_layout_chess blockquote {
  padding: 0 0 0 3em
}

.post_layout_chess blockquote:before {
  left: 0;
  top: 0
}

.post_layout_chess.post_format_quote .post_meta {
  margin-top: 1em;
  margin-left: 4.4em
}

.post_layout_chess_1 .post_meta:before {
  content: ' ';
  display: block;
  width: 1px;
  height: 2em;
  margin: 3em auto
}

.post_layout_chess_1.post_format_quote .post_meta:before,.post_layout_chess_1.post_format_link .post_meta:before,.post_layout_chess_1.post_format_aside .post_meta:before,.post_layout_chess_1.post_format_status .post_meta:before {
  display: none
}

.post_layout_chess_1.post_format_quote .post_meta,.post_layout_chess_1.post_format_link .post_meta,.post_layout_chess_1.post_format_status .post_meta {
  margin-top: 1em
}

.post_layout_chess_1.post_format_aside .post_meta {
  margin-top: 3em
}

.post_layout_chess_1 .more-link {
  margin-top: 3em;
  padding: 1em 4.5em
}

.post_layout_chess_2 .post_title {
  font-size: 1.5789em
}

.post_layout_chess_2 .post_content_inner {
  font-size: .8947em;
  line-height: 1.4118em
}

.post_layout_chess_2 .post_content_inner {
  max-height: 6em
}

.post_layout_chess_2.post_format_quote .post_content_inner,.post_layout_chess_2.post_format_link .post_content_inner,.post_layout_chess_2.post_format_aside .post_content_inner,.post_layout_chess_2.post_format_status .post_content_inner {
  max-height: 11em
}

.post_layout_chess_2.post_format_audio .post_featured .post_audio_title,.post_layout_chess_3.post_format_audio .post_featured .post_audio_title {
  letter-spacing: 0
}

.post_layout_chess_3 .post_title {
  font-size: 1.3684em;
  line-height: 1.15em
}

.post_layout_chess_3 .post_content_inner {
  font-size: .7895em;
  line-height: 1.3333em
}

.post_layout_chess_3 .post_content_inner {
  max-height: 4.5em
}

.post_layout_chess_3.post_format_quote .post_content_inner,.post_layout_chess_3.post_format_link .post_content_inner,.post_layout_chess_3.post_format_aside .post_content_inner,.post_layout_chess_3.post_format_status .post_content_inner {
  max-height: 6.2em
}

.post_layout_chess_3.post_format_quote blockquote p,.post_layout_chess_3.post_format_link .post_content_inner,.post_layout_chess_3.post_format_status .post_content_inner {
  font-size: 1.3684em;
  line-height: 1.15385em
}

.post_layout_chess_2 .post_meta,.post_layout_chess_3 .post_meta {
  font-size: 11px;
  line-height: 1.45455em;
  margin-top: .8182em
}

.post_layout_chess_2 .more-link,.post_layout_chess_3 .more-link {
  margin-top: 1.55em
}

.post_layout_chess_1:nth-child(2n+2) .post_featured {
  float: left
}

.post_layout_chess_1:nth-child(2n+2) .post_inner {
  left: auto;
  right: 0
}

.post_layout_chess_2 {
  width: 50%;
  float: left
}

.post_layout_chess_2:nth-child(4n+3) .post_featured,.post_layout_chess_2:nth-child(4n+4) .post_featured {
  float: left
}

.post_layout_chess_2:nth-child(4n+3) .post_inner,.post_layout_chess_2:nth-child(4n+4) .post_inner {
  left: auto;
  right: 0
}

.post_layout_chess_3 {
  width: 33.3333%;
  float: left
}

.post_layout_chess_3:nth-child(6n+4) .post_featured,.post_layout_chess_3:nth-child(6n+5) .post_featured,.post_layout_chess_3:nth-child(6n+6) .post_featured {
  float: left
}

.post_layout_chess_3:nth-child(6n+4) .post_inner,.post_layout_chess_3:nth-child(6n+5) .post_inner,.post_layout_chess_3:nth-child(6n+6) .post_inner {
  left: auto;
  right: 0
}

.post_layout_portfolio .post_title {
  margin: 1em 0 .4em
}

.post_layout_portfolio .post_featured {
  margin-bottom: 0
}

.portfolio_wrap {
  position: relative;
  margin-right: -1.7em
}

.post_layout_portfolio {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 0 1.6666em 1.6666em 0;
  margin-bottom: 0;
  width: 50%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.portfolio_wrap.portfolio_3 .post_layout_portfolio {
  width: 33.3333%
}

.portfolio_wrap.portfolio_4 .post_layout_portfolio {
  width: 25%
}

.post_layout_gallery * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.post_layout_gallery .post_details {
  display: none
}

.gallery_item_current {
  opacity: 0 !important
}

.gallery_preview {
  position: fixed;
  z-index: 200000;
  top: 0;
  left: 0;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  align-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: 50%;
  height: 100%;
  pointer-events: none
}

.gallery_preview:before {
  content: ' ';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: opacity .6s;
  -ms-transition: opacity .6s;
  transition: opacity .6s
}

.gallery_preview_show {
  pointer-events: auto
}

.gallery_preview_show:before {
  opacity: 1
}

.gallery_preview_image_clone {
  position: fixed;
  z-index: 110;
  -webkit-transition: -webkit-transform .5s;
  -ms-transition: -ms-transform .5s;
  transition: transform .5s;
  -webkit-backface-visibility: hidden
}

.gallery_preview_image_original {
  position: relative;
  z-index: 120;
  display: block;
  object-fit: contain;
  -webkit-transition: opacity .2s;
  -ms-transition: opacity .2s;
  transition: opacity .2s;
  -webkit-backface-visibility: hidden
}

.gallery_preview_show .gallery_preview_image_animate {
  -webkit-transition: -webkit-transform .6s,opacity .2s;
  -ms-transition: -ms-transform .6s,opacity .2s;
  transition: transform .6s,opacity .2s
}

.gallery_preview_image_animate {
  -webkit-transition: -webkit-transform .3s,opacity .2s;
  -ms-transition: -ms-transform .3s,opacity .2s;
  transition: transform .3s,opacity .2s
}

.gallery_preview_description {
  position: absolute;
  z-index: 140;
  width: 100%;
  left: 100%;
  top: 0;
  height: 100%;
  padding: 0 3em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  opacity: 0;
  -webkit-transition: opacity 1s,-webkit-transform 1s;
  -ms-transition: opacity 1s,-webkit-transform 1s;
  transition: opacity 1s,transform 1s;
  -webkit-transition-timing-function: cubic-bezier(0.2,1,0.3,1);
  -ms-transition-timing-function: cubic-bezier(0.2,1,0.3,1);
  transition-timing-function: cubic-bezier(0.2,1,0.3,1);
  -webkit-transform: translate3d(0,30px,0);
  -ms-transform: translate3d(0,30px,0);
  transform: translate3d(0,30px,0)
}

.gallery_preview_show .gallery_preview_description {
  opacity: 1;
  -webkit-transition-delay: .2s;
  transition-delay: .2s;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.gallery_preview_show .post_title {
  margin: .3em 0 .5em
}

.gallery_preview_show .post_meta {
  margin-bottom: 2em
}

.gallery_preview_show .post_category,.gallery_preview_show .post_description {
  max-width: 100%;
  font-size: 1.1429em;
  line-height: 2em
}

.gallery_preview_show .post_readmore {
  font-size: .8em
}

.gallery_preview_close {
  font-size: 2em;
  margin: 0;
  padding: 0;
  cursor: pointer;
  vertical-align: top;
  border: 0;
  background: 0;
  position: fixed;
  z-index: 150;
  top: 0;
  right: 0;
  padding: 1em;
  opacity: 0;
  -webkit-transition: opacity .3s,-webkit-transform .3s;
  -ms-transition: opacity .3s,-webkit-transform .3s;
  transition: opacity .3s,transform .3s;
  -webkit-transform: scale3d(0.6,0.6,1);
  -ms-transform: scale3d(0.6,0.6,1);
  transform: scale3d(0.6,0.6,1)
}

.gallery_preview_image_loaded .gallery_preview_close {
  opacity: 1;
  -webkit-transform: scale3d(1,1,1);
  -ms-transform: scale3d(1,1,1);
  transform: scale3d(1,1,1)
}

.post_featured {
  overflow: hidden;
  position: relative;
  margin-bottom: 1.8em
}

.post_featured_bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat
}

.post_featured_bg:before {
  content: ' ';
  width: 0;
  height: 0;
  padding-top: 56.25%;
  display: inline-block;
  margin-left: -0.3em
}

.post_featured img {
  width: 100%;
  height: auto
}

.post_featured_right {
  float: right;
  width: 50%;
  margin-left: 4.3478%
}

.post_featured_left {
  float: left;
  width: 50%;
  margin-right: 4.3478%
}

.post_item .slider_swiper .swiper-slide {
  position: relative;
  background-position: center center;
  background-size: cover
}

.post_meta {
  font-size: 12px;
  line-height: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: .4em
}

.post_share .social_items {
  text-transform: uppercase;
  font-size: .9286em
}

.post_share .social_items .social_item a i {
  font-style: normal
}

.post_meta .post_meta_item+.post_meta_item:before,.vc_inline-link:before {
  content: '/';
  display: inline;
  margin: 0 .6em;
  font-family: inherit;
  font-size: inherit;
  font-style: normal;
  font-weight: 700;
  vertical-align: baseline
}

.post_meta .socials_share {
  display: inline;
  vertical-align: baseline
}

.post_meta .socials_share .socials_caption:before {
  display: none
}

.post_meta_item .socials_share .social_items {
  bottom: auto;
  top: 2.5em
}

.post_meta_item .socials_share .social_items:before {
  bottom: auto;
  top: -5px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg)
}

/* 7.5 Post Formats
-------------------------------------------------------------- */
.format-audio .post_featured {
  position: relative;
  max-height: 10em
}

.format-audio .post_featured img {
  position: relative
}

.post_layout_excerpt.format-audio .post_featured img {
  top: -8em
}

.format-audio .post_featured .mask {
  opacity: 1;
  filter: alpha(opacity=100)
}

.format-audio .post_featured .post_audio_author {
  font-size: .9474em;
  font-weight: 700;
  font-style: italic
}

.format-audio .post_featured .post_audio_title {
  font-size: 1.2632em;
  font-weight: 700;
  line-height: 1.35em;
  margin-bottom: .55em;
  letter-spacing: 2px
}

.format-audio .post_featured.with_thumb .post_audio {
  position: absolute;
  z-index: 1000;
  bottom: 2.6em;
  left: 6%;
  right: 6%
}

.format-audio .post_featured.without_thumb .post_audio {
  padding: 2em 2.5em 2.4em;
  border: 1px dotted #ddd
}

.format-audio .post_featured.with_thumb .mejs-container,.format-audio .post_featured>div .mejs-controls {
  background: 0
}

.format-audio .post_featured.with_thumb .mejs-controls,.format-audio .post_featured>div>.mejs-container {
  background: 0
}

.mejs-container .mejs-controls .mejs-button {
  margin: 3px 3px 3px 0;
  overflow: hidden;
  position: relative;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%
}

.mejs-container .mejs-controls .mejs-button:hover {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%
}

.mejs-container .mejs-controls .mejs-button button {
  display: block;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: none !important
}

.mejs-container .mejs-controls .mejs-playpause-button.mejs-play:before {
  content: '\e8e1';
  font-family: 'fontello'
}

.mejs-container .mejs-controls .mejs-playpause-button.mejs-pause:before {
  content: '\e8e3';
  font-family: 'fontello'
}

.mejs-container .mejs-controls .mejs-volume-button.mejs-mute:before {
  content: '\e905';
  font-family: 'fontello'
}

.mejs-container .mejs-controls .mejs-volume-button.mejs-unmute:before {
  content: '\e904';
  font-family: 'fontello'
}

.mejs-container .mejs-controls .mejs-time-rail span,.mejs-container .mejs-controls .mejs-time-rail a {
  height: 3px
}

.mejs-container .mejs-controls .mejs-time-rail .mejs-time-float {
  height: 17px;
  border: 0
}

.mejs-container .mejs-controls .mejs-time-rail .mejs-time-total {
  margin: 8px 5px
}

.mejs-container .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current,.mejs-container .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
  height: 3px;
  top: 13px
}

.format-video .post_featured.with_thumb {
  position: relative
}

.format-video .post_featured.with_thumb .post_video {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s
}

.format-video .post_featured.with_thumb .post_video:before {
  content: ' ';
  display: inline-block;
  padding-top: 55%;
  width: 0;
  height: 0
}

.format-video .post_featured.with_thumb img {
  position: relative;
  z-index: 2
}

.format-video .post_featured.with_thumb .mask {
  z-index: 3;
  background-color: rgba(0,0,0,0.5);
  opacity: 0
}

.format-video .post_featured.with_thumb:hover .mask {
  opacity: 1
}

.format-video .post_featured.with_thumb .post_video_hover {
  position: absolute;
  z-index: 4;
  left: 50%;
  top: 50%;
  font-size: 1.31579em;
  width: 2.4em;
  height: 2.4em;
  line-height: 2.3em;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  margin-top: 2em;
  background-color: rgba(255,255,255,0.2);
  border: 3px solid #fff;
  color: #fff;
  opacity: 0
}

.body_style_fullwide .format-video .post_featured.with_thumb .post_video_hover,.post_layout_excerpt.format-video .post_featured.with_thumb .post_video_hover,.post_layout_chess_1.format-video .post_featured.with_thumb .post_video_hover {
  font-size: 2.36842em;
  width: 2.4em;
  height: 2.2em;
  line-height: 2.2em;
  border-width: 5px
}

.format-video .post_featured.with_thumb .post_video_hover:before {
  content: '\e855';
  font-family: 'fontello'
}

.format-video .post_featured.with_thumb .post_video_hover:hover {
  background-color: rgba(0,0,0,0.5)
}

.mobile_layout .format-video .post_featured.with_thumb .post_video_hover,.format-video .post_featured.with_thumb:hover .post_video_hover {
  opacity: 1;
  margin-top: 0
}

.format-video .post_featured.post_video_play .post_video {
  opacity: 1;
  z-index: 10
}

.format-video .post_featured.post_video_play .mask {
  opacity: 1;
  background-color: #000
}

.format-video .post_featured.post_video_play .post_video>* {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%)
}

.format-video .post_featured.post_video_play .post_video_hover {
  display: none
}

.format-quote .post_content,.format-aside .post_content,.format-link .post_content,.format-status .post_content {
  padding: 0;
  text-align: left
}

.format-link .post_content_inner,.format-status .post_content_inner {
  font-size: 1.5789em;
  font-weight: 600;
  font-style: italic;
  line-height: 1.25em
}

.format-aside .post_content_inner {
  padding: 1em 1.3158em;
  border: 1px solid #ddd
}

.format-quote .post_content p,.format-aside .post_content p,.format-link .post_content p,.format-status .post_content p {
  margin-bottom: 0
}

.format-quote .post_content p+p,.format-aside .post_content p+p,.format-link .post_content p+p,.format-status .post_content p+p {
  margin-top: 1em
}

.format-aside .post_meta,.format-link .post_meta,.format-status .post_meta {
  margin-bottom: 2em
}

.format-chat .post_content_inner {
  padding-top: 1.1em
}

.format-chat p {
  margin-bottom: 0
}

.format-chat p>em,.format-chat p>b,.format-chat p>strong {
  display: inline-block;
  margin-top: 1.7em
}

.format-chat p:first-child>em,.format-chat p:first-child>b,.format-chat p:first-child>strong {
  margin-top: 0
}

/* 7.6 Paginations
-------------------------------------------------------------- */
.nav-links-more {
  font-size: .7368em;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 2.3em;
  letter-spacing: 1px;
  text-align: center
}

.blog_style_excerpt .nav-links-more {
  margin-top: 5.3em
}

.nav-links-more.nav-links-infinite {
  display: none
}

.nav-links-more a {
  display: inline-block;
  padding-top: 3em;
  position: relative
}

.nav-links-more a:before {
  content: '\e929';
  font-family: 'fontello';
  font-size: 2em;
  display: block;
  text-align: center;
  font-weight: normal;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -0.5em;
  width: 1em;
  height: 1em;
  line-height: 1em
}

.nav-links-more a:hover {
  -webkit-animation: jump .5s 1 cubic-bezier(0.2,0.8,0.6,1.6);
  -moz-animation: jump .5s 1 cubic-bezier(0.2,0.8,0.6,1.6);
  -ms-animation: jump .5s 1 cubic-bezier(0.2,0.8,0.6,1.6);
  -o-animation: jump .5s 1 cubic-bezier(0.2,0.8,0.6,1.6);
  animation: jump .5s 1 cubic-bezier(0.2,0.8,0.6,1.6)
}

.nav-links-more.loading a:before {
  content: '\e82d';
  -webkit-animation: spin 2s infinite linear;
  -moz-animation: spin 2s infinite linear;
  -ms-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear
}

.nav-links-infinite a span {
  opacity: 0;
  filter: alpha(opacity=0)
}

.nav-links-infinite.loading {
  display: block
}

.nav-links-old {
  overflow: hidden;
  font-size: .7368em;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 2em
}

body[class*="blog_style_portfolio"] .nav-links-old,body[class*="blog_style_classic"] .nav-links-old {
  margin-top: 1.3em
}

.nav-links-old .nav-prev a:before,.nav-links-old .nav-next a:after {
  font-family: 'fontello';
  display: inline;
  font-weight: normal;
  position: absolute;
  top: 0
}

.nav-links-old .nav-prev {
  float: left;
  position: relative;
  padding-left: 2em
}

.nav-links-old .nav-prev a:before {
  content: '\e907';
  left: 0
}

.nav-links-old .nav-next {
  float: right;
  position: relative;
  padding-right: 2em
}

.nav-links-old .nav-next a:after {
  content: '\e908';
  right: 0
}

.nav-links-old a {
  border-bottom: 1px dotted transparent
}

.nav-links,.page_links {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 3.4em;
  text-align: center
}

.page_links {
  margin-top: 2.5em
}

.page_links .page_links_title {
  margin-right: 1em
}

.page_links>span:not(.page_links_title),.page_links>a,.nav-links .page-numbers {
  display: inline-block;
  width: 4.3333em;
  height: 4.3333em;
  line-height: 4.3333em;
  text-align: center;
  margin-right: .2em
}

.single .nav-links {
  font-size: .8421em;
  text-align: left;
  margin-top: 5.45em;
  padding-top: 5.45em;
  border-top: 1px dotted #ddd;
  overflow: hidden;
  text-transform: none
}

.single .nav-links a {
  display: block;
  position: relative;
  min-height: 5em;
  z-index: 1
}

.single .nav-links a .nav-arrow {
  width: 5em;
  height: 4.6em;
  line-height: 4.6em;
  overflow: hidden;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px dotted transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center
}

.single .nav-links a .nav-arrow:before {
  content: ' ';
  display: block;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s
}

.single .nav-links a:hover .nav-arrow:before {
  opacity: .5
}

.single .nav-links a .nav-arrow:after {
  content: '\e907';
  font-family: 'fontello';
  display: inline-block;
  position: relative;
  z-index: 2;
  top: 3em;
  opacity: 0;
  color: #292929;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s
}

.single .nav-links a:hover .nav-arrow:after {
  top: 0;
  opacity: 1
}

.single .nav-links .nav-next a .nav-arrow {
  right: 0;
  left: auto
}

.single .nav-links .nav-next a .nav-arrow:after {
  content: '\e908'
}

.single .nav-links .nav-previous,.single .nav-links .nav-next {
  width: 50%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.single .nav-links .nav-previous {
  float: left;
  text-align: left;
  padding-right: 15px
}

.single .nav-links .nav-previous a {
  padding-left: 6.5em
}

.single .nav-links .nav-previous .post-title {
  text-align: left
}

.single .nav-links .nav-next {
  float: right;
  text-align: right;
  padding-left: 15px
}

.single .nav-links .nav-next a {
  padding-right: 6.5em
}
@media (max-width: 460px) {
  .single .nav-links .nav-next a .nav-arrow,
  .single .nav-links .nav-previous a .nav-arrow{
    display: none;
  }
  .single .nav-links .nav-next a,
  .single .nav-links .nav-previous a {
    padding: 0;

  }

}
.single .nav-links .nav-next .post-title {
  text-align: right
}

.single .nav-links .post-title,.single .nav-links .post_date {
  -webkit-transition: all ease-in-out .3s;
  -moz-transition: all ease-in-out .3s;
  -ms-transition: all ease-in-out .3s;
  -o-transition: all ease-in-out .3s;
  transition: all ease-in-out .3s
}

.single .nav-links .post-title {
  margin: 0 0 .3em;
  line-height: 1.3em;
  font-weight: 600
}

.single .nav-links .meta-nav,.single .nav-links .post_date {
  font-size: .9286em;
  font-weight: normal
}

.image-navigation .nav-previous,.image-navigation .nav-next {
  float: none;
  width: auto;
  text-align: left;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 1.5em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

.image-navigation .nav-next {
  left: auto;
  right: 1.5em;
  text-align: right
}

.image-navigation .nav-previous a,.image-navigation .nav-next a {
  display: block;
  padding: .25em 1em;
  width: 0;
  max-width: 20em;
  overflow: hidden;
  white-space: nowrap;
  background-color: rgba(0,0,0,0.6);
  border: 1px solid #000;
  color: #fff;
  -webkit-border-radius: 1.5em;
  -moz-border-radius: 1.5em;
  border-radius: 1.5em
}

.image-navigation .nav-previous a:hover,.image-navigation .nav-next a:hover {
  width: auto;
  color: #fff
}

.image-navigation .nav-previous a:hover {
  padding-left: 3.5em
}

.image-navigation .nav-next a:hover {
  padding-right: 3.5em
}

.image-navigation .nav-previous a:after,.image-navigation .nav-next a:after {
  content: '\e8ef';
  font-family: "fontello";
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  text-align: center;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border: 1px solid #000;
  background-color: #333;
  color: #fff
}

.image-navigation .nav-next a:after {
  content: '\e8f0';
  left: auto;
  right: 0
}

.image-navigation .nav-previous a:hover:after,.image-navigation .nav-next a:hover:after {
  border-color: transparent;
  background-color: transparent
}

/* 8. Single page parts
-------------------------------------------------------------- */
.post_item_single.format-aside .post_content,.post_item_single.format-link .post_content,.post_item_single.format-status .post_content {
  padding: 2em 0 0;
  text-align: left
}

.post_item_single.format-quote .post_content {
  background-color: transparent;
  padding-top: 0
}

.post_item_single .post_content .mejs-container {
  margin-bottom: 1em
}
/* 8.1 Post header
------------------------------------------------------ */
.post_item_single .post_featured {
  margin-bottom: 2.8em
}

.post_featured.post_attachment {
  margin-bottom: .5em
}

.entry-caption {
  text-align: center;
  font-style: italic
}

.post_item_single .post_header {
  margin-bottom: 2.5em
}

.post_item_single .post_content>h1:first-child,.post_item_single .post_content>.row:first-child h1:first-child,.post_item_single .post_title {
  margin-top: -0.2em
}

.post_item_single .post_header .post_title {
  margin-bottom: 0
}

/* 8.2 Post footer
------------------------------------------------------ */
.post_item_single .post_content .post_meta {
  margin-top: 2.8em;
  overflow: hidden
}

.post_item_single .post_content .post_meta .post_tags {
  float: left;
  display: block;
  max-width: 55%;
  margin-top: .7em
}

.post_item_single .post_content .post_meta .post_share {
  float: right;
  display: block;
  max-width: 40%
}

.post_item_single .post_content .post_meta .post_share:before {
  display: none
}

.post_item_single .post_content .post_meta .post_share .social_item a {
  display: block;
  width: 4em;
  height: 4em;
  line-height: 4em;
  text-align: center
}

.post_item_single .post_content .post_meta .post_share .social_item+.social_item {
  margin-left: 3px
}
/* 8.3 Post author
------------------------------------------------------ */
.author_info {
  margin-top: 5.45em;
  padding-top: 5.45em;
  border-top: 1px dotted #ddd;
  position: relative
}

.author_avatar {
  width: 6.3158em;
  height: 6.3158em;
  position: absolute;
  left: 0;
  top: 5.45em
}

.author_avatar img {
  width: 100%;
  height: auto
}

.author_description {
  padding-left: 7.8947em
}

.author_title {
  margin-top: -0.4em;
  margin-bottom: 1em
}

.author_bio p {
  margin: 0
}

.author_bio p+p {
  margin-top: 1em
}

.author_bio .author_link {
  display: block;
  margin-top: .8em;
  font-style: italic;
  font-weight: 600
}

/* 8.4 Related posts
------------------------------------------------------ */
.related_wrap {
  margin-top: 5.45em;
  padding-top: 5.45em;
  border-top: 1px dotted #eee;
  overflow: hidden
}

.related_wrap_title {
  margin: 0 0 1.5em;
  text-align: left
}

.related_wrap .related_item {
  text-align: center
}

.related_wrap .post_featured {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover
}

.related_wrap .post_categories {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px
}

.related_wrap .post_categories ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block
}

.related_wrap .post_categories li {
  display: inline-block;
  margin: 0
}

.related_wrap .post_categories li+li {
  margin-left: .5em
}

.related_wrap .post_categories li:nth-child(n+3) {
  display: none
}

.related_wrap .post_title {
  margin: .2em 0 .1em
}

.related_wrap .post_date {
  font-size: .7368em
}

.related_wrap .post_featured:before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%
}

.related_wrap .related_item_style_1 .post_header {
  display: inline-block;
  vertical-align: middle;
  padding: 3em 2em;
  max-width: 70%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s
}

.related_wrap .related_item_style_1 .post_title {
  margin: .8em 0;
  letter-spacing: 1px;
  line-height: 1.5em
}

.related_wrap .related_item_style_2 .post_featured {
  margin-bottom: 1.1em
}

.related_wrap .related_item_style_2 .post_featured:before {
  padding-top: 62.47%
}

.related_wrap .related_item_style_2 .post_date {
  font-size: 10px;
  font-weight: bold;
  line-height: 1.4em;
  text-transform: uppercase
}


/* 8.5 Comments
-------------------------------------------------------- */
.body_style_fullscreen .comments_wrap {
  margin-bottom: 3em
}

.comments_list_wrap {
  margin-top: 7.2em;
  padding-top: 6.3em;
  border-top: 1px dotted #eee;
  overflow: hidden
}

.comments_list_wrap .comments_closed {
  margin-top: 2em;
  color: #1d1d1d
}

.comments_list_wrap .comments_list_title {
  margin: 0 0 .4em;
  text-align: left
}

.comments_list_wrap>ul {
  padding: 0;
  margin: 0;
  list-style: none
}

.comments_list_wrap>ul>li:first-child {
  margin-top: -1.3em
}

.comments_list_wrap ul li {
  padding-top: 2.4em
}

.comments_list_wrap ul>li {
  overflow: hidden;
  position: relative;
  min-height: 5em
}

.comments_list_wrap ul>li:before {
  display: none
}

.comments_list_wrap ul ul {
  margin-left: 7.3em;
  padding-left: 0
}

.comments_list_wrap ul ul ul ul ul {
  margin-left: 0
}

.comments_list_wrap li+li,.comments_list_wrap li ul {
  margin-top: 2.2em;
  border-top: 1px dotted #eee
}

.comments_list_wrap .comment_author_avatar {
  position: absolute;
  left: 0;
  top: 2.5em;
  z-index: 1;
  width: 5.7895em;
  height: 5.7895em
}

.comments_list_wrap .comment_author_avatar img {
  width: 100%
}

.comments_list_wrap .comment_content {
  padding-left: 7.3em;
  overflow: hidden
}

.comments_list_wrap .comment_info {
  margin-bottom: 1em;
  position: relative
}

.comments_list_wrap .comment_author {
  margin: 0
}

.comments_list_wrap .comment_posted {
  position: relative;
  font-size: 12px;
  line-height: 1.6em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px
}

.comments_list_wrap .comment_time {
  display: none
}

.comments_list_wrap .comment_counters {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0
}
@media(max-width:460px){
  .comments_list_wrap .comment_counters {
    position: static;
  }
}

.comments_list_wrap .comment_not_approved {
  padding: .2em 0 .5em 0;
  font-style: italic
}

.comments_list_wrap .comment_text {
  margin-bottom: .5em
}

.comments_list_wrap .comment_text p {
  margin: 0
}

.comments_list_wrap .comment_text p+p {
  margin-top: .6em
}

.comments_list_wrap .comment_text ul {
  margin: 0;
  padding: 0 0 0 1.5em;
  list-style: outside
}

.comments_list_wrap .comment_text ol {
  margin: 0;
  padding: 0 0 0 1.5em;
  list-style: decimal
}

.comments_list_wrap .comment_text ul>li>ul,.comments_list_wrap .comment_text ol>li>ol {
  margin-top: .5em
}

.comments_list_wrap .comment_text ul>li,.comments_list_wrap .comment_text ol>li {
  min-height: 0;
  padding: 0;
  margin: 0 0 .5em 0;
  overflow: visible
}

.comments_list_wrap .comment_text ul,.comments_list_wrap .comment_text ul>li,.comments_list_wrap .comment_text ol,.comments_list_wrap .comment_text ol>li {
  border: 0
}

.comments_list_wrap .comment_reply {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.6em;
  letter-spacing: 1px;
  margin-top: 2.1em
}

.comments_list_wrap .comment_reply a:before {
  content: '\e802';
  font-family: 'fontello';
  margin-right: .2em
}

.comments_list>li.trackback {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  padding-right: 4em;
  min-height: 0;
  position: relative
}

.comments_list>li.trackback p {
  font-style: italic;
  padding-bottom: .8em
}

.comments_list>li.trackback p a {
  font-style: normal
}

.comments_list>li.trackback .edit-link {
  position: absolute;
  right: 0;
  top: 2em
}

.comments_form_wrap {
  margin-top: 5.45em;
  padding-top: 5.45em;
  border-top: 1px dotted #eee;
  overflow: hidden
}

.comments_list_wrap+.comments_form_wrap {
  margin-top: 2.3em
}

.comments_form_wrap form {
  position: relative;
  overflow: hidden
}

.comments_wrap .comments_form_title {
  margin: 0 0 1.5em;
  text-align: left
}

.comments_wrap .comment-reply-title {
  display: none;
  font-size: 1.3333em;
  line-height: 1.7333em;
  text-transform: uppercase;
  text-align: left;
  margin: 0
}

.comments_wrap .comments_list_wrap .comment-reply-title {
  display: block
}

.comments_wrap .comments_notes {
  font-size: .8667em
}

.comments_wrap .comments_field {
  margin-bottom: 2em
}

.comments_wrap .comments_author,.comments_wrap .comments_email {
  width: 48%;
  float: left
}

.comments_wrap .comments_email {
  float: right
}

.comments_wrap .comments_url {
  clear: both
}

.comments_wrap .comments_field label {
  display: none
}

.comments_wrap .comments_field input,.comments_wrap .comments_field textarea {
  width: 100%;
  padding: 1.5em 2em;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important
}

.comments_wrap .comments_field textarea {
  min-height: 11em
}

.comments_wrap .form-submit {
  margin: 3.3em 0 0
}

.comments_wrap .form-submit:before {
  content: ' ';
  clear: both;
  display: block;
  width: 100%;
  height: 0
}

.comments_wrap .form-submit input[type="submit"] {
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #000;
  font-size: 11px;
  font-style: normal;
  font-weight: bold;
  padding: 1em 4em;
  text-transform: uppercase;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s
}

form .error_field {
  border-color: #cc6868 !important;
  -webkit-box-shadow: 0 0 8px 0 rgba(200,100,100,0.2);
  -moz-box-shadow: 0 0 8px 0 rgba(200,100,100,0.2);
  box-shadow: 0 0 8px 0 rgba(200,100,100,0.2)
}

.laon_wine_house_messagebox {
  display: inline-block;
  font-size: .8667em;
  line-height: 1.5em;
  padding: 1.5em;
  color: #006818;
  border: 1px solid #006818;
  background-color: #d8ffd8;
  position: absolute;
  z-index: 1000;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-box-shadow: 0 0 20px 0 rgba(0,200,0,0.2);
  -moz-box-shadow: 0 0 20px 0 rgba(0,200,0,0.2);
  box-shadow: 0 0 20px 0 rgba(0,200,0,0.2)
}

.laon_wine_house_messagebox_style_error {
  color: #681800;
  border: 1px solid #681800;
  background-color: #ffd8d8;
  -webkit-box-shadow: 0 0 20px 0 rgba(200,0,0,0.2);
  -moz-box-shadow: 0 0 20px 0 rgba(200,0,0,0.2);
  box-shadow: 0 0 20px 0 rgba(200,0,0,0.2)
}

.laon_wine_house_messagebox p {
  margin: 0
}

.laon_wine_house_messagebox p+p {
  margin-top: .4em
}

/* 8.6 Page 404
-------------------------------------------------------------- */
.post_item_404 .post_content {
  padding: 11em 0 8em;
  overflow: hidden
}

.post_item_404 .page_title {
  float: left;
  width: 50%;
  font-size: 17em;
  line-height: .8em;
  margin: 0;
  margin-top: 0 !important;
  letter-spacing: 0;
  text-align: left
}

.post_item_404 .page_info {
  float: left;
  width: 50%;
  text-align: left;
  padding-left: 5em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.post_item_404 .page_subtitle {
  margin: 0;
  letter-spacing: 1px
}

.post_item_404 .page_description {
  margin-top: 1em;
  margin-bottom: 2em;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 2em
}

.post_item_404 .go_home {
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1em 4em;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: .8571em;
  font-style: normal;
  font-weight: bold;
  border: 2px solid #ddd;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s
}

.post_item_404 .go_home:after {
  content: '\e908';
  font-family: 'fontello';
  margin-left: .5em;
  font-size: .9286em
}

/* 8.7 Page 'No search results' and 'No archive results'
-------------------------------------------------------- */
.post_item_none_search .post_content {
  padding: 3em 0
}

.post_item_none_search .page_title,.post_item_none_archive .page_title {
  font-size: 7em;
  line-height: 1em
}


/* 9. Sidebars
-------------------------------------------------------------- */
/* Common rules */
.sidebar[class*="scheme_"] aside {
  padding: 3.2em 1.6667em
}

.sidebar .sidebar_inner {
  padding: 0 1.5789em
}

.sidebar aside+aside {
  padding-top: 2.9473em;
  margin-top: 2.9473em;
  border-top: 1px dotted #000
}

.sidebar aside+aside.widget_bg_image {
  border-top: 0;
  padding-top: 2em
}

.sidebar aside.widget_bg_image+aside {
  border-top: 0;
  margin-top: 0
}

aside p {
  margin: 0
}

aside p+p {
  margin-top: 1em
}

aside .widget_title {
  margin-top: 0;
  margin-bottom: 1.55em
}

aside ul {
  margin: 0;
  padding-left: .9em;
  list-style-type: none
}

aside li {
  position: relative;
  font-style: italic;
  font-weight: normal
}

aside li:before {
  content: ' ';
  display: block;
  width: 5px;
  height: 5px;
  position: absolute;
  left: -0.9em;
  top: .4em;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%
}

.widgets_above_page_wrap,.widgets_above_content_wrap {
  font-size: .8667em;
  margin-bottom: 2.3077em
}

.widgets_below_content_wrap,.widgets_below_page_wrap {
  font-size: .8667em;
  margin-top: 2.3077em
}

.widgets_below_page_wrap {
  clear: both
}

.widget_calendar table {
  width: 100%;
  position: relative
}

.widget_calendar caption {
  padding-bottom: .5em;
  text-transform: uppercase
}

.widget_calendar table>thead>tr {
  background-color: transparent !important
}

.widget_calendar th,.widget_calendar td {
  font-size: .7895em !important;
  line-height: 1.5em;
  font-weight: 400 !important;
  font-style: normal;
  text-align: center;
  padding: 2px;
  color: #000;
  border: none !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  width: 14.2857%
}

.widget_calendar th {
  font-weight: 700 !important;
  font-style: italic !important;
  text-transform: none !important;
  padding: .5em 0 .75em
}

.widget_calendar td {
  line-height: 2em;
  padding: 0
}

.widget_calendar tbody td a {
  display: block;
  position: relative;
  font-weight: normal;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.widget_calendar tbody td a:after {
  content: '\e83c';
  font-family: 'fontello';
  position: absolute;
  left: 48%;
  top: .7em
}

.widget_calendar td#today {
  position: relative;
  z-index: 2
}

.widget_calendar td#today:before {
  content: ' ';
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  width: 2.2em;
  height: 2.2em;
  border: 1px solid #ddd;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%)
}

.widget_calendar tfoot {
  position: relative;
  top: .55em
}

.widget_calendar tfoot td {
  font-size: .7895em !important;
  font-style: italic !important;
  font-weight: 600 !important;
  text-transform: none !important
}

.widget_calendar td#prev {
  text-align: left;
  overflow: hidden
}

.widget_calendar td#next {
  text-align: right
}

.widget_recent_entries .post-date {
  display: inline-block;
  margin-left: .5em
}

.widget_search .search_form,.widget_search .search-form {
  position: relative;
  overflow: visible;
}

.widget_search .search-form {
  top: .3em
}

.widget_search .search-field {
  padding: .9em 1.1em;
  padding-right: 3em !important
}

.widget_search input.search-submit {
  display: block;
  text-indent: -1000px;
  position: absolute;
  right: 2em;
  top: 50%;
  margin-top: -0.6em;
  width: 1.2em;
  height: 1.2em;
  line-height: 1.2em;
  border: none !important;
  background: none !important;
  z-index: 1;
  cursor: pointer
}

.widget_rss .widget_title a {
  display: inline-block;
  margin-right: 6px
}

.widget_rss .widget_title a:first-child:before {
  content: '\e8ed';
  font-family: 'fontello';
  display: inline-block;
  font-size: .9em
}

.widget_rss .widget_title img {
  display: none
}

.widget_rss ul,.widget_rss li {
  padding-left: 0
}

.widget_rss li:before {
  display: none
}

.widget_rss li+li {
  margin-top: 2em
}

.widget_product_tag_cloud,.widget_tag_cloud {
  overflow: hidden
}

.widget_product_tag_cloud .widget_title,.widget_tag_cloud .widget_title {
  margin-bottom: 1em
}

.widget_product_tag_cloud a,.widget_tag_cloud a {
  display: block;
  float: left;
  border: 1px solid #ddd;
  padding: .4em .8em;
  margin: 0 .2143em .2143em 0;
  font-weight: 700;
  font-size: 12px !important;
  line-height: 1.5em;
  text-transform: uppercase
}

.widget_text .mc4wp_wrap {
  margin-top: 1.5em
}

.widget_text .mc4wp-form label {
  font-weight: 400;
  line-height: 2em
}

.widget_text .mc4wp-form input {
  display: block;
  width: 100%;
  font-style: italic
}

.widget_text .mc4wp-form input+input {
  margin-top: .4em
}

.widget_text .mc4wp-form input[type="submit"] {
  border: 2px solid #292929;
  padding: .5em 1em;
  text-transform: uppercase;
  font-size: .9333em;
  font-style: normal;
  font-weight: bold;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s
}

.widget_text .mc4wp-form input[type="submit"]:hover {
  background-color: transparent !important
}

.widget_text .mc4wp-form .mc4wp-alert {
  margin: 2em 0;
  padding: 1em
}

.widget_text .socials_wrap {
  text-align: center;
  margin-top: 1.3em;
  font-size: 1.2308em
}

.widget_text .socials_wrap .social_item+.social_item {
  margin-left: .8em
}

#sb_instagram .sbi_header_text {
  padding-top: 0 !important;
  margin-top: -2px
}

#sb_instagram .sb_instagram_header {
  padding: 0 !important;
  margin-bottom: .5em !important
}

.footer_fullwidth #sb_instagram .sb_instagram_header {
  float: none;
  text-align: center;
  overflow: hidden
}

.footer_fullwidth #sb_instagram .sb_instagram_header>a {
  float: none;
  display: inline-block;
  overflow: hidden
}

#sb_instagram .sb_instagram_header h3 {
  font-size: 1.2308em;
  line-height: 1.5em
}

#sb_instagram .sb_instagram_header p {
  font-size: 1em;
  line-height: 1.5em
}

#sb_instagram #sbi_load {
  margin-top: .5em
}

ul.instagram-pics {
  margin-right: -5px
}

ul.instagram-pics li {
  float: left;
  padding: 0 5px 5px 0;
  margin: 0;
  overflow: hidden;
  width: 33.3333%;
  height: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.column-1_3 ul.instagram-pics li {
  width: 25%
}

.column-1_2 ul.instagram-pics li {
  width: 20%
}

.column-1_1 ul.instagram-pics li {
  width: 16.6666%
}

ul.instagram-pics li:before {
  display: none
}

/* 10. Footer areas
-------------------------------------------------------------- */
.site_footer_wrap {
  margin-top: 6em
}

.remove_margins .site_footer_wrap {
  margin-top: 0
}

.footer_wrap_inner {
  padding: 3em 0 1em
}

.footer_wrap aside {
  margin-bottom: 1.6667em
}

.footer_wrap.footer_fullwidth {
  overflow: hidden
}

.footer_wrap .widget_title {
  text-align: left
}

.footer_wrap.footer_fullwidth .widget_title {
  text-align: center;
  margin-bottom: 1.6667em
}

.logo_footer_wrap_inner {
  padding: 7.15em 0;
  overflow: hidden;
  text-align: center;
  position: relative
}

.footer_wrap+.logo_footer_wrap .logo_footer_wrap_inner {
  padding-top: 4.15em;
  border-top-width: 1px;
  border-top-style: solid
}

.logo_footer_wrap_inner:after {
  content: ' ';
  position: absolute;
  z-index: 1;
  left: 50%;
  bottom: 0;
  width: 1px;
  height: 3.8889em
}

.logo_footer_wrap_inner img {
  max-width: 50%
}

.logo_footer_text {
  font-size: 3.3333em;
  line-height: 1em;
  letter-spacing: 6px;
  margin: 0
}

.logo_footer_image+.logo_footer_text {
  margin: .63em 0 2em
}

.socials_footer_wrap_inner {
  padding: 2.9em 0;
  text-align: center
}

.socials_footer_wrap_inner .social_icons {
  display: inline-block;
  width: 2.2em;
  height: 2.2em;
  line-height: 2.2em;
  text-align: center;
  border: 1px solid #ddd;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg)
}

.socials_footer_wrap_inner .social_icons span:before {
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  -ms-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.socials_footer_wrap_inner .social_icons:hover span:before {
  -webkit-transform: scale(1.2,1.2) rotate(-45deg);
  -moz-transform: scale(1.2,1.2) rotate(-45deg);
  -ms-transform: scale(1.2,1.2) rotate(-45deg);
  transform: scale(1.2,1.2) rotate(-45deg)
}

.socials_footer_wrap_inner .social_item+.social_item {
  margin-left: 1.6em
}

.menu_footer_wrap_inner {
  padding: 2em 0;
  text-align: center
}

.menu_footer_nav_area ul {
  padding: 0;
  margin: 0;
  list-style-type: none
}

.menu_footer_nav_area ul li {
  display: inline-block;
  vertical-align: baseline;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px
}

.menu_footer_nav_area ul li+li:before {
  content: ' ';
  display: inline-block;
  width: 0;
  height: .8em;
  margin: 0 3em;
  vertical-align: baseline;
  border-left: 1px dotted #ddd
}

.footer_wrap+.copyright_wrap .copyright_wrap_inner,.logo_footer_wrap+.copyright_wrap .copyright_wrap_inner,.socials_footer_wrap+.copyright_wrap .copyright_wrap_inner,.menu_footer_wrap+.copyright_wrap .copyright_wrap_inner {
  border-top-width: 1px;
  border-top-style: solid
}

.copyright_wrap_inner {
  font-size: .8889em;
  font-style: italic;
  /*padding: 2em 0 7.5em;*/
  padding: 2em 0;
  overflow: hidden;
  text-align: center
}

.copyright_wrap p {
  margin: 0
}

/* 11. Utils
-------------------------------------------------------------- */
#debug_log {
  position: fixed;
  z-index: 1000000;
  display: block;
  width: 100%;
  max-height: 400px;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.8);
  color: #fff;
  overflow: auto
}

.admin-bar #debug_log {
  top: 32px
}

#debug_log_close {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  cursor: pointer
}

#debug_log_content {
  padding: 12px
}

/* 12. Registration and Login popups
-------------------------------------------------------------- */
.popup_wrap {
  position: absolute;
  top: 3.3333em;
  right: 0;
  width: 37.5em;
  padding: 3em 2.5em 2em;
  display: none
}

.popup_registration {
  right: -5.5em
}

.popup_wrap .popup_close {
  display: block;
  font-size: 1.25em;
  line-height: 1em;
  width: 1em;
  height: 1em;
  position: absolute;
  right: 0;
  top: 0;
  margin: .5em .5em 0 0;
  text-align: center;
  color: #ccc;
  border: 2px solid #ebebeb;
  background-color: #f4f7f9
}

.popup_wrap .popup_close:hover {
  color: #909090;
  border: 2px solid #ccc;
  background-color: #fff
}

.popup_wrap .popup_close:before {
  font-family: fontello;
  content: '\e8ac';
  line-height: 1em;
  padding: 0 !important;
  margin: 0 !important
}

.popup_wrap .form_wrap {
  position: relative;
  overflow: hidden
}

.popup_wrap .form_left {
  float: left;
  padding-right: 2.5em;
  width: 54%;
  border-right: 1px solid #e0e0e0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.popup_wrap .form_right {
  float: right;
  padding-left: 2.5em;
  width: 45%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.popup_wrap.popup_registration .form_left {
  width: 50%;
  padding-right: 5px;
  border-right: 0
}

.popup_wrap.popup_registration .form_right {
  width: 50%;
  padding-left: 5px
}

.popup_wrap .popup_form_field {
  margin-bottom: .625em
}

.popup_wrap input[type="text"],.popup_wrap input[type="email"],.popup_wrap input[type="password"] {
  width: 100%;
  border: 2px solid #ebebeb;
  background-color: #fafafa
}

.popup_wrap input:not([type="submit"]):focus {
  background-color: #fff
}

.popup_wrap .popup_form_field.iconed_field {
  position: relative
}

.popup_wrap .popup_form_field.iconed_field:before {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 8px;
  margin-right: 0;
  color: #c9c9c9;
  width: 1em
}

.popup_wrap .popup_form_field.iconed_field input {
  padding-right: 30px
}

.popup_wrap .popup_form_field .forgot_password {
  float: right;
  line-height: 1.5em
}

.popup_wrap .popup_form_field.submit_field {
  padding: 1em 0 4px 4px
}

.popup_wrap .popup_form_field .submit_button {
  width: 100%
}

.popup_wrap .login_socials_title {
  color: #222;
  font-size: 1.5em;
  line-height: 1.3em;
  margin-bottom: .5em
}

.popup_wrap .login_socials_problem {
  line-height: 1.3em
}

.popup_wrap .popup_form_field.remember_field {
  margin-top: 1.2em
}

/* 13. Third part plugins
------------------------------------------------------------------------------------ */
.wpb_row,.wpb_text_column,.wpb_content_element,ul.wpb_thumbnails-fluid>li,.last_toggle_el_margin,.wpb_button {
  margin-bottom: 0 !important
}

.sc_gap .row {
  margin-left: 0;
  margin-right: 0
}

.sc_gap .vc_column_container {
  padding-left: 0;
  padding-right: 0
}

h1 .wpb_text_column p,h2 .wpb_text_column p,h3 .wpb_text_column p,h4 .wpb_text_column p,h5 .wpb_text_column p,h6 .wpb_text_column p {
  padding-bottom: 0 !important
}

iframe[name="google_conversion_frame"] {
  height: 1px;
  min-height: 0;
  display: none
}

.post_item_single .post_edit .vc_inline-link {
  display: none
}

.mfp-bg {
  z-index: 200001
}

.mfp-wrap {
  z-index: 200002
}

.mfp-arrow {
  background-color: transparent !important
}

.mfp-image-holder .mfp-close,.mfp-iframe-holder .mfp-close {
  right: -20px;
  padding: 0;
  width: 44px;
  text-align: center
}

.mfp-image-holder .mfp-close,.mfp-iframe-holder .mfp-close {
  background: none !important
}
/* 14. Predefined classes for users
-------------------------------------------------------------- */
.rounded_none {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0
}

.rounded_tiny {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px
}

.rounded_small {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px
}

.rounded_medium {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px
}

.rounded_large {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px
}

.rounded {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%
}

.height_tiny {
  height: 1.0526em !important
}

.height_small {
  height: 1.5789em !important
}

.height_medium {
  height: 2.6316em !important
}

.height_large,.height_big {
  height: 5.2632em !important
}

.height_huge {
  height: 6.3158em !important
}

.theme_button {
  display: inline-block;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 2em 3.3em;
  font-size: .8571em;
  font-weight: bold;
  letter-spacing: 3px;
  -webkit-transition: all ease-in-out .3s !important;
  -moz-transition: all ease-in-out .3s !important;
  -ms-transition: all ease-in-out .3s !important;
  -o-transition: all ease-in-out .3s !important;
  transition: all ease-in-out .3s !important
}

.theme_button.theme_button_small {
  padding: 1.3em 3.3em
}

.slider_engine_revo .theme_button {
  font-size: .8571em !important
}

.theme_button:after {
  content: '\e908';
  font-family: 'fontello';
  margin-left: .5em
}

.theme_scroll_down {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 10px !important;
  line-height: 15px !important
}

.theme_scroll_down:before {
  content: '\e90e';
  font-family: 'fontello';
  font-size: 20px !important;
  line-height: 30px !important;
  display: block;
  text-align: center
}

.theme_scroll_down:hover {
  -webkit-animation: jump .5s 1 cubic-bezier(0.2,0.8,0.6,1.6);
  -moz-animation: jump .5s 1 cubic-bezier(0.2,0.8,0.6,1.6);
  -ms-animation: jump .5s 1 cubic-bezier(0.2,0.8,0.6,1.6);
  -o-animation: jump .5s 1 cubic-bezier(0.2,0.8,0.6,1.6);
  animation: jump .5s 1 cubic-bezier(0.2,0.8,0.6,1.6)
}

#makeup {
  position: absolute;
  z-index: 1000000;
  pointer-events: none;
  opacity: .2;
  left: 50%;
  top: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%)
}

.admin-bar #makeup {
  top: 32px
}

#makeup {
  margin-left: 0;
  margin-top: 0;
  display: none
}


/*custom styles*/
/*progressbar*/
.vc_progress_bar .vc_single_bar {
  background: #f7f7f7;
  position: relative;
  margin-bottom: 11.67px;
  -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.1) inset;
  box-shadow: 0 1px 2px rgba(0,0,0,.1) inset;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px
}

.vc_progress_bar .vc_single_bar:last-child {
  margin-bottom: 0
}

.vc_progress_bar .vc_single_bar .vc_label {
  display: block;
  padding: .5em 1em;
  position: relative;
  z-index: 1
}

.vc_progress_bar .vc_single_bar .vc_bar {
  display: block;
  position: absolute;
  height: 100%;
  background-color: #e0e0e0;
  width: 0;
  top: 0;
  left: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  transition: width 1s linear;
  -moz-transition: width 1s linear;
  -webkit-transition: width 1s linear;
  -o-transition: width 1s linear
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-bar_blue .vc_bar,.vc_progress_bar.vc_progress-bar-color-bar_blue .vc_single_bar .vc_bar {
  background-color: #0074CC
}

.vc_progress_bar .vc_single_bar .vc_bar.striped {
  background-image: -webkit-gradient(linear,0 100%,100% 0,color-stop(.25,rgba(255,255,255,.15)),color-stop(.25,transparent),color-stop(.5,transparent),color-stop(.5,rgba(255,255,255,.15)),color-stop(.75,rgba(255,255,255,.15)),color-stop(.75,transparent),to(transparent));
  background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-image: -moz-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-image: -o-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  -webkit-background-size: 40px 40px;
  -moz-background-size: 40px 40px;
  -o-background-size: 40px 40px;
  background-size: 40px 40px
}

.vc_progress_bar .vc_single_bar .vc_bar.animated {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -moz-animation: progress-bar-stripes 2s linear infinite;
  -ms-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-bar_turquoise .vc_bar,.vc_progress_bar.vc_progress-bar-color-bar_turquoise .vc_single_bar .vc_bar {
  background-color: #49afcd
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-bar_green .vc_bar,.vc_progress_bar.vc_progress-bar-color-bar_green .vc_single_bar .vc_bar {
  background-color: #5bb75b
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-bar_orange .vc_bar,.vc_progress_bar.vc_progress-bar-color-bar_orange .vc_single_bar .vc_bar {
  background-color: #faa732
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-bar_red .vc_bar,.vc_progress_bar.vc_progress-bar-color-bar_red .vc_single_bar .vc_bar {
  background-color: #da4f49
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-bar_black .vc_bar,.vc_progress_bar.vc_progress-bar-color-bar_black .vc_single_bar .vc_bar {
  background-color: #414141
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-blue .vc_bar,.vc_progress_bar.vc_progress-bar-color-blue .vc_single_bar .vc_bar {
  background-color: #5472D2
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-turquoise .vc_bar,.vc_progress_bar.vc_progress-bar-color-turquoise .vc_single_bar .vc_bar {
  background-color: #00C1CF
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-pink .vc_bar,.vc_progress_bar.vc_progress-bar-color-pink .vc_single_bar .vc_bar {
  background-color: #FE6C61
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-turquoise .vc_label {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25)
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-violet .vc_bar,.vc_progress_bar.vc_progress-bar-color-violet .vc_single_bar .vc_bar {
  background-color: #8D6DC4
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-peacoc .vc_bar,.vc_progress_bar.vc_progress-bar-color-peacoc .vc_single_bar .vc_bar {
  background-color: #4CADC9
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-chino .vc_bar,.vc_progress_bar.vc_progress-bar-color-chino .vc_single_bar .vc_bar {
  background-color: #CEC2AB
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-mulled-wine .vc_bar,.vc_progress_bar.vc_progress-bar-color-mulled-wine .vc_single_bar .vc_bar {
  background-color: #50485B
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-vista-blue .vc_bar,.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-vista_blue .vc_bar,.vc_progress_bar.vc_progress-bar-color-vista-blue .vc_single_bar .vc_bar,.vc_progress_bar.vc_progress-bar-color-vista_blue .vc_single_bar .vc_bar {
  background-color: #75D69C
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-black .vc_bar,.vc_progress_bar.vc_progress-bar-color-black .vc_single_bar .vc_bar {
  background-color: #2A2A2A
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-grey .vc_bar,.vc_progress_bar.vc_progress-bar-color-grey .vc_single_bar .vc_bar {
  background-color: #EBEBEB
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-black .vc_label {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25)
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-grey .vc_label,.vc_progress_bar.vc_progress-bar-color-grey .vc_single_bar .vc_label {
  color: #666;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25)
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-orange .vc_bar,.vc_progress_bar.vc_progress-bar-color-orange .vc_single_bar .vc_bar {
  background-color: #F7BE68
}

.vc_progress_bar.vc_progress-bar-color-orange .vc_single_bar .vc_label {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25)
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-sky .vc_bar,.vc_progress_bar.vc_progress-bar-color-sky .vc_single_bar .vc_bar {
  background-color: #5AA1E3
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-orange .vc_label {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25)
}

.vc_progress_bar.vc_progress-bar-color-sky .vc_single_bar .vc_label {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25)
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-green .vc_bar,.vc_progress_bar.vc_progress-bar-color-green .vc_single_bar .vc_bar {
  background-color: #6DAB3C
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-sky .vc_label {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25)
}

.vc_progress_bar.vc_progress-bar-color-green .vc_single_bar .vc_label {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25)
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-juicy-pink .vc_bar,.vc_progress_bar.vc_progress-bar-color-juicy-pink .vc_single_bar .vc_bar {
  background-color: #F4524D
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-green .vc_label {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25)
}

.vc_progress_bar.vc_progress-bar-color-juicy-pink .vc_single_bar .vc_label {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25)
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-sandy-brown .vc_bar,.vc_progress_bar.vc_progress-bar-color-sandy-brown .vc_single_bar .vc_bar {
  background-color: #F79468
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-juicy-pink .vc_label {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25)
}

.vc_progress_bar.vc_progress-bar-color-sandy-brown .vc_single_bar .vc_label {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25)
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-purple .vc_bar,.vc_progress_bar.vc_progress-bar-color-purple .vc_single_bar .vc_bar {
  background-color: #B97EBB
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-sandy-brown .vc_label {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25)
}

.vc_progress_bar.vc_progress-bar-color-purple .vc_single_bar .vc_label {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25)
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-white .vc_bar,.vc_progress_bar.vc_progress-bar-color-white .vc_single_bar .vc_bar {
  background-color: #FFF
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-purple .vc_label {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25)
}

.vc_progress_bar .vc_general.vc_single_bar.vc_progress-bar-color-white .vc_label,.vc_progress_bar.vc_progress-bar-color-white .vc_single_bar .vc_label {
  color: #666;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25)
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0
  }

  to {
    background-position: 0 0
  }
}

@-moz-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0
  }

  to {
    background-position: 0 0
  }
}

@-ms-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0
  }

  to {
    background-position: 0 0
  }
}

@-o-keyframes progress-bar-stripes {
  from {
    background-position: 0 0
  }

  to {
    background-position: 40px 0
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0
  }

  to {
    background-position: 0 0
  }
}
.vc_separator {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}
.vc_separator .vc_sep_holder {
  height: 1px;
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 10%
}

.vc_separator .vc_sep_holder .vc_sep_line {
  height: 1px;
  border-top: 1px solid #EBEBEB;
  display: block;
  position: relative;
  top: 1px;
  width: 100%
}

.vc_separator.vc_separator_align_left .vc_sep_holder.vc_sep_holder_l,.vc_separator.vc_separator_align_right .vc_sep_holder.vc_sep_holder_r {
  display: none
}
.vc_message_box {
  border: 1px solid transparent;
  display: block;
  overflow: hidden;
  margin: 0 0 21.74px;
  padding: 1em 1em 1em 4em;
  position: relative;
  font-size: 1em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.vc_row.vc_row-flex,.vc_row.vc_row-flex>.vc_column_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox
}

#content .wpb_alert p:last-child,#content .wpb_text_column :last-child,#content .wpb_text_column p:last-child,.vc_message_box>p:last-child,.wpb_alert p:last-child,.wpb_text_column :last-child,.wpb_text_column p:last-child {
  margin-bottom: 0
}

.vc_message_box-icon,.vc_message_box-icon>* {
  position: absolute;
  font-weight: 400;
  font-style: normal
}

.vc_message_box>p:first-child {
  margin-top: 0
}

.vc_message_box-icon {
  bottom: 0;
  font-size: 1em;
  left: 0;
  top: 0;
  width: 4em
}

.vc_message_box-icon>*,.vc_message_box-icon>.fa {
  font-size: 1.7em;
  line-height: 1
}

.vc_message_box-icon>* {
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%)
}
.vc_color-orange.vc_message_box {
  color: #c3811c;
  border-color: #fbe1ba;
  background-color: #fef6eb
}

.vc_color-orange.vc_message_box .vc_message_box-icon {
  color: #F7BE68
}

.vc_color-orange.vc_message_box-solid {
  color: #fff;
  border-color: transparent;
  background-color: #F7BE68
}

.vc_color-orange.vc_message_box-solid .vc_message_box-icon {
  color: #fff
}

.vc_color-orange.vc_message_box-outline,.vc_color-orange.vc_message_box-solid-icon {
  color: #c3811c;
  border-color: #F7BE68;
  background-color: transparent
}

.vc_color-orange.vc_message_box-outline .vc_message_box-icon,.vc_color-orange.vc_message_box-solid-icon .vc_message_box-icon {
  color: #F7BE68
}

.vc_color-orange.vc_message_box-solid-icon .vc_message_box-icon {
  color: #fff;
  background-color: #F7BE68
}

.vc_color-orange.vc_message_box-3d {
  box-shadow: 0 5px 0 #f9cd8a
}
.vc_color-juicy_pink.vc_message_box {
  color: #a3231f;
  border-color: #fbc7c5;
  background-color: #fef5f5
}

.vc_color-juicy_pink.vc_message_box .vc_message_box-icon {
  color: #F4524D
}

.vc_color-juicy_pink.vc_message_box-solid {
  color: #fff;
  border-color: transparent;
  background-color: #F4524D
}

.vc_color-juicy_pink.vc_message_box-solid .vc_message_box-icon {
  color: #fff
}

.vc_color-juicy_pink.vc_message_box-outline,.vc_color-juicy_pink.vc_message_box-solid-icon {
  color: #a3231f;
  border-color: #F4524D;
  background-color: transparent
}

.vc_color-juicy_pink.vc_message_box-outline .vc_message_box-icon,.vc_color-juicy_pink.vc_message_box-solid-icon .vc_message_box-icon {
  color: #F4524D
}

.vc_color-juicy_pink.vc_message_box-solid-icon .vc_message_box-icon {
  color: #fff;
  background-color: #F4524D
}

.vc_color-juicy_pink.vc_message_box-3d {
  box-shadow: 0 5px 0 #f89895
}
.vc_color-black.vc_message_box {
  color: #2A2A2A;
  border-color: #2A2A2A;
  background-color: #3c3c3c
}

.vc_color-black.vc_message_box-solid {
  color: #fff;
  border-color: transparent;
  background-color: #2A2A2A
}

.vc_color-black.vc_message_box-solid .vc_message_box-icon {
  color: #fff
}

.vc_color-black.vc_message_box-outline,.vc_color-black.vc_message_box-solid-icon {
  border-color: #2A2A2A;
  background-color: transparent
}

.vc_color-black.vc_message_box-outline .vc_message_box-icon,.vc_color-black.vc_message_box-solid-icon .vc_message_box-icon {
  color: #2A2A2A
}

.vc_color-black.vc_message_box-solid-icon .vc_message_box-icon {
  color: #fff;
  background-color: #2A2A2A
}

.vc_color-black.vc_message_box-3d {
  box-shadow: 0 5px 0 #101010
}
#tribe-events-footer .tribe-events-sub-nav, #tribe-events-header .tribe-events-sub-nav{
  overflow: visible;
}