body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.sc_item_subtitle {
  font-family: fontello, serif;
  font-size: 1.1em;
}

.spinner-container {
  position: relative;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgba(172, 172, 172, 0.5); /* Black see-through */
  width: 100%;
  height: 100%;
  transition: .5s ease;
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  z-index: 1;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid orange;
  border-color: orange transparent orange transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fade_in {
  display: block;
}

.fade_out {
  visibility: hidden;
  opacity: 0;
  display: none;
  transition: visibility 0s 2s, opacity 2s linear;
}

.fixed_menu_slide_in {
  margin-top: 0;
  transition: margin-top 0.1s ease;
}

.fixed_menu_slide_out {
  margin-top: -2.5em;
}

.custom-home-image {
  /*background-image: url('./images/shop-bundles.jpg')!important;*/
}

.text-with-background {
  text-shadow: 2px 2px 2px #222222; /* horizontal-offset vertical-offset 'blur' colour */
  -moz-text-shadow: 2px 2px 2px #222222;
  -webkit-text-shadow: 2px 2px 2px #222222;
}

.scheme_default .menu_mobile_close:before, .light_colors, .scheme_default .menu_mobile_close:after, .scheme_default .menu_mobile_button, .scheme_default .menu_mobile_button:before {
  border-color: grey;
}